import CloseIcon from '@mui/icons-material/Close'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Modal,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsDefaultForm from '../../../custom-components/DoformsDefaultForm'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import DoformsPortal from '../../../custom-components/DoformsPortal'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderDialog from '../../../custom-components/skeletons/SkeletonLoaderDialog'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { performFormAction } from '../datagrid/recordsHelper'
import { getAllProjects, getProjectForms } from '../../../components/data/dataServices'

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	exitConfirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
	hidden: {
		display: 'none',
	},
}))

const DoformsSendDispatch = (props) => {
	const [t] = useTranslation('common')
	const {
		environment,
		formSelected,
		title,
		action,
		recordsLoading,
		tab,
		source,
		deviceSelected,
		onDialogOpen,
		selectedDate,
		duration,
		viewType,
	} = props
	//const {environment} = useSelector((state) => state);
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const initialState = {
		loaded: false,
		error: null,
	}

	const [values, setValues] = useState(initialState)
	const [open, setOpen] = useState(false)
	const [dispatchForm, setDispatchForm] = useState(formSelected)
	const [exitConfirmation, setExitConfirmation] = useState(false)
	const [loading, setLoading] = useState(false)

	const [iframe, setIframe] = useState(null)

	const { loaded, error } = values
	const dispatch = useDispatch()

	useEffect(() => {
		if (source === 'FormsDashboard') {
			if (environment.showSendDispatch == 'dashboard') {
				confirmSend()
			}
			return
		}
		if (
			(source === 'dashboard' || source === 'button') &&
			environment.showSendDispatch === source
		) {
			if (tab === 'devices') setOpen(true)
			confirmSend()
		}
	}, [environment.showSendDispatchRefresh])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setValues({ ...values, error: null })
	}

	const showLoading = () => (
		<>
			<LoadingSpinner />
			<SkeletonLoaderDialog />
		</>
	)

	const confirmSend = () => {
		if (tab !== 'devices') {
			if (open) return

			if (_.isEmpty(formSelected)) return
			let dispatchFormTmp = { ...formSelected, projectKey: formSelected.projectKey }
			setDispatchForm(dispatchFormTmp)
			setOpen(true)
			setValues({ ...values, loaded: false })
			performFormAction(
				environment,
				dispatchFormTmp,
				action,
				tab,
				deviceSelected,
				selectedDate,
				duration,
				viewType
			)
				.then((res) => {
					setIframe(res)
				})
				.catch((err) => {
					setValues({
						...values,
						error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
					})
				})
		} else {
			if (environment.showSendDispatch == 'dashboard') return
			if (_.isEmpty(dispatchForm)) return
			setOpen(true)
			setValues({ ...values, loaded: false })
			performFormAction(environment, dispatchForm, action, tab, deviceSelected)
				.then((res) => {
					setIframe(res)
				})
				.catch((err) => {
					setValues({
						...values,
						error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
					})
				})
		}
	}

	const handleOpen = (e, source) => {
		e.preventDefault()
		if (onDialogOpen !== undefined) {
			onDialogOpen(true)
		}

		if (source === 'grid') source = 'dashboard'

		dispatch({
			type: ENV_ACTIONS.SHOW_SEND_DISPATCH,
			payload: source,
		})
	}

	const handleClose = (event, reason) => {
		if (
			!dispatchForm ||
			(reason !== 'backdropClick' && reason !== 'escapeKeyDown' && reason !== 'buttonClick')
		) {
			setExitConfirmation(false)
			setOpen(false)
			setValues(initialState)
			dispatch({
				type: ENV_ACTIONS.SHOW_SEND_DISPATCH,
				payload: false,
			})
			// if(!environment.userCurrent.projectForm) {
			//     setOpen(false)showLoading()
			//     return
			// }
			if (tab === 'devices') {
				dispatch({
					type: ENV_ACTIONS.DISPATCH_FORM,
					payload: environment.userCurrent.projectForm,
				})
			}
		} else {
			setExitConfirmation(true)
		}
	}

	const handleLoaded = (event) => {
		event.preventDefault()
		setValues({ ...values, loaded: true })
	}

	const showTitle = () => {
		return `${t('tooltip.send')} ${title}`
	}

	const showTitleForDeviceTab = () => {
		return (
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} style={{ alignItems: 'center' }}>
					<Grid item xs={12} style={{ textAlign: 'left' }}>
						{title}
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							display: 'inline-flex',
							alignItems: 'left',
							textAlign: 'left',
							paddingTop: '0px',
						}}
					>
						<Grid item xs={5}>
							<DoformsDefaultForm tab="devices" source={source} />
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const ExitConfirmationDialog = (props) => {
		const { open, onClose } = props
		const { coreValues } = useContext(CoreContext)
		const { theme } = coreValues

		const classes = useStyles(theme.topBar)

		const buttonStyle = [
			{ color: theme.icons.color },
			{
				'&:hover': {
					color: theme.icons.active.color,
				},
			},
		]

		const handleCloseRecordDialog = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
			onClose()
		}

		const handleCloseExitConfirmation = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
		}

		return (
			<div>
				<Modal
					open={open}
					onClose={handleCloseExitConfirmation}
					className={classes.exitConfirmation}
				>
					<div className={classes.paper} style={{ backgroundColor: 'white' }}>
						<p>{t('common:dialog.cancelSendDispatch')}</p>
						<Button onClick={handleCloseRecordDialog} sx={buttonStyle}>
							{t('common:misc.continue')}
						</Button>
						<Button onClick={handleCloseExitConfirmation} sx={buttonStyle}>
							{t('common:misc.cancel')}
						</Button>
					</div>
				</Modal>
			</div>
		)
	}

	useEffect(() => {
		if (!tab) return
		if (!environment.isProjectFormsLoaded) {
			initiateLoadAllProjects()
		}
	}, [tab])

	useEffect(() => {
		if (!loaded && environment.showSendDispatch === source && !_.isEmpty(dispatchForm)) {
			confirmSend()
		}
	}, [dispatchForm])

	const initiateLoadAllProjects = () => {
		setLoading(true)
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = _.sortBy(res.data, 'name')
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				console.log('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const initiateLoadFormsByProject = (projects) => {
		loadFormsByProject(projects)
			.then((res) => {
				const newProjects = _.sortBy(res, 'name')
				dispatch({
					type: ENV_ACTIONS.SET_NEW_PROJECTS,
					payload: newProjects,
				})
			})
			.catch((err) => {
				console.log('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_LOADING_PROJECTS,
					payload: false,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const loadFormsByProject = async (projects) => {
		let promises = []
		for (let i = 0; i < projects.length; i++) {
			const promise = getProjectForms(projects[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { key: projects[i].key, name: projects[i].name, forms: [] }
				if (resp?.data && _.isArray(resp.data) && resp.data.length) {
					promiseObject.forms = _.sortBy(resp.data, 'name')
					dispatch({
						type: ENV_ACTIONS.GET_FORMS,
						payload: promiseObject.forms,
					})
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	useEffect(() => {
		//if (open && _.isEmpty(formSelected) && _.isEmpty(dispatchForm)) {
		if (tab === 'devices' && open) {
			const matchProject = environment.projects.find(
				(item) => item.key === environment.dispatchForm.projectKey
			)
			const matchForm = matchProject.forms.find(
				(item) => item.key === environment.dispatchForm.formKey
			)
			setDispatchForm({ ...matchForm, projectKey: environment.dispatchForm.projectKey })
			setValues({ ...values, loaded: false })
			setIframe(null)
		}
	}, [environment.dispatchForm])

	return (
		<>
			{source === 'grid' && (
				<LoadingButton
					id={'send-dispatch'}
					className={classes.icon}
					disabled={recordsLoading}
					loadingPosition="start"
					onClick={(e) => handleOpen(e, source)}
					startIcon={<ShortcutIcon />}
				>
					{t('common:misc.send')}
				</LoadingButton>
			)}
			{source === 'button' && (
				<Tooltip
					title={`${t('common:tooltip.prefix.dispatch')} - ${title}`}
					arrow
					placement="bottom-start"
					disableInteractive
				>
					<span>
						<IconButton
							aria-label="add"
							size="small"
							edge="end"
							onClick={(e) => handleOpen(e, source)}
						>
							<ShortcutIcon fontSize="inherit" className={classes.icon} />
						</IconButton>
					</span>
				</Tooltip>
			)}
			{(source === 'dashboard' || source === 'button') && (
				<>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth={true}
						maxWidth="md"
						className={classes.dialog}
						sx={{
							'& .MuiDialog-paper': {
								height: '90% !important',
							},
						}}
					>
						<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
							{tab !== 'devices' ? showTitle() : showTitleForDeviceTab()}
							<IconButton onClick={(e) => handleClose(e, 'buttonClick')}>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							{showErrorMessage()}
							{loading ? (
								<LoadingSpinner />
							) : (
								!_.isEmpty(dispatchForm) && (
									<>
										{iframe ? (
											<DoformsPortal
												iframeSrc={iframe}
												onClose={handleClose}
												onLoaded={handleLoaded}
											/>
										) : (
											showLoading()
										)}
										{/* {!loaded && showLoading()}
                                {iframe && <div className={!loaded ? classes.hidden : undefined}>
                                    <DoformsPortal
                                        iframeSrc={iframe}
                                        onClose={handleClose}
                                        onLoaded={handleLoaded}
                                    />
                                </div>} */}
									</>
								)
							)}
						</DialogContent>
					</Dialog>
					<ExitConfirmationDialog
						onClose={(e) => handleClose(e, null)}
						onMouseDown={(e) => e.stopPropagation()}
						open={exitConfirmation}
					/>
				</>
			)}
		</>
	)
}

export default DoformsSendDispatch
