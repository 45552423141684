import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'

import { Box, Alert } from '@mui/material'
import LoadingSpinner from '../../../../../../custom-components/LoadingSpinner'

import driverMapApi from 'apis/disApi/driverMapApi'

import { DRIVER_MAP_LOGGED_IN_INFO } from '../../../../../../constants'

import { useTileDashboard } from '../../../dashboard/Dashboard'
import { getAutoUpdateFilters } from '../../../helpers'
import { logErrorMessage } from 'utils/functions/helpers'
import { convertArrayToObject } from '../helper/functions'
import { DriverMapInfo } from './DriverMapInfo'
import DriverMapSettingsDialog from './DriverMapSettingsDialog'
import { checkByMasterDateTimeCondition } from 'utils/functions/helpers'
import { parseAs } from 'utils/functions/doformsDateUtil'

const COLUMN_NAME = {
	FROM_DATE: 'fromDate',
	TO_DATE: 'toDate',
	USER_EMAIL: 'userEmail',
}

export function DriverMapTileContent(props) {
	const environment = useSelector((state) => state.environment)
	const { id: dashboardKey } = useParams()
	// const { isMapLogout } = useSearchParams()

	const {
		tile,
		formMapRef,
		updateTile,
		settingsOpen,
		setSettingsOpen,
		handleCloseDialog,
		handleResizeTileWidth,
		handleSubmit,
		showFilters,
		repeatReloadMapSeconds,
		showHistory,
		pointOfInterest,
		locationData,
		linkedFields,
		dashboardKeyList,
		gpsSource,
		userEmail: settingUserEmail,
	} = props

	const [errorMsg, setErrorMsg] = useState('')

	const [userEmail, setUserEmail] = useState(null)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)

	const { selectedFields, isRemovedTile } = useTileDashboard()
	const userOffset = useMemo(() => {
		return environment.userCurrent.time.timezone.offset || 0
	}, [environment])

	useEffect(() => {
		if (isEqual(settingUserEmail, userEmail)) return
		setUserEmail(settingUserEmail)
	}, [settingUserEmail])

	useEffect(() => {
		const userMoment = moment.utc(locationData.fromDate).utcOffset(userOffset)
		setFromDate(userMoment.unix())
	}, [locationData.fromDate])

	useEffect(() => {
		// If To date is empty, use now
		if (!locationData.toDate) {
			const currentMoment = moment().endOf('day')
			const userMoment = parseAs(currentMoment, userOffset)
			setToDate(userMoment.unix())
			return
		}
		setToDate(moment.utc(locationData.toDate).unix())
	}, [locationData.toDate, userOffset])

	const { filterConfigs, hasPrimaryKey, conditions } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields, dashboardKeyList]
	)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	const conditionsWithoutId = useMemo(() => {
		const result = {}
		for (const key in conditions) {
			const value = conditions[key]
			const { id, ...rest } = value
			result[key] = rest
		}
		return result
	}, [conditions])

	useEffect(() => {
		if (hasPrimaryKey) {
			const newSettings = convertArrayToObject(filterWithoutId)

			const shouldSetEmail = Object.prototype.hasOwnProperty.call(newSettings, 'userEmail')
			const shouldSetFromDate = Object.prototype.hasOwnProperty.call(newSettings, 'fromDate')
			const shouldSetToDate = Object.prototype.hasOwnProperty.call(newSettings, 'toDate')

			const valueToCheck = {
				userEmail: shouldSetEmail ? newSettings.userEmail : settingUserEmail,
				fromDate: shouldSetFromDate ? newSettings.fromDate : locationData?.fromDate,
				toDate: shouldSetToDate ? newSettings.toDate : locationData?.toDate,
			}

			if (!isEmpty(conditionsWithoutId)) {
				Object.values(COLUMN_NAME).forEach((key) => {
					const condition = conditionsWithoutId[key]
					if (isEmpty(condition)) return

					const dateValue = valueToCheck[key]
					const isValid = checkByMasterDateTimeCondition(
						dateValue,
						condition,
						condition?.keyData === 'Master_DateTime'
					)
					if (!isValid) {
						valueToCheck[key] = ''
					}
				})
			}

			if (!isEqual(valueToCheck.userEmail, userEmail)) {
				setUserEmail(valueToCheck.userEmail)
			}

			let startUnix = moment.utc(valueToCheck.fromDate).utcOffset(userOffset).unix()
			if (!isEqual(startUnix, fromDate)) {
				setFromDate(startUnix)
			}

			let toUnix
			if (!valueToCheck.toDate) {
				const currentMoment = moment().endOf('day')
				const userMoment = parseAs(currentMoment, userOffset)
				toUnix = userMoment.unix()
			} else {
				toUnix = moment.utc(valueToCheck.toDate).unix()
			}
			if (!isEqual(toUnix, toDate)) {
				setToDate(toUnix)
			}
		} else {
			if (!isEqual(settingUserEmail, userEmail)) {
				setUserEmail(settingUserEmail)
			}

			let startUnix = moment.utc(locationData?.fromDate).utcOffset(userOffset).unix()
			if (!isEqual(startUnix, fromDate)) {
				setFromDate(startUnix)
			}

			let toUnix
			if (!locationData.toDate) {
				const currentMoment = moment().endOf('day')
				const userMoment = parseAs(currentMoment, userOffset)
				toUnix = userMoment.unix()
			} else {
				toUnix = moment.utc(locationData.toDate).unix()
			}
			if (!isEqual(toUnix, toDate)) {
				setToDate(toUnix)
			}
		}
	}, [JSON.stringify(filterWithoutId), JSON.stringify(conditionsWithoutId), hasPrimaryKey])

	return (
		<>
			<DriverMapInfo
				titleKey={tile?.key}
				showFilters={showFilters}
				repeatReloadMapSeconds={repeatReloadMapSeconds}
				showHistory={showHistory}
				pointOfInterest={pointOfInterest}
				locationData={locationData}
				linkedFields={linkedFields}
				dashboardKeyList={dashboardKeyList}
				userEmail={userEmail}
				fromDate={fromDate}
				toDate={toDate}
				isRemovedTile={isRemovedTile}
			/>
			{errorMsg && <Alert severity="error">{errorMsg}</Alert>}
			<DriverMapSettingsDialog
				defaultTileWidth={300}
				tileElementWidth={formMapRef?.current?.clientWidth}
				open={settingsOpen}
				tile={tile}
				isSubmitting={updateTile?.isLoading}
				locationData={locationData}
				showFilters={showFilters}
				repeatReloadMapSeconds={repeatReloadMapSeconds}
				showHistory={showHistory}
				pointOfInterest={pointOfInterest}
				dashboardKeyList={dashboardKeyList}
				linkedFields={linkedFields}
				onClose={handleCloseDialog}
				onResizeTileWidth={handleResizeTileWidth}
				onSubmit={handleSubmit}
				gpsSource={gpsSource}
				userEmail={settingUserEmail}
			/>
		</>
	)
}
