import { useState, useRef, useMemo, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty, sortBy, isArray } from 'lodash'
import moment from 'moment'

import { Scheduler } from '@aldabil/react-scheduler'
import {
	Box,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions,
	Button,
	IconButton,
	Typography,
	TextField,
	Tooltip,
	CircularProgress,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from '@mui/material'
import { Shortcut as ShortcutIcon } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { LoadingButton } from '@mui/lab'
import { Close, Delete, FileCopy, History, Reply, Visibility, Edit } from '@mui/icons-material'

import TileWrapper from '../../components/TileWrapper'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider, IconThemeContext } from 'custom-components/context/IconThemesContext'
import {
	isJson,
	logErrorMessage,
	convertArrayToObject,
} from '../../../../../utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'
import { ENV_ACTIONS } from 'reducers/environmentReducer'

import DispatchSchedulerSettingsDialog from './DispatchSchedulerSettingDialog'
import DoformsSendDispatch from 'components/data/dispatch/DoformsSendDispatch'
import LoadingSpinner from 'custom-components/LoadingSpinner'
import DoformsPortal from '../../../../../custom-components/DoformsPortal'
import ToastAlert from 'components/core/Layouts/ToastAlert'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import useSchedulerQuery from '../../hooks/useSchedulerQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'

import {
	getAllProjects,
	getAllTeams,
	getProjectForms,
	getViewRecords,
} from 'components/data/dataServices'
import {
	getDevices,
	getDispatchSettings,
	updateAppointments,
} from 'components/core/services/environmentService'
import { getAutoUpdateFilters } from '../../helpers'
import {
	viewRecord,
	getPrintPreview,
	getRecordHistory,
	deleteRecord,
	executePostDispatchLink,
} from 'components/data/datagrid/recordsService'
import { capitalizeFirstLetter } from '../../../../../components/data/dataHelpers'
import { performRecordAction } from 'components/data/datagrid/recordsHelper'
import DoformsRecordHistory from 'components/data/datagrid/DoFormsRecordHistory'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import enUS from 'date-fns/locale/en-US'
import { toDayjs } from 'utils/functions/doformsDateUtil'
import { toast } from 'react-toastify'
import useMobileUnitsQuery from '../../hooks/useMobileUnitsQuery'

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	eventRenderer: {
		'& .MuiPaper-root': {
			marginTop: '-4px',
			minHeight: '25px',
			height: '96%',
		},
		'& .rs_event_item': {
			marginTop: '-4px',
		},
	},
    schedulerContainer: {
        '& .MuiPaper-root': {
			marginTop: '-3px',
			minHeight: '25px',
		},
		'& .rs_event_item': {
			marginTop: '-4px',
		},
    },
	buttonDialog: {
		'& .MuiButtonBase-root': {
			padding: '5px 1px !important',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	exitConfirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
	hidden: {
		display: 'none',
	},
}))

const EVENT_STATUS = {
	PENDING: 'pending',
	SCHEDULED: 'scheduled',
	EMAILED: 'emailed',
	SENT: 'sent',
	RECEIVED: 'received',
	VIEWED: 'viewed',
	OPENED: 'opened',
	REJECTED: 'rejected',
	COMPLETED: 'completed',
	RECALLED: 'recalled',
	ACKNOWLEDGED: 'acknowledged',
}
const ACTION_TYPE = {
	COPY: 'copy',
	VIEW: 'view',
	EDIT: 'edit',
	DELETE: 'delete',
	RECALL: 'recall',
	DRAGDROP: 'dragdrop',
	SENDSMSMAIL: 'sendsmsmail',
	HISTORY: 'history',
}
function DispatchSchedulerTile(props) {
	const { tile } = props

	const [t] = useTranslation()

	const { environment } = useSelector((state) => state)
	const dispatch = useDispatch()
	const iconTheme = environment.theme.icons
	const classes = useStyles(iconTheme)
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()
	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})
	const tileRef = useRef(null)
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const {
		tileWidth,
		projectFormInfo,
		schedulerRangeType,
		schedulerStartTime,
		schedulerEndTime,
		schedulerColorStatusSettings,
		linkedFields = {},
		otherOptionsChecked,
	} = settings

	const { selectedFields } = useTileDashboard()
	const { filterConfigs } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields]
	)
	const schedulerBoxRef = useRef(null)
	const schedulerRef = useRef(null)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	useEffect(() => {
		const { Mobile_number: mobileNumber } = convertArrayToObject(filterWithoutId)

		const device = environment?.devices?.find((device) => device.number === mobileNumber)

		setSelectedDevice(device || null)
	}, [environment?.devices, filterWithoutId])

	const formSelected = useMemo(() => {
		const allForms = environment?.forms
		if (isEmpty(allForms)) {
			return {}
		}

		const form = allForms.find((item) => item.key === projectFormInfo?.formKey)
		if (!form) {
			return {}
		}

		return {
			...form,
			projectKey: projectFormInfo?.projectKey,
		}
	}, [projectFormInfo, environment])

	// data states
	const [view, setView] = useState(schedulerRangeType || 'day')
	const [isAllowDispatch, setIsAllowDispatch] = useState(false)
	const [schedulerColorStatus, setSchedulerColorStatus] = useState(schedulerColorStatusSettings)
	const [events, setEvents] = useState([])
	const [mobileUnits, setMobileUnits] = useState([])
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})
	const [schedulerKey, setSchedulerKey] = useState(0)
	const [loading, setLoading] = useState(false)
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [selectedDate, setSelectedDate] = useState(null)
	const [selectedDateDisplay, setSelectedDateDisplay] = useState(moment())
	const [defaultSchedulerStatus, setDefaultSchedulerStatus] = useState([])
	const defaultDuration = '0.5'

	useEffect(() => {
		const updateTodayButtonColor = () => {
			const todayBtn = document.querySelector('button[aria-label="Today"]')
			if (todayBtn) {
				const currentDateMoment = moment()
				const selectedDateMoment = moment(selectedDateDisplay)

				if (
					view === 'day' &&
					selectedDateMoment.format('MM/DD/YYYY') === currentDateMoment.format('MM/DD/YYYY')
				) {
					todayBtn.setAttribute('style', 'color: #1976d2 !important')
				} else {
					todayBtn.setAttribute('style', 'color: black !important')
				}
			}
		}
		updateTodayButtonColor()

		const observer = new MutationObserver(() => {
			updateTodayButtonColor()
		})

		observer.observe(document.body, { childList: true, subtree: true })
		return () => observer.disconnect()
	}, [selectedDateDisplay, view])

	useEffect(() => {
		const showAgenda = otherOptionsChecked.some((item) => item === 'showAgenda')

		if (schedulerRef.current?.scheduler) {
			if (schedulerRef.current.scheduler.agendada !== showAgenda) {
				schedulerRef.current.scheduler.handleState(showAgenda, 'agenda')
			}
		}
	}, [schedulerRef.current?.scheduler, otherOptionsChecked])
	useEffect(() => {
		const allowDispatch = otherOptionsChecked.some((item) => item === 'allowDispatch')
		setIsAllowDispatch(allowDispatch)
	}, [otherOptionsChecked])

	useEffect(() => {
		const showAgenda = otherOptionsChecked.some((item) => item === 'showAgenda')

		if (schedulerRef.current?.scheduler) {
			if (schedulerRef.current.scheduler.agendada !== showAgenda) {
				schedulerRef.current.scheduler.handleState(showAgenda, 'agenda')
			}
		}
	}, [schedulerKey])

	useEffect(() => {
		if (environment.isProjectFormsLoaded) return

		initiateLoadAllProjects()
	}, [environment.isProjectFormsLoaded])

	useEffect(() => {
		getDevices(environment.apiToken)
			.then((data) => {
				const devices = data.data
				dispatch({
					type: ENV_ACTIONS.GET_DEVICES,
					payload: devices,
				})
				initiateLoadAllTeams(devices)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const initiateLoadAllTeams = (devices) => {
		setLoading(true)
		loadAllTeams()
			.then((res) => {
				let teams = sortBy(res.data, (team) => team.name.toLowerCase())
				if (devices.length) {
					teams = teams.map((team) => {
						let teamDevices = devices.filter((device) => device.teamKey === team.key)
						teamDevices = sortBy(teamDevices, (team) => team.name.toLowerCase())
						return { ...team, devices: teamDevices }
					})
				}

				dispatch({
					type: ENV_ACTIONS.GET_TEAMS,
					payload: teams,
				})

				setLoading(false)
			})
			.catch((err) => {
				const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
				setLoading(false)
				setAlertInfo((prev) => ({
					...prev,
					open: true,
					type: 'error',
					text: errorMessage,
				}))
			})
	}

	const loadAllTeams = async () => {
		let promise = await getAllTeams(environment.apiToken)
		return promise
	}
	useEffect(() => {
		setView(schedulerRangeType)
		setSchedulerKey((prevKey) => prevKey + 1)
	}, [schedulerRangeType])

	useEffect(() => {
		setSchedulerColorStatus(schedulerColorStatusSettings)
		setSchedulerKey((prevKey) => prevKey + 1)
	}, [schedulerColorStatusSettings])

	const initiateLoadAllProjects = () => {
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = sortBy(res.data, (project) => project.name.toLowerCase())
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
				setAlertInfo((prev) => ({
					...prev,
					open: true,
					type: 'error',
					text: errorMessage,
				}))
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const initiateLoadFormsByProject = (projects) => {
		let promises = []
		let newProjects = []
		for (let i = 0; i < projects.length; i++) {
			const promise = loadFormsByProject(projects[i])
				.then((res) => {
					if (res.name.toLowerCase() !== 'lookups') {
						newProjects[i] = { ...res }
					}
					dispatch({
						type: ENV_ACTIONS.GET_PROJECTS,
						payload: newProjects.filter((val) => val),
					})
				})
				.catch((err) => {
					const errorMessage =
						'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
					setAlertInfo((prev) => ({
						...prev,
						isOpen: true,
						type: 'error',
						text: errorMessage,
					}))
				})
			promises.push(promise)
		}

		Promise.all(promises).then(() => {
			dispatch({
				type: ENV_ACTIONS.IS_LOADING_PROJECTS,
				payload: false,
			})
		})
	}

	const loadFormsByProject = async (project) => {
		return getProjectForms(project.key, environment.apiToken).then((resp) => {
			let promiseObject = { key: project.key, name: project.name, forms: [] }
			if (resp?.data && isArray(resp.data) && resp.data.length) {
				promiseObject.forms = sortBy(resp.data, (project) => project.name.toLowerCase())
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: promiseObject.forms,
				})
			}
			return promiseObject
		})
	}

	function getColorStatusDispatch(status) {
		if (!schedulerColorStatus) return ''
		const statusColor = schedulerColorStatus.find((item) => item.label === status)
		if (!statusColor) return ''
		return statusColor.color
	}

	function getActionsDispatch(status) {
		const actions = [ACTION_TYPE.COPY, ACTION_TYPE.VIEW, ACTION_TYPE.HISTORY]
		if (
			status === EVENT_STATUS.PENDING ||
			status === EVENT_STATUS.SCHEDULED ||
			status === EVENT_STATUS.REJECTED ||
			status === EVENT_STATUS.RECALLED ||
			status === EVENT_STATUS.ACKNOWLEDGED ||
			status === EVENT_STATUS.COMPLETED
		) {
			actions.push(ACTION_TYPE.EDIT)
			actions.push(ACTION_TYPE.DELETE)
		}
		if (
			status === EVENT_STATUS.SENT ||
			status === EVENT_STATUS.EMAILED ||
			status === EVENT_STATUS.RECEIVED ||
			status === EVENT_STATUS.VIEWED ||
			status === EVENT_STATUS.OPENED
		) {
			actions.push(ACTION_TYPE.RECALL)
		}
		if (status === EVENT_STATUS.PENDING || status === EVENT_STATUS.SCHEDULED) {
			actions.push(ACTION_TYPE.DRAGDROP)
		}
		if (
			status === EVENT_STATUS.SCHEDULED ||
			status === EVENT_STATUS.SENT ||
			status === EVENT_STATUS.EMAILED
		) {
			actions.push(ACTION_TYPE.SENDSMSMAIL)
		}

		return actions
	}

	const { isLoading: isLoadingMobileUnits, data: groupedData } = useMobileUnitsQuery({
		tile,
		searchInput: '',
	})
	useEffect(() => {
		if (groupedData.length === 0) return

		let mobileUnitList = []
		for (let i = 0; i < groupedData.length; i++) {
			const currentGroup = groupedData[i]
			for (let j = 0; j < currentGroup.devices.length; j++) {
				const currentDevice = currentGroup.devices[j]
				mobileUnitList.push({
					number: currentDevice.number,
					name: currentDevice.name,
				})
			}
		}
		setMobileUnits(mobileUnitList)
	}, [groupedData])

	const { appoinments, isLoadingData } = useSchedulerQuery({
		tileKey: tile.key,
		rangeType: view,
		selectedDevice: selectedDevice,
		selectedDateDisplay: selectedDateDisplay,
	})

	useEffect(() => {
		if (!appoinments) return

		let eventList = []
		for (let i = 0; i < appoinments.data.length; i++) {
			const currentData = appoinments.data[i]
			const startMoment = moment(`${currentData.date} ${currentData.time}`, 'YYYY-MM-DD HH:mm')
			const endMoment = startMoment.clone().add(currentData.duration, 'minutes')
			const actions = getActionsDispatch(currentData?.dispatch?.systemStatus?.toLowerCase())
			eventList.push({
				event_id: currentData.key,
				title: isEmpty(currentData?.dispatch?.name)
					? currentData?.dispatch?.formName
					: currentData?.dispatch?.name,
				start: startMoment.toDate(),
				end: endMoment.toDate(),
				dispatch: currentData.dispatch,
				deletable: false,
				editable: true,
				nickName: isEmpty(currentData?.dispatch?.ownerName)
					? currentData?.dispatch?.ownerId
					: currentData?.dispatch?.ownerName,
				color: getColorStatusDispatch(currentData?.dispatch?.systemStatus),
				draggable: actions.includes(ACTION_TYPE.DRAGDROP),
				availableActions: actions,
			})
		}
		setEvents(eventList)
	}, [appoinments, schedulerColorStatus])

	const isScheduleLoading = useMemo(
		() => loading || isLoadingData || isLoadingMobileUnits,
		[loading, isLoadingData, isLoadingMobileUnits]
	)

	useEffect(() => {
		setSchedulerKey((prev) => prev + 1)
	}, [isScheduleLoading])

	useEffect(() => {
		getDispatchSettings(environment.apiToken)
			.then((setting) => {
				setDefaultSchedulerStatus(setting?.data?.status)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const {
				projectFormInfo,
				schedulerRangeType,
				schedulerStartTime,
				schedulerEndTime,
				schedulerColorStatusSettings,
				linkedFields,
				otherOptionsChecked,
			} = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						projectFormInfo,
						schedulerRangeType,
						schedulerStartTime,
						schedulerEndTime,
						schedulerColorStatusSettings,
						linkedFields,
						otherOptionsChecked,
					}),
				},
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleEventAdd = (newEvent) => {
		setEvents([...events, newEvent])
	}

	const handleEventUpdate = (updatedEvent) => {
		const updatedEvents = events.map((event) =>
			event.event_id === updatedEvent.event_id ? updatedEvent : event
		)
		setEvents(updatedEvents)
	}

	const handleEventDelete = (eventId) => {
		const updatedEvents = events.filter((event) => event.event_id !== eventId)
		setEvents(updatedEvents)
	}

	const handleViewChange = (view, agenda) => {
		setView(view)
	}

	const handleCellClick = (source) => {
		if (checkNotValidDateTime(source)) return
		if (view !== 'month') {
			setSelectedDate(moment(source).format('YYYY-MM-DDTHH:mm:ss'))
		} else {
			const sourceMoment = moment(source)
			const momentToday = moment().clone()
			if (momentToday.isBefore(sourceMoment, 'day')) {
				const selectMoment = sourceMoment
					.clone()
					.startOf('day')
					.add(8, 'hours')
					.format('YYYY-MM-DDTHH:mm:ss')
				setSelectedDate(selectMoment)
			} else {
				const currentMinute = momentToday.clone().minutes()
				let selectMoment
				if (currentMinute < 30) {
					selectMoment = momentToday.clone().startOf('hour').add(30, 'minutes')
				} else {
					selectMoment = momentToday
						.clone()
						.add(1, 'hour')
						.set({ minute: 0, second: 0, millisecond: 0 })
				}
				setSelectedDate(selectMoment)
			}
		}
		dispatch({
			type: ENV_ACTIONS.SHOW_SEND_DISPATCH,
			payload: 'dashboard',
		})
	}

	const checkNotValidDateTime = (date) => {
		const isNotValid = moment(date).isBefore(moment(), view !== 'month' ? null : 'day')
		if (isNotValid) {
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: 'You can not schedule dispatch in the past',
			}))
		}
		return isNotValid
	}
	const handleEventDrop = async (event, droppedOn, updatedEvent, originalEvent) => {
		if (checkNotValidDateTime(droppedOn)) return

		const sourceMoment = moment(droppedOn)
		let selectMoment
		if (view !== 'month') {
			selectMoment = sourceMoment.clone()
		} else {
			const momentToday = moment().clone()
			if (momentToday.isBefore(sourceMoment, 'day')) {
				selectMoment = sourceMoment.clone().startOf('day').add(8, 'hours')
			} else {
				const currentMinute = momentToday.clone().minutes()
				if (currentMinute < 30) {
					selectMoment = momentToday.clone().startOf('hour').add(30, 'minutes')
				} else {
					selectMoment = momentToday
						.clone()
						.add(1, 'hour')
						.set({ minute: 0, second: 0, millisecond: 0 })
				}
			}
		}
		const updatedEvents = events.map((event) =>
			event.event_id === updatedEvent.event_id ? updatedEvent : event
		)

		try {
			setLoading(true)
			const res = await updateAppointments(environment.apiToken, updatedEvent.event_id, {
				date: selectMoment.format('YYYY-MM-DD'),
				time: selectMoment.format('HH:mm'),
			})
			if (res.status === 200) {
				setEvents(updatedEvents)
			}
			setLoading(false)
		} catch (err) {
			const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: errorMessage,
			}))
			setLoading(false)
		}
	}

	const CustomEditor = (props) => {
		const { event: currentEvent, close, openCustomEditor, action } = props
		const [iframeSrc, setIframeSrc] = useState('')

		const [historyData, setHistoryData] = useState({})
		const { iconTheme } = useContext(IconThemeContext)
		const classes = useStyles(iconTheme)

		const [actionLoading, setActionLoading] = useState(false)
		useEffect(() => {
			;(async () => {
				try {
					if (action === ACTION_TYPE.VIEW) {
						const record = {
							dispatchKey: currentEvent.dispatch.key,
							type: 'DISPATCH',
						}

						const res = await viewRecord(record, environment?.apiToken, formSelected)
						const iframeSrc = getPrintPreview(res.data, environment.apiToken)
						setIframeSrc(iframeSrc)
						return
					}

					if ([ACTION_TYPE.COPY, ACTION_TYPE.EDIT].includes(action)) {
						const record = {
							dispatchKey: currentEvent.dispatch.key,
							type: 'DISPATCH',
							sourceType: 'DISPATCH',
							key: currentEvent.dispatch.key,
						}
						const isDispathRequest = currentEvent.dispatch.systemStatus === 'Pending'
						const iframePromise = await performRecordAction(
							environment,
							record,
							action,
							formSelected,
							'forms',
							undefined,
							isDispathRequest
						)

						setIframeSrc(iframePromise)
						return
					}

					if ([ACTION_TYPE.HISTORY].includes(action)) {
						const record = {
							dispatchKey: currentEvent.dispatch.key,
							type: 'DISPATCH',
							sourceType: 'DISPATCH',
							key: currentEvent.dispatch.key,
						}
						const [viewResponse, recordHistoryResponse] = await Promise.all([
							getViewRecords(formSelected.key, formSelected.projectKey, '', environment.apiToken),
							getRecordHistory(record, environment.apiToken),
						])
						setHistoryData({
							record: recordHistoryResponse?.data,
							columns: viewResponse?.data?.columns,
						})
						return
					}
				} catch (err) {
					console.error(err)
					throw err
				}
			})()
		}, [currentEvent, action])

		async function handleRecall() {
			if (action !== ACTION_TYPE.RECALL) return

			try {
				setActionLoading(true)
				const record = {
					dispatchKey: currentEvent.dispatch.key,
					type: 'DISPATCH',
					sourceType: 'DISPATCH',
					key: currentEvent.dispatch.key,
				}
				const iframeSrc = await performRecordAction(
					environment,
					record,
					action,
					formSelected,
					'forms'
				)

				await executePostDispatchLink(iframeSrc, environment?.apiToken)
				setActionLoading(false)
			} catch (error) {
				console.log(error)
				throw error
			} finally {
				close()
			}
		}

		async function handleDelete() {
			if (action !== ACTION_TYPE.DELETE) return

			try {
				setActionLoading(true)
				const record = {
					dispatchKey: currentEvent.dispatch.key,
					type: 'DISPATCH',
					sourceType: 'DISPATCH',
					key: currentEvent.dispatch.key,
				}
				await deleteRecord(record, environment?.apiToken)
				setActionLoading(false)
			} catch (error) {
				console.log(error)
				throw error
			}
		}

		async function handleOK() {
			switch (action) {
				case ACTION_TYPE.DELETE:
					await handleDelete()
					return
				case ACTION_TYPE.RECALL:
					await handleRecall()
					break
				default:
					break
			}
		}

		return (
			<Dialog
				open={openCustomEditor}
				onClose={close}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={'md'}
				fullWidth
				className={classes.root}
				sx={{
					'& .MuiDialog-paper': {
						height: [ACTION_TYPE.DELETE, ACTION_TYPE.RECALL].includes(action)
							? '10% important'
							: '90% !important',
					},
				}}
			>
				<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
					{capitalizeFirstLetter(currentEvent?.title)}
					<IconButton onClick={close}>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{action === ACTION_TYPE.HISTORY && !isEmpty(historyData) && (
							<DoformsRecordHistory
								recordHistory={historyData?.record}
								environment={environment}
								tab={'form'}
								columns={historyData?.columns}
								onClose={close}
							/>
						)}
						{[ACTION_TYPE.VIEW, ACTION_TYPE.COPY, ACTION_TYPE.EDIT].includes(action) && (
							<DoformsPortal
								iframeSrc={iframeSrc}
								onClose={close}
								onLoaded={() => {}}
								refreshData={() => {}}
							/>
						)}
						{action === ACTION_TYPE.DELETE && t('common:misc.areYouSureYouWantToDeleteThisRecord')}
						{action === ACTION_TYPE.RECALL ? (
							iframeSrc ? (
								<DoformsPortal
									iframeSrc={iframeSrc}
									onClose={close}
									onLoaded={() => {}}
									refreshData={() => {}}
								/>
							) : (
								t('common:misc.confirmRecallThisRecord')
							)
						) : null}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<LoadingButton
						onClick={handleOK}
						className={classes.icon}
						loading={actionLoading}
						autoFocus
					>
						{t('common:misc.ok')}
					</LoadingButton>
					<Button onClick={close} className={classes.icon}>
						{t('common:misc.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	const CustomViewer = (event, close) => {
		const [openCustomEditor, setOpenCustomEditor] = useState(false)
		const [action, setAction] = useState('')
		const [locale, setLocale] = useState(enUS)
		const [isSaving, setIsSaving] = useState(false)
		const [eventStatus, setEventStatus] = useState(event.dispatch.status)
		const [eventUser, setEventUser] = useState(event.dispatch.ownerId)
		const [eventTitle, setEventTitle] = useState(event.title)
		const [eventStartTime, setEventStartTime] = useState(toDayjs(event.start))
		const [eventEndTime, setEventEndTime] = useState(toDayjs(event.end))
		const eventEditable =
			event?.dispatch?.status?.toLowerCase() === EVENT_STATUS.PENDING ||
			event?.dispatch?.status?.toLowerCase() === EVENT_STATUS.SCHEDULED
		const maxLength = 56
		const isLongText = eventTitle.length > maxLength
		useEffect(() => {
			const importLocaleFile = async () => {
				const localeToSet = await import(
					`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
				)
				setLocale(localeToSet.default)
			}

			if (locale.code !== t('common:languages.dateFnsLocale')) {
				importLocaleFile()
			}
		}, [t('common:languages.dateFnsLocale')])
		const handleSaveEvent = async () => {
			setIsSaving(true)
			const dateString = moment(event.start).format('YYYY-MM-DD')
			const timeString = eventStartTime.format('HH:mm')
			const duration = eventEndTime.diff(eventStartTime, 'minutes')
			console.log('eventUser: ', eventUser)
			if (duration <= 0) {
				toast.error('Start time must be before end time.')
				return
			}
			try {
				const res = await updateAppointments(environment.apiToken, event.event_id, {
					date: dateString,
					time: timeString,
					duration: duration,
				})
				setIsSaving(false)
			} catch (err) {
				const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
				setAlertInfo((prev) => ({
					...prev,
					open: true,
					type: 'error',
					text: errorMessage,
				}))
				setIsSaving(false)
			}
		}

		return (
			<Dialog open={true} onClose={close}>
				{isSaving && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.5)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 10,
						}}
					>
						<CircularProgress size={50} />
					</div>
				)}
				<div style={{ backgroundColor: event.color, minHeight: '40px' }}></div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: '8px',
						paddingRight: '8px',
					}}
				>
					<IconButton size="small" onClick={close}>
						<Close />
					</IconButton>
					<div className={classes.buttonDialog} style={{ display: 'flex' }}>
						{event.availableActions.includes(ACTION_TYPE.HISTORY) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.HISTORY)
									setOpenCustomEditor(true)
								}}
							>
								<History />
							</IconButton>
						)}
						{event.availableActions.includes(ACTION_TYPE.VIEW) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.VIEW)
									setOpenCustomEditor(true)
								}}
							>
								<Visibility />
							</IconButton>
						)}
						{event.availableActions.includes(ACTION_TYPE.RECALL) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.RECALL)
									setOpenCustomEditor(true)
								}}
							>
								<Reply />
							</IconButton>
						)}
						{event.availableActions.includes(ACTION_TYPE.COPY) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.COPY)
									setOpenCustomEditor(true)
								}}
							>
								<FileCopy />
							</IconButton>
						)}
						{event.availableActions.includes(ACTION_TYPE.DELETE) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.DELETE)
									setOpenCustomEditor(true)
								}}
							>
								<Delete />
							</IconButton>
						)}
						{event.availableActions.includes(ACTION_TYPE.EDIT) && (
							<IconButton
								size="small"
								onClick={() => {
									setAction(ACTION_TYPE.EDIT)
									setOpenCustomEditor(true)
								}}
							>
								<Edit />
							</IconButton>
						)}

						{action && (
							<CustomEditor
								openCustomEditor={openCustomEditor}
								action={action}
								event={event}
								close={() => {
									setAction('')
									setOpenCustomEditor(false)
								}}
							/>
						)}
					</div>
				</div>
				<DialogContent>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingTop: '5px' }}>
						<TextField
							id="eventStatus"
							value={eventStatus}
							onChange={(e) => setEventStatus(e.target.value)}
							label={t('common:misc.status')}
							size="small"
							variant="outlined"
							disabled={true}
						/>
						<FormControl size="small" variant="outlined" fullWidth>
							<InputLabel id="datagrid-select-label">{t('common:nav.user')}</InputLabel>
							<Select
								labelId="datagrid-select-label"
								id="datagrid-select-small"
								value={eventUser}
								onChange={(e) => {
									setEventUser(e.target.value)
								}}
								disabled={!eventEditable}
								label={t('common:nav.user')}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 300, // Giới hạn chiều cao dropdown
											width: 300, // Giới hạn chiều rộng dropdown
											overflow: 'auto',
										},
									},
								}}
							>
								{mobileUnits.map((mb) => (
									<MenuItem value={mb.number} key={mb.number}>
										{mb.number + ' - ' + mb.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Tooltip
							title={
								isLongText ? (
									<Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
										{eventTitle}
									</Typography>
								) : (
									''
								)
							}
							arrow
						>
							<TextField
								id="eventTitle"
								value={eventTitle}
								onChange={(e) => setEventTitle(e.target.value)}
								label={t('common:misc.recordName')}
								size="small"
								variant="outlined"
								disabled={true}
							/>
						</Tooltip>
						<div></div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								gap: '8px',
							}}
						>
							<Typography variant="body1" color="textSecondary" paddingTop="17px">
								📅 {moment(event.start).format('MM/DD/YYYY')}
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
								<TimePicker
									label={t('common:misc.startTimeScheduler')}
									value={toDayjs(eventStartTime)}
									onChange={(newValue) => setEventStartTime(newValue)}
									sx={{ width: '150px' }}
									disabled={!eventEditable}
								/>
							</LocalizationProvider>
							<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
								<TimePicker
									label={t('common:misc.endTimeScheduler')}
									value={toDayjs(eventEndTime)}
									onChange={(newValue) => setEventEndTime(newValue)}
									sx={{ width: '150px' }}
									disabled={!eventEditable}
								/>
							</LocalizationProvider>
						</div>
						{eventEditable && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
									gap: '8px',
								}}
							>
								<Button onClick={handleSaveEvent} disabled={isSaving} sx={{ fontSize: '18px' }}>
									{t('common:misc.saveAndClose')}
								</Button>
							</div>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		)
	}

	const CustomLoader = () => {
		return (
			<div style={{ textAlign: 'center', padding: '20px' }}>
				<LoadingSpinner />
			</div>
		)
	}

	const titleSuffix = useMemo(() => {
		if (isEmpty(selectedDevice)) return ''

		return selectedDevice?.name || selectedDevice?.number
	}, [tile?.i, selectedDevice])

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={isEmpty(selectedDevice) ? tile?.i : `${tile?.i}  - ${titleSuffix}`}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<DispatchSchedulerSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					dashboardKeyList={dashboardKeyList}
				/>

				<Box
					ref={schedulerBoxRef}
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						overflowY: 'auto',
						height: '95%',
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
                        '*': {
                            boxSizing: 'content-box !important',
                        },
                        
					}}
				>
					{environment?.isLoadingProjects ? (
						<LoadingSpinner />
					) : (
						<div className={classes.schedulerContainer}>
							<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
							{isAllowDispatch && (
								<div>
									<LoadingButton
										id={'send-dispatch'}
										className={classes.icon}
										loadingPosition="start"
										onClick={(e) => {
											dispatch({
												type: ENV_ACTIONS.SHOW_SEND_DISPATCH,
												payload: 'dashboard',
											})
										}}
										startIcon={<ShortcutIcon />}
									>
										{t('common:filters.dispatch')}
									</LoadingButton>
								</div>
							)}
							<Scheduler
								key={schedulerKey}
								ref={schedulerRef}
								view={view}
								events={events}
								hourFormat="24"
								selectedDate={selectedDateDisplay}
								loading={isScheduleLoading}
								loadingComponent={<CustomLoader />}
								customEditor={CustomEditor}
								onViewChange={(view, agenda) => {
									handleViewChange(view, agenda)
								}}
								onEventAdd={handleEventAdd}
								onEventUpdate={handleEventUpdate}
								onEventDelete={handleEventDelete}
								onCellClick={handleCellClick}
								onEventDrop={(event, droppedOn, updatedEvent, originalEvent) => {
									handleEventDrop(event, droppedOn, updatedEvent, originalEvent)
								}}
								onSelectedDateChange={(date) => {
									setSelectedDateDisplay(date)
								}}
								day={{
									startHour: schedulerStartTime || 8,
									endHour: schedulerEndTime || 18,
								}}
								week={{
									startHour: schedulerStartTime || 8,
									endHour: schedulerEndTime || 18,
									weekStartOn: 0,
								}}
								month={{
									weekStartOn: 0,
								}}
								editable={false}
								draggable={true}
								customViewer={CustomViewer}
								eventRenderer={({ event, ...props }) => {
									if (view === 'month') {
										return
									}
									return (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												height: '-webkit-fill-available',
												width: '-webkit-fill-available',
												background: event.color,
												whiteSpace: 'pre-line',
											}}
											{...props}
										>
											<Typography
												variant="h6"
												fontSize="12px"
												padding="6px 6px"
												color="white"
												whiteSpace="pre-line"
											>
												{event.title}
											</Typography>
											{view === 'day' && (
												<div>
													<Typography
														variant="h6"
														fontSize="12px"
														padding="6px 6px"
														color="white"
														whiteSpace="pre-line"
													>
														{event.nickName}
													</Typography>
												</div>
											)}
										</div>
									)
								}}
							/>
							<DoformsSendDispatch
								onDialogOpen={handleCellClick}
								formSelected={formSelected}
								action={'send'}
								title={t('common:tabs.dispatchScheduler')}
								recordsLoading={false}
								source={'dashboard'}
								tab={'forms'}
								environment={environment}
								deviceSelected={selectedDevice}
								selectedDate={selectedDate || null}
								duration={defaultDuration}
							/>
						</div>
					)}
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default DispatchSchedulerTile
