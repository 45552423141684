import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const sectionsApi = {
	getSections(token) {
		return axios.get(`${url}/sections`, {
			headers: apiHeader(token),
		})
	},
}

export default sectionsApi
