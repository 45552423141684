import { useMutation } from '@tanstack/react-query'
import dashboardApi from 'apis/disApi/dashboardApi'
import tileApi from 'apis/disApi/tileApi'
import SplitLayout from 'components/pages/dis/components/SplitLayout'
import { generateRandomID } from 'components/pages/dis/helpers'
import useGroupQuery from 'components/pages/dis/hooks/useGroupQuery'
import useMenuQuery from 'components/pages/dis/hooks/useMenuQuery'
import { cloneDeep, differenceBy, isEmpty, isEqual, unionBy, uniqBy, orderBy } from 'lodash'
import { lazy, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isJson, logErrorMessage } from 'utils/functions/helpers'
import { mobileUnitTileFieldIdMap } from 'utils/params/helpers'
import { IconThemeProvider } from '../../../../custom-components/context/IconThemesContext'
import MenuProvider, { useMenu } from '../contexts/MenuContext'
import MenuDashboard from './MenuDashboard'
import {
    convertToDndKitData,
    updateDraggableColumnsData,
    updateDraggableRowsData,
} from './_utils/common'
import { getMenuItemsWithTileInfo } from './MenuContent'

const MenuContent = lazy(() => import('./MenuContent'))

export const EmptyMenuPage = () => {
    const { environment } = useSelector((state) => state)
    const iconTheme = environment.theme.icons

    const { onResetMenu } = useMenu()
    useEffect(() => {
        onResetMenu()
    }, [])

    return (
        <IconThemeProvider values={iconTheme}>
            <MenuProvider>
                <SplitLayout sidePanel={<MenuDashboard />} mainContent={null} />
            </MenuProvider>
        </IconThemeProvider>
    )
}

export const DefaultMenuPage = () => {
    const { environment } = useSelector((state) => state)
    const iconTheme = environment.theme.icons
    const history = useHistory()

    const {
        detailMenu,
        copiedDetailMenu,
        dragDropItems,
        containers,
        onUpdateMenu,
        onAssignMenu,
        setDragDropItems,
        setContainers,
    } = useMenu()

    const { addMutation: addNewMenuMutation, updateMutation: updateMenuMutation } = useMenuQuery()
    const { groups, assignToMenuMutation: assignGroupToMenuMutation } = useGroupQuery()
    const updateDashboardMutation = useMutation(dashboardApi.update)
    const updateTileMutation = useMutation(tileApi.update)
    const updateAllTileMutation = useMutation(tileApi.updateAll)

    useEffect(() => {
        let isMounted = true
        if (!isMounted) return

        // Update menu items after drag and drop rows
        const draggedRowsMenuItems = updateDraggableRowsData(detailMenu?.items, dragDropItems)

        if (!isEmpty(draggedRowsMenuItems)) {
            onUpdateMenu('items', draggedRowsMenuItems)
        }

        return () => {
            isMounted = false
        }
    }, [JSON.stringify(dragDropItems), JSON.stringify(detailMenu?.items)])

    useEffect(() => {
        let isMounted = true
        if (!isMounted) return

        // Update menu items after drag and drop columns
        const draggedColumnsMenuItems = updateDraggableColumnsData(detailMenu?.items, containers)

        const validColumnsMenuItems = draggedColumnsMenuItems.every((x) =>
            Boolean(x?.dashboard?.key)
        )

        if (detailMenu?.items?.length === 0) {
            onUpdateMenu('items', [])
        }

        if (!isEmpty(draggedColumnsMenuItems) && validColumnsMenuItems) {
            onUpdateMenu('items', draggedColumnsMenuItems)
        }

        return () => {
            isMounted = false
        }
    }, [JSON.stringify(containers), JSON.stringify(detailMenu?.items)])

    const handleAssignWebGroup = async (groupKey, menuKey) => {
        try {
            // Find the group that is already assigned to the given menu key
            const groupByMenuKeyList = groups?.filter((group) => {
                const isMenuAssigned = group?.menuKey === menuKey
                return !groupKey ? isMenuAssigned : group.key === groupKey && isMenuAssigned
            })

            // If there is a group assigned to the menu key, remove the assignment
            if (groupByMenuKeyList?.length > 0) {
                await Promise.all(
                    groupByMenuKeyList.map((group) =>
                        assignGroupToMenuMutation.mutateAsync({
                            groupKey: group.key,
                            data: {
                                menuKey: null,
                            },
                            token: environment.apiToken,
                        })
                    )
                )
            }

            if (!groupKey) return

            // Assign the new group to the menu key
            await assignGroupToMenuMutation.mutateAsync({
                groupKey,
                data: {
                    menuKey,
                },
                token: environment.apiToken,
            })
        } catch (error) {
            throw new Error(error)
        }
    }

    const getSuitablePrimaryKeyListToUpdateAPI = (
        dashboardKeyList,
        primaryKeyList,
        tileName,
        tileKey
    ) => {
        // Create a new primary key list by mapping over the existing primary key list
        const newPrimaryKeyList = [
            ...primaryKeyList.map((item) => ({
                id: generateRandomID(),
                key: item,
                tileName: tileName,
                tileKey: tileKey,
            })),
            ...(dashboardKeyList.filter(
                (item) => primaryKeyList.includes(item.key) || item.tileName !== tileName
            ) ?? []),
        ]

        // Remove duplicates from the new primary key list based on a unique key
        const uniqNewPrimaryKeyList = uniqBy(
            newPrimaryKeyList,
            (item) => `${item.key}-${tileName}-${tileKey}`
        )

        // Keep the old primary keys if they exist in the dashboard key list
        const keepOldPrimaryKeyList = uniqNewPrimaryKeyList.map((item) => {
            const oldDashboardKeyItem = dashboardKeyList.find((oldItem) => oldItem.key === item.key)
            if (Boolean(oldDashboardKeyItem)) {
                return { ...oldDashboardKeyItem, old: true }
            }
            return item
        })

        // Return the final list of primary keys to update
        return keepOldPrimaryKeyList
    }

    const processCreateTilesOfNewMenu = async ({
        newTileList,
        dashboardKey,
        validTileKey = [],
    }) => {
        // Check if newTileList is empty or undefined, return early if true
        const resultMap = {}

        if (newTileList?.length === 0 || !newTileList) return resultMap

        try {
            const tileResponseMap = {}

            const datagridTileList = newTileList.filter((tile) => tile?.type === 'datagrid')
            const mobileUniteTileList = newTileList.filter((tile) => tile?.type === 'MobileUnits')
            const dofromsTileList = newTileList.filter((tile) => tile?.type === 'DoFormsForm')
            const otherTileList = newTileList.filter(
                (tile) =>
                    tile?.type !== 'datagrid' &&
                    tile?.type !== 'MobileUnits' &&
                    tile?.type !== 'DoFormsForm'
            )

            for (const tile of dofromsTileList) {
                await handleCreateTile(tile)
            }
            for (const tile of mobileUniteTileList) {
                await handleCreateTile(tile)
            }
            // Ensure that the datagridTileList runs first to avoid the error of creating a tile with a connected datagrid that does not exist
            // await Promise.all(datagridTileList.map((tile) => handleCreateTile(tile)))
            for (const tile of datagridTileList) {
                await handleCreateTile(tile)
            }
            await Promise.all(otherTileList.map((tile) => handleCreateTile(tile)))

            async function handleCreateTile(tile) {
                if (!tile) return null

                // Destructure settings and primaryKeyList from tile object, assign remaining properties to othersTileInfo
                const {
                    settings,
                    primaryKeyList,
                    connectedNameWithNewDatagrid,
                    connectedNameWithNewDoForms,
                    bgColorRowCondition,
                    ...othersTileInfo
                } = tile

                let editedSetting = settings

                // Check if primaryKeyList is not empty
                const isHavePrimaryKey = !isEmpty(primaryKeyList)
                const isConnectedWithNewDatagrid = Boolean(connectedNameWithNewDatagrid)
                const isHaveBgColorRowCondition = !isEmpty(bgColorRowCondition)
                const isConnectedNameWithNewDoForms = !isEmpty(connectedNameWithNewDoForms)

                // Destructure key from othersTileInfo object, assign remaining properties to newTile
                const { key, ...newTile } = othersTileInfo

                // Add new tile to the dashboard using tileApi.add() method
                const tileResponse = await tileApi.add({
                    dashboardKey: dashboardKey,
                    data: newTile,
                    token: environment.apiToken,
                })

                const tileKey = tileResponse.data.key
                const tileName = tileResponse.data.name

                if (validTileKey && validTileKey.length > 0) {
                    validTileKey = validTileKey.map((validKey) =>
                        validKey === key ? tileKey : validKey
                    )
                }

                if (isConnectedNameWithNewDoForms) {
                    const parsedSettings = isJson(settings) ? JSON.parse(settings) : {}
                    parsedSettings.connectedDoFormsKey =
                        tileResponseMap[connectedNameWithNewDoForms]
                    editedSetting = JSON.stringify(parsedSettings)
                } else {
                    tileResponseMap[tileName] = tileKey
                }

                if (isConnectedWithNewDatagrid) {
                    const parsedSettings = isJson(settings) ? JSON.parse(settings) : {}
                    parsedSettings.connectedDatagridKey =
                        tileResponseMap[connectedNameWithNewDatagrid]

                    editedSetting = JSON.stringify(parsedSettings)
                } else {
                    tileResponseMap[tileName] = tileKey
                }

                // If tile has primaryKeyList, update dashboard variables with newPrimaryKeyList
                if (isHavePrimaryKey) {
                    // Get dashboard data using dashboardApi.get() method
                    const dashboardResponse = await dashboardApi.get(
                        dashboardKey,
                        environment.apiToken
                    )

                    // Parse dashboard variables if it's a valid JSON, otherwise default to an empty object
                    const dashboardKeyList =
                        dashboardResponse?.data?.variables &&
                        isJson(dashboardResponse?.data?.variables)
                            ? JSON.parse(dashboardResponse?.data?.variables ?? '{}')
                            : []

                    // There are some dashboard keys saved even though creating the tile failed.
                    // Filter the error keys if they still exist
                    let filteredDashboardKeyList = dashboardKeyList
                    if (validTileKey && validTileKey.length > 0) {
                        filteredDashboardKeyList = dashboardKeyList.filter((item) =>
                            validTileKey.includes(item.tileKey)
                        )
                    }

                    // Get the suitable primary key list to update the API
                    // const newPrimaryKeyList = getSuitablePrimaryKeyListToUpdateAPI(
                    // 	filtereDashboardKeyList,
                    // 	primaryKeyList,
                    // 	tileName,
                    // 	tileKey
                    // )

                    // Create a new primary key list by mapping over the existing primary key list
                    const isMobileUnitTiles = tileResponse.data.type === 'MobileUnits'
                    const isMasterDateTimeTile = tileResponse.data.type === 'MasterDateTime'
                    const newPrimaryKeyList = [
                        ...primaryKeyList.map((key) => {
                            const newPrimaryKey = {
                                id: generateRandomID(),
                                key: key,
                                tileName: tileName,
                                tileKey: tileKey,
                            }

                            if (isMobileUnitTiles) {
                                newPrimaryKey['mobileUnitKey'] = mobileUnitTileFieldIdMap[key]
                            } else if (
                                isMasterDateTimeTile &&
                                [
                                    'Master_DateTime',
                                    'Master_DateTime_Start',
                                    'Master_DateTime_End',
                                ].includes(key)
                            ) {
                                newPrimaryKey['selectableTypes'] = ['DATE', 'DATETIME']
                            }

                            return newPrimaryKey
                        }),
                        ...filteredDashboardKeyList,
                    ]

                    const hasPrimaryKeyList = newPrimaryKeyList?.length > 0

                    if (hasPrimaryKeyList) {
                        // Update dashboard variables using updateDashboardMutation.mutateAsync() method
                        await updateDashboardMutation.mutateAsync({
                            key: dashboardKey,
                            data: {
                                variables: JSON.stringify(newPrimaryKeyList),
                            },
                            token: environment.apiToken,
                        })
                    }
                }

                const parsedSettings = isJson(editedSetting) ? JSON.parse(editedSetting) : {}
                parsedSettings.bgColorRowCondition = isHaveBgColorRowCondition
                    ? bgColorRowCondition
                    : {}
                editedSetting = JSON.stringify(parsedSettings)

                // Update tile settings using updateTileMutation.mutateAsync() method
                await updateTileMutation.mutateAsync({
                    dashboardKey,
                    tileKey,
                    data: { settings: editedSetting },
                    token: environment.apiToken,
                })

                resultMap[key] = tileKey
            }
        } catch (error) {
            logErrorMessage(error)
        }

        return resultMap
    }

    const getMenuItemsWithNewDashboardKey = async (menuItems) => {
        try {
            const result = []
            for (const menuItem of menuItems) {
                // Create a new object based on the current menu item
                const newItem = { ...menuItem }
                // If the menu item is not creating, push it to the result array
                if (!menuItem?.is_creating) {
                    if (menuItem.items && menuItem.items.length > 0) {
                        newItem.items = await getMenuItemsWithNewDashboardKey(menuItem.items)
                    }
                    result.push(newItem)
                    continue
                }

                // Destructure the necessary properties from the new item
                const { name, tileInfo } = newItem

                // Create a new dashboard object with the name
                const newDashboard = {
                    name,
                }

                // Add the new dashboard using the dashboard API and get the key
                const dashboardResponse = await dashboardApi.add(newDashboard, environment.apiToken)
                const dashboardKey = dashboardResponse.data.key

                // Process the create tiles of the new menu using the new dashboard key
                const results = await processCreateTilesOfNewMenu({
                    newTileList: tileInfo,
                    dashboardKey,
                })

                // Update new Tile key
                let newTileInfo = {}
                if (!isEmpty(results)) {
                    const tmpTileInfo = cloneDeep(tileInfo)
                    newTileInfo = tmpTileInfo.map((tile) => {
                        // remove is_creating
                        const { is_creating, ...tileProps } = tile
                        return {
                            ...tileProps,
                            key: results[tile.key] || tile.key,
                        }
                    })
                } else {
                    newTileInfo = tileInfo
                }

                // Recursively update the dashboard keys of any nested menu items
                if (menuItem.items && menuItem.items.length > 0) {
                    newItem.items = await getMenuItemsWithNewDashboardKey(menuItem.items)
                }

                // Remove the tileInfo property from the new item
                const { tileInfo: _tileInfo, dashboard, is_creating, ...othersItem } = newItem

                // Add the updated item to the result array with the new dashboard key
                result.push({
                    ...othersItem,
                    tileInfo: newTileInfo,
                    dashboard: {
                        key: dashboardKey,
                    },
                })
            }
            return result
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleCreateNewMenu = async () => {
        try {
            // Destructure properties from detailMenu object
            const { name, settings, groupKey, items } = detailMenu

            // Prepare newMenu object with name and settings properties
            const newMenu = {
                name: name,
                // Convert settings to JSON string if it exists, otherwise set it to undefined
                settings: Boolean(settings) ? JSON.stringify(settings) : undefined,
                items: [],
            }

            // Call addNewMenuMutation to create a new menu
            const menuResponse = await addNewMenuMutation.mutateAsync({
                data: newMenu,
                token: environment.apiToken,
            })

            // Get the key of the newly created menu
            const menuKey = menuResponse.data.key

            // If groupKey exists, assign the menu to the web group
            if (groupKey) {
                await handleAssignWebGroup(groupKey, menuKey)
            }

            // If items exist and have a length greater than 0, update the menu items
            if (items?.length > 0) {
                // Get menu items with new dashboard keys
                const menuItemsWithDashboardKey = await getMenuItemsWithNewDashboardKey(items)

                // Remove tileInfo key
                const menuItemsWithoutTileInfo = menuItemsWithDashboardKey.map((item) => {
                    const { tileInfo, ...props } = item
                    return props
                })

                // Call updateMenuMutation to update the menu with new items
                await updateMenuMutation.mutateAsync({
                    id: menuKey,
                    newData: { items: menuItemsWithoutTileInfo },
                    token: environment.apiToken,
                })
            }

            // Update the menu key in the menu context
            onUpdateMenu('key', menuKey)

            // Update query params with new menu key
            history.push({
                search: new URLSearchParams({ menu: menuKey }).toString(),
            })
        } catch (error) {
            logErrorMessage(error)
        }
    }

    const processUpdatePrimaryKeyListAndBgColorCondition = async (
        tileInfoList,
        dashboardKey,
        validTileKey
    ) => {
        try {
            // Iterate over each item in the tileInfoList
            const updatedPrimaryKeyListOfTiles = []
            // Get dashboard data using dashboardApi.get() method
            const dashboardResponse = await dashboardApi.get(dashboardKey, environment.apiToken)

            // Parse dashboard variables if it's a valid JSON, otherwise default to an empty object
            const dashboardKeyList =
                dashboardResponse?.data?.variables && isJson(dashboardResponse?.data?.variables)
                    ? JSON.parse(dashboardResponse?.data?.variables ?? '{}')
                    : []
            let filteredDashboardKeyList = dashboardKeyList
            for (const item of tileInfoList) {
                const tileName = item.name
                const tileKey = item.key
                // Get the primaryKeyList for the current item
                const primaryKeyList = item?.primaryKeyList
                const isHaveBgColorRowCondition = !isEmpty(item?.bgColorRowCondition)

                // Check if the item has a primaryKeyList
                const hasPrimaryKeyList = primaryKeyList?.length > 0

                // If the item has a primaryKeyList, update the dashboard with the new primary keys
                if (hasPrimaryKeyList) {
                    // There are some dashboard keys saved even though creating the tile failed.
                    // Filter the error keys if they still exist
                    if (validTileKey && validTileKey.length > 0) {
                        filteredDashboardKeyList = filteredDashboardKeyList.filter((item) =>
                            validTileKey.includes(item.tileKey)
                        )
                    }

                    const isMobileUnitTiles = item.type === 'MobileUnits'
                    const newPrimaryKeyList = [
                        ...primaryKeyList.map((key) => {
                            const newPrimaryKey = {
                                id: generateRandomID(),
                                key: key,
                                tileName: tileName,
                                tileKey: tileKey,
                            }

                            if (isMobileUnitTiles) {
                                newPrimaryKey['mobileUnitKey'] = mobileUnitTileFieldIdMap[key]
                            }

                            return newPrimaryKey
                        }),
                    ]

                    // Remove duplicates from the new primary key list based on a unique key
                    const uniqNewPrimaryKeyList = uniqBy(
                        newPrimaryKeyList,
                        (item) => `${item.key}-${tileKey}`
                    )

                    // mark delete primary key
                    const deletedPrimaryKeyList = filteredDashboardKeyList
                        .filter((item) => tileKey === item.tileKey)
                        .map((oldItem) => {
                            const hasInNewList = uniqNewPrimaryKeyList.find(
                                (newItem) =>
                                    oldItem.key === newItem.key &&
                                    oldItem.tileKey === newItem.tileKey
                            )

                            if (hasInNewList) return null

                            return {
                                ...oldItem,
                                deleted: true,
                            }
                        })
                        .filter((item) => !!item)

                    const allKeys = [...uniqNewPrimaryKeyList, ...deletedPrimaryKeyList]

                    // Keep the old primary keys if they exist in the dashboard key list
                    const keepOldPrimaryKeyList = allKeys.map((item) => {
                        const deletedKey = deletedPrimaryKeyList.find(
                            (deletedItem) =>
                                deletedItem.key === item.key &&
                                // deletedItem.tileName === item.tileName &&
                                deletedItem.tileKey === item.tileKey
                        )
                        if (Boolean(deletedKey)) {
                            return deletedKey
                        }

                        const oldDashboardKeyItem = filteredDashboardKeyList.find(
                            (oldItem) =>
                                oldItem.key === item.key && oldItem.tileKey === item.tileKey
                        )

                        if (Boolean(oldDashboardKeyItem)) {
                            return { ...oldDashboardKeyItem, tileName, old: true }
                        }
                        return item
                    })

                    updatedPrimaryKeyListOfTiles.push(...keepOldPrimaryKeyList)
                } else {
                    const deletedPrimaryKeyList = filteredDashboardKeyList
                        .filter((item) => tileKey === item.tileKey)
                        .map((oldItem) => {
                            return {
                                ...oldItem,
                                deleted: true,
                            }
                        })
                        .filter((item) => !!item)

                    if (!isEmpty(deletedPrimaryKeyList)) {
                        updatedPrimaryKeyListOfTiles.push(...deletedPrimaryKeyList)
                    }
                }

                if (!item?.is_creating) {
                    let editedSetting = item.settings
                    const parsedSettings = isJson(editedSetting) ? JSON.parse(editedSetting) : {}
                    parsedSettings.bgColorRowCondition = isHaveBgColorRowCondition
                        ? item?.bgColorRowCondition
                        : {}
                    editedSetting = JSON.stringify(parsedSettings)

                    await updateTileMutation.mutateAsync({
                        dashboardKey,
                        tileKey: item?.key,
                        data: { settings: editedSetting },
                        token: environment.apiToken,
                    })
                }
            }

            const unionPrimaryKeyList = unionBy(
                filteredDashboardKeyList,
                updatedPrimaryKeyListOfTiles,
                (item) => `${item.id}-${item.tileKey}`
            )

            const primaryKeysToSave = unionPrimaryKeyList
                .map((item) => {
                    const uniqString = `${item.id}-${item.tileKey}`
                    const updatedKey = updatedPrimaryKeyListOfTiles.find((updateKey) => {
                        const uniqUpdatedString = `${updateKey.id}-${updateKey.tileKey}`
                        return isEqual(uniqString, uniqUpdatedString)
                    })

                    // new key from create tile
                    if (!updatedKey) return { ...item }

                    // updated or deleted key
                    if (updatedKey.deleted) return null

                    return updatedKey
                })
                .filter((item) => !!item)

            const orderedSaveList = orderBy(
                primaryKeysToSave,
                (item) => `${item.tileName}-${item.tileKey}-${item.id}`
            )

            // save the dashboard key once
            await updateDashboardMutation.mutateAsync({
                key: dashboardKey,
                data: {
                    variables: JSON.stringify(orderedSaveList),
                },
                token: environment.apiToken,
            })
        } catch (error) {
            // Throw an error if any exception occurs
            throw new Error(error)
        }
    }

    const processUpdateTiles = async (menuItems, validTileKey = [], deletedTileInfo) => {
        try {
            // Iterate over each menu item in the array
            for (const menuItem of menuItems) {
                if (menuItem?.is_creating) {
                    continue
                }

                // Create a new object by spreading the properties of the current menuItem
                const newItem = { ...menuItem }

                // If the menuItem does not have a tileInfo property, skip to the next iteration
                if (!menuItem?.tileInfo) {
                    if (menuItem.items && menuItem.items.length > 0) {
                        await processUpdateTiles(menuItem.items, validTileKey, deletedTileInfo)
                    }
                    continue
                }

                //Get all valid Tilekeys
                validTileKey.push(...menuItem?.tileInfo?.map((tile) => tile.key))

                // Retrieve the dashboard key from the newItem
                const dashboardKey = newItem?.dashboard?.key

                const tileInfoList = newItem?.tileInfo

                const newTileList = tileInfoList?.filter((item) => item?.is_creating)
                if (!isEmpty(deletedTileInfo[dashboardKey])) {
                    const deletedKeys = deletedTileInfo[dashboardKey]
                    newTileList.push(
                        ...tileInfoList?.filter((item) => deletedKeys.includes(item.key))
                    )
                }
                const resultMap = await processCreateTilesOfNewMenu({
                    newTileList,
                    dashboardKey,
                    validTileKey,
                })

                if (!isEmpty(resultMap)) {
                    validTileKey = validTileKey.map((validKey) => resultMap[validKey] ?? validKey)
                }

                // Call the processUpdatePrimaryKeyList function to process the tileInfo and dashboardKey
                let updatingTileList = tileInfoList?.filter((item) => !item?.is_creating)
                if (!isEmpty(deletedTileInfo[dashboardKey])) {
                    const deletedKeys = deletedTileInfo[dashboardKey]
                    updatingTileList = updatingTileList.filter(
                        (item) => !deletedKeys.includes(item.key)
                    )
                }
                await processUpdatePrimaryKeyListAndBgColorCondition(
                    updatingTileList,
                    dashboardKey,
                    validTileKey
                )

                // Create a new array of menu items without the primaryKeyList property
                const editedTileList = updatingTileList?.map((item) => {
                    // Delete unnecessary properties from the item object
                    const { primaryKeyList, bgColorRowCondition, settings, ...othersItem } = item

                    // Return the othersItem object
                    return othersItem
                })

                // Call the updateAllTileMutation function to update all the tiles with the new menu items
                await updateAllTileMutation.mutateAsync({
                    dashboardKey: dashboardKey,
                    data: editedTileList,
                    token: environment.apiToken,
                })

                // Recursively update the dashboard keys of any nested menu items
                if (menuItem.items && menuItem.items.length > 0) {
                    await processUpdateTiles(menuItem.items, validTileKey, deletedTileInfo)
                }
            }
        } catch (error) {
            // Throw an error if any exception occurs during the processing
            throw new Error(error)
        }
    }

    const processDeleteTiles = async (menuItems, copiedItems) => {
        let deletedTileInfo = {}
        try {
            // Iterate over each menu item in the array
            for (let i = 0; i < menuItems.length; i++) {
                const menuItem = menuItems[i]
                const copiedMenuItem = copiedItems[i]

                if (menuItem?.is_creating) {
                    continue
                }

                const tileInfo = menuItem?.tileInfo
                const copiedTileInfo = copiedMenuItem?.tileInfo

                const dashboardKey = copiedMenuItem?.dashboard?.key
                const deletedTileList = differenceBy(copiedTileInfo, tileInfo, 'key').filter(
                    (item) => !item?.is_creating
                )

                if (deletedTileList?.length > 0) {
                    // loop through each tile in deletedTileList
                    await Promise.all(
                        deletedTileList.map(async (tile) => {
                            const { key } = tile
                            await tileApi.remove({
                                dashboardKey,
                                tileKey: key,
                                token: environment.apiToken,
                            })

                            if (isEmpty(deletedTileInfo[dashboardKey])) {
                                deletedTileInfo[dashboardKey] = [key]
                            } else {
                                const currentValue = deletedTileInfo[dashboardKey]
                                deletedTileInfo[dashboardKey] = [...currentValue, key]
                            }
                        })
                    )
                }

                if (menuItem.items && menuItem.items.length > 0) {
                    const tileInfo = await processDeleteTiles(menuItem.items, copiedMenuItem.items)
                    deletedTileInfo = { ...deletedTileInfo, ...tileInfo }
                }
            }
        } catch (error) {
            // Throw an error if any exception occurs during the processing
            throw new Error(error)
        }

        return deletedTileInfo
    }

    const handleEditMenu = async () => {
        try {
            // Destructure properties from detailMenu object
            const { groupKey, settings, items, ...othersMenu } = detailMenu
            const { items: copiedItems } = copiedDetailMenu.current

            // Get the menu key from the othersMenu object
            const menuKey = othersMenu?.key

            const deletedTileInfo = await processDeleteTiles(items, copiedItems)

            await processUpdateTiles(items, [], deletedTileInfo)

            // Get menu items with new dashboard key
            const menuItemsWithDashboardKey = await getMenuItemsWithNewDashboardKey(items)

            // Remove tileInfo key
            const menuItemsWithoutTileInfo = menuItemsWithDashboardKey.map((item) => {
                const { tileInfo, ...props } = item
                return props
            })

            // Create editedData object with updated properties
            const editedData = {
                ...othersMenu,
                items: menuItemsWithoutTileInfo,
                settings: JSON.stringify(settings),
            }

            // If groupKey exists, assign the menu to the web group
            await handleAssignWebGroup(groupKey, menuKey)

            // Update the menu using the updateMenuMutation function
            await updateMenuMutation.mutateAsync({
                id: menuKey,
                newData: editedData,
                token: environment.apiToken,
            })

            const newItems = await getMenuItemsWithTileInfo(environment, menuItemsWithDashboardKey)

            onAssignMenu({
                items: newItems,
            })

            const dragDropData = convertToDndKitData(newItems)
            setDragDropItems(dragDropData)
            setContainers(Object.keys(dragDropData))
        } catch (error) {
            // Throw an error if any exception occurs
            throw new Error(error)
        }
    }

    return (
        <IconThemeProvider values={iconTheme}>
            <SplitLayout
                sidePanel={<MenuDashboard />}
                mainContent={
                    <MenuContent
                        key={detailMenu?.key}
                        onSubmitCreateMenu={handleCreateNewMenu}
                        onSubmitEditMenu={handleEditMenu}
                    />
                }
            />
        </IconThemeProvider>
    )
}
