import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import GeoMapLoginForm from 'components/pages/dis/tile/map/geotab-map/GeoMapLoginForm'
import _ from 'lodash'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getMapLoggedInInfoFromStorage } from 'utils/functions/helpers'
import { MAP_LOGGED_IN_INFO } from '../constants'

function DoformsMapLogin() {
	const history = useHistory()
	const location = useLocation()

	const [open, setOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !_.isEmpty(userInfo)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleLogout = async () => {
		setIsLoading(true)
		localStorage.removeItem(MAP_LOGGED_IN_INFO)

		const searchParams = new URLSearchParams(location.search)
		searchParams.set('isMapLogout', true)
		history.push({
			pathname: location.path,
			search: searchParams.toString(),
		})
		setIsLoading(false)
	}

	return (
		<Box sx={{ pb: 2, width: '100%' }}>
			<Typography variant={'subtitle1'}>Geotab</Typography>
			{!isLoggedIn ? (
				<>
					<Button variant="contained" onClick={handleClickOpen}>
						Login
					</Button>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth={true}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<GeoMapLoginForm onCloseDialog={handleClose} />
					</Dialog>
				</>
			) : (
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography>{userInfo?.credentials?.userName}</Typography>
					<LoadingButton
						variant="outlined"
						onClick={handleLogout}
						disabled={isLoading}
						loading={isLoading}
					>
						Logout
					</LoadingButton>
				</Stack>
			)}
		</Box>
	)
}

export default DoformsMapLogin
