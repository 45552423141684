import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { toZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toDayjs, parseAs } from 'utils/functions/doformsDateUtil'
import moment from 'moment'

function DriverMapFilters({ userEmail, fromDate, toDate, disableFilters, onFilters }) {
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)

	const { environment } = useSelector((state) => state)
	const userOffset = useMemo(() => {
		return environment?.userCurrent?.time?.timezone?.offset || 0
	}, [environment])
	const displayFromDate = useMemo(() => {
		const currentUTCMoment = moment
			.utc(fromDate)
			.utcOffset(userOffset)
			.format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, fromDate])

	const displayToDate = useMemo(() => {
		const currentUTCMoment = moment.utc(toDate).utcOffset(userOffset).format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, toDate])

	const handleFromDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())
		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('fromDate', null)
			return
		}
		const startDayMoment = momentValue.clone().startOf('day')
		const userMoment = parseAs(startDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		onFilters?.('fromDate', startDayUTC)
	}

	const handleToDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('toDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())
		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('toDate', null)
			return
		}

		const endDayMoment = momentValue.clone().endOf('day')
		const userMoment = parseAs(endDayMoment, userOffset)
		// to UTC time
		const endDayUTC = userMoment.toISOString()
		onFilters?.('toDate', endDayUTC)
	}

	return (
		<>
			<TextField
				size="small"
				id="userEmail"
				label={'User email'}
				disabled={disableFilters}
				value={userEmail || ''}
				onChange={(e) => {
					const value = e.target.value
					onFilters('userEmail', value)
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>

			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.fromDate')}
					disabled={disableFilters}
					value={displayFromDate}
					maxDate={toDayjs(locale.maxDate)}
					onChange={handleFromDateChange}
					onAccept={handleFromDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: 'small',
								variant: 'outlined',
								error: false,
							},
							onChange: (e) => {
								if (!e?.isValid()) return
								handleFromDateChange(e)
							},
						},
					}}
				/>
			</LocalizationProvider>

			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.toDate')}
					disabled={disableFilters}
					value={displayToDate}
					maxDate={toDayjs(locale.maxDate)}
					onChange={handleToDateChange}
					onAccept={handleToDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: 'small',
								variant: 'outlined',

								// Turn off the error when the user clears the date
								error: false,
							},
							onChange: (e) => {
								if (!e?.isValid()) return
								handleToDateChange(e)
							},
						},
					}}
				/>
			</LocalizationProvider>
		</>
	)
}

export default DriverMapFilters
