import { makeStyles } from '@mui/styles'
import ReactECharts from 'echarts-for-react'
import { cloneDeep, map, uniq } from 'lodash'
import moment from 'moment'
import { Resizable } from 're-resizable'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import colorScheme from 'utils/functions/colorsOptions'
import SkeletonLoaderGridChart from '../../../custom-components/skeletons/SkeletonLoaderGridChart'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'

import useForceRerender from 'utils/hooks/useForceRerender'
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css'
import DoformsDataChart from './DoformsDataChart'
import { currencyFormatter } from '../dataHelpers'

const CONST_ONE_PERCENT_RADIAN = 0.062831853071796

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
	axisBox: {
		display: 'inline-grid',
		marginTop: '10px',
		textAlign: 'left',
	},
	splitAxisBox: {
		display: 'inline-grid',
		marginTop: '10px',
		textAlign: 'left',
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},
	buttonGroup: {
		'& .MuiToggleButtonGroup-grouped': {
			border: 0,
			textTransform: 'none',
			'&.Mui-disabled': {
				border: 0,
			},
		},
	},
	listBody: {
		maxHeight: '15em',
		overflow: 'auto',
	},
	footer: {
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'end',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	chart: (props) => ({
		position: 'relative',
		paddingBottom: '20px',
		'& #top-container': {
			alignItems: 'center',
			display: 'block !important',
			justifyContent: 'end',
			'& .MuiTypography-root': {
				marginBottom: '0 !important',
			},
			'& .MuiList-root': {
				flexDirection: 'row-reverse !important',
				flexWrap: 'wrap',
				marginBottom: '20px !important',
			},
			'& .MuiListItem-root': {
				textTransform: 'none !important',
				width: 'auto',
			},
		},
	}),
	editButton: {
		position: 'absolute',
		top: '-7px',
		right: '1%',
		zIndex: 99,
	},
}))

const DoformsGridChart = (props) => {
	const {
		formMapRef,
		chartSetting,
		setChartSetting,
		columns,
		gridRows,
		onHandleFilters,
		hiddenDisplayChartBtn,
		hiddenDisplayEditChartBtn,
		id,
		showResizeSlider,
	} = props
	const environment = useSelector((state) => state.environment)
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [t] = useTranslation('common')

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)

	const [seriesOmit, setSeriesOmit] = useState([])
	const [forceId, setForceRerender] = useForceRerender()

	const records = useMemo(() => {
		return [...gridRows]
	}, [gridRows])

	const chartColor = ['red', 'green', 'blue']

	const [chartSelected, setChartSelected] = useState(null)
	const [drawChartSelected, setDrawChartSelected] = useState(null)
	const [methodSelected, setMethodSelected] = useState(null)
	const [dateGroupSelected, setDateGroupSelected] = useState('day')
	const [xAxisSelected, setXAxisSelected] = useState('')
	const [yAxisSelected, setYAxisSelected] = useState('')
	const [yAxis2Selected, setYAxis2Selected] = useState('')
	const [yAxis3Selected, setYAxis3Selected] = useState('')
	const [xAxisSelectedTitle, setXAxisSelectedTitle] = useState('')
	const [yAxisSelectedTitle, setYAxisSelectedTitle] = useState('')
	const [yAxis2SelectedTitle, setYAxis2SelectedTitle] = useState('')
	const [yAxis3SelectedTitle, setYAxis3SelectedTitle] = useState('')

	const [splitAxisSelected, setSplitAxisSelected] = useState('')
	const [splitAxisSelectedTitle, setSplitAxisSelectedTitle] = useState('')

	const [dataSet, setDataSet] = useState([])
	const [renderChart, setRenderChart] = useState(false)
	const [chartDisplayed, setChartDisplayed] = useState(false)
	const [splitIntoMultipleSeries, setSplitIntoMultipleSeries] = useState(false)
	const [showName, setShowName] = useState(false)
	const [showValue, setShowValue] = useState(false)
	const [showPercent, setShowPercent] = useState(false)
	const [stackBarSeries, setStackBarSeries] = useState(false)
	const [chartDS, setChartDS] = useState(null)

	useEffect(() => {
		if (chartSetting) {
			loadSavedChart()
		}
	}, [forceId, gridRows]) // forceID: when user click on save button, gridRows: when user change filter

	let mapPointY = 0

	const handleResizeMapStart = (e, dir) => {
		mapPointY = formMapRef.current.scrollTop
	}

	const handleResizingMap = (event, direction, refToElement, delta) => {
		formMapRef.current.scrollTop = mapPointY
	}

	const loadSavedChart = () => {
		let isRenderChart = true
		if (chartSetting) {
			if (chartSetting.display) {
				setChartDisplayed(chartSetting.display)
			}
			if (chartSetting.type) {
				setChartSelected(chartSetting.type)
			} else {
				isRenderChart = false
			}
			if (chartSetting.method) {
				setMethodSelected(chartSetting.method)
			} else {
				isRenderChart = false
			}

			if (chartSetting.step) {
				setDateGroupSelected(chartSetting.step)
			}

			if (chartSetting.splitAxis && chartSetting.splitAxis.length > 0) {
				setSplitIntoMultipleSeries(true)
				setSplitAxisSelected(chartSetting.splitAxis[0].name)
				setSplitAxisSelectedTitle(chartSetting.splitAxis[0].title)
			} else {
				setSplitIntoMultipleSeries(false)
				setSplitAxisSelected('')
				setSplitAxisSelectedTitle('')
			}

			if (chartSetting.features && chartSetting.features.length > 0) {
				if (chartSetting.features.includes('stack')) {
					setStackBarSeries(true)
				} else {
					setStackBarSeries(false)
				}
			} else {
				setStackBarSeries(false)
			}

			if (chartSetting.xAxis && chartSetting.xAxis.length > 0) {
				setXAxisSelected(chartSetting.xAxis[0].name)
				setXAxisSelectedTitle(chartSetting.xAxis[0].title)
			} else {
				isRenderChart = false
			}
			if (chartSetting.yAxis && chartSetting.yAxis.length > 0) {
				if (chartSetting.yAxis.length > 0) {
					setYAxisSelected(chartSetting.yAxis[0].name)
					setYAxisSelectedTitle(chartSetting.yAxis[0].title)
				}
				if (chartSetting.yAxis.length > 1) {
					setYAxis2Selected(chartSetting.yAxis[1].name)
					setYAxis2SelectedTitle(chartSetting.yAxis[1].title)
				}
				if (chartSetting.yAxis.length > 2) {
					setYAxis3Selected(chartSetting.yAxis[2].name)
					setYAxis3SelectedTitle(chartSetting.yAxis[2].title)
				}
			} else if (isRenderChart) {
				if (chartSetting.method !== 'count') {
					isRenderChart = false
				}
			}
			if (isRenderChart) {
				setRenderChart(true)
			}
			if (chartSetting.labels && chartSetting.labels.length > 0) {
				chartSetting.labels.forEach((item, index) => {
					switch (item) {
						case 'showName':
							setShowName(true)
							break
						case 'showValue':
							setShowValue(true)
							break
						case 'showPercent':
							setShowPercent(true)
							break
						default:
							break
					}
				})
			} else {
				setShowName(false)
				setShowValue(false)
				setShowPercent(false)
			}
		}
	}

	const valueOf = (value) => {
		if (typeof value === 'string') {
			value = parseFloat(value)
		}
		return !Number.isNaN(value) ? value : 0
	}

	const titleOfchart = useMemo(() => {
		let colValName =
			methodSelected === 'count'
				? t('common:chart.count')
				: methodSelected === 'sum'
				? t('common:chart.total')
				: t('common:chart.average')
		switch (methodSelected) {
			case 'count':
				return colValName + ` ${t('common:chart.by')} ` + xAxisSelectedTitle
				return
			default:
				let ofLabel = ''
				let s = ''
				if (yAxisSelectedTitle) {
					ofLabel += s
					ofLabel += yAxisSelectedTitle
					s = ', '
				}
				if (yAxis2SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis2SelectedTitle
					s = ', '
				}
				if (yAxis3SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis3SelectedTitle
				}
				return (
					colValName +
					` ${t('common:chart.of')} ` +
					ofLabel +
					` ${t('common:chart.by')} ` +
					xAxisSelectedTitle
				)
		}
	}, [dataSet])

	const computeStep = (xAxisData) => {
		if (!xAxisData) return []
		if (!xAxisData.length) return []

		var result = []

		var dateRange = xAxisData.sort(function (left, right) {
			return new Date(left).getTime() - new Date(right).getTime()
		})
		var oldest = dateRange[0]
		var recent = dateRange[dateRange.length - 1]

		var differenceInTime = new Date(recent).getTime() - new Date(oldest).getTime()
		var differenceInDays = differenceInTime / (1000 * 3600 * 24)

		for (var i = 0; i <= differenceInDays; i++) {
			var dateObj = new Date(oldest)
			var momentObj = moment(dateObj)

			result.push(momentObj.format('MM/DD/YYYY'))

			var momentNext = momentObj.add(1, 'days')
			var momentString = momentNext.format('MM/DD/YYYY')
			oldest = momentNext.format('MM/DD/YYYY')
		}
		return result
	}

	const xAxisDataSet = () => {
		if (dataSet == null) return []
		let xAxisData = dataSet.map((item) => {
			return item['xAxisData']
		})
		return xAxisData
	}

	const yAxisDataSet = useMemo(() => {
		if (dataSet == null) return []
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let yAxisData = dataSet.map((item) => {
			return item[colValName]
		})
		return yAxisData
	}, [dataSet])

	const chartDataSet = useMemo(() => {
		if (!dataSet || dataSet.length == 0) return []
		let colXSelected = columns.filter((col) => col['name'] === xAxisSelected)
		if (colXSelected.length == 0) return []
		switch (colXSelected[0].type) {
			case 'DATE':
			case 'DATETIME':
			case 'TIME':
				let dayDataSetRet = []
				let dayDataSet = computeStep(xAxisDataSet())
				if (splitIntoMultipleSeries) {
					let mappedSplitAxisRecord = map(records, splitAxisSelected)
					let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
					dayDataSet.forEach((item) => {
						let itemDayData = { xAxisData: item, count: 0, total: 0, average: 0 }
						if (distinctMappedSplitAxisRecord) {
							distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
								itemDayData[index + '_total'] = 0
								itemDayData[index + '_average'] = 0
								itemDayData[index + '_count'] = 0
							})
						}
						dayDataSetRet.push(itemDayData)
					})
					if (dayDataSetRet.length === 0) return dataSet
					dataSet.forEach((item) => {
						var xVal = item['xAxisData']
						xVal = new Date(xVal)
						var momentObj = moment(xVal)
						xVal = momentObj.format('MM/DD/YYYY')
						let dayItem = dayDataSetRet.filter((item) => item['xAxisData'] === xVal)[0]
						if (dayItem !== undefined) {
							dayItem.count += item.count
							if (yAxisSelected) {
								dayItem.total += item.total
								dayItem.average += item.average
							}
							if (distinctMappedSplitAxisRecord && item.splitData) {
								distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
									let total = item.splitData[splitItem].total
									let average = item.splitData[splitItem].average
									let caount = item.splitData[splitItem].caount
									dayItem[index + '_total'] += isNaN(total) ? 0 : total
									dayItem[index + '_average'] += isNaN(average) ? 0 : average
									dayItem[index + '_count'] += isNaN(caount) ? 0 : caount
								})
							}
						}
					})
					if (dateGroupSelected !== 'day') {
						let result = []
						dayDataSetRet.forEach((day, index) => {
							let now = new Date(day['xAxisData'])
							let timeUse
							switch (dateGroupSelected) {
								case 'week':
									let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
									let lastSunday = new Date(today.setDate(today.getDate() - today.getDay()))
									timeUse = moment(lastSunday).format('MM/DD/YYYY')
									break
								case 'month':
									timeUse = moment(now).format('MM/YYYY')
									break
								case 'year':
									timeUse = moment(now).format('YYYY')
									break
								default:
									break
							}
							if (!result.length) {
								day['xAxisData'] = timeUse
								result.push(day)
							} else {
								let resultItem = result.find((item) => item['xAxisData'] === timeUse)
								if (resultItem === undefined) {
									day['xAxisData'] = timeUse
									result.push(day)
								} else {
									resultItem.count += day.count
									if (yAxisSelected) {
										resultItem.total += day.total
										resultItem.average += day.average
									}
									if (distinctMappedSplitAxisRecord) {
										distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
											resultItem[index + '_total'] += day[index + '_total']
											resultItem[index + '_average'] += day[index + '_average']
											resultItem[index + '_count'] += day[index + '_count']
										})
									}
								}
							}
						})
						return result
					}
				} else {
					dayDataSet.forEach((item) => {
						dayDataSetRet.push({
							xAxisData: item,
							count: 0,
							total: 0,
							average: 0,
							total2: 0,
							average2: 0,
							total3: 0,
							average3: 0,
						})
					})
					if (dayDataSetRet.length === 0) return dataSet
					dataSet.forEach((item) => {
						var xVal = item['xAxisData']
						xVal = new Date(xVal)
						var momentObj = moment(xVal)
						xVal = momentObj.format('MM/DD/YYYY')
						let dayItem = dayDataSetRet.filter((item) => item['xAxisData'] === xVal)[0]
						if (dayItem != undefined) {
							dayItem.count += item.count
							if (yAxisSelected) {
								dayItem.total += item.total
								dayItem.average += item.average
							}
							if (yAxis2Selected) {
								dayItem.total2 += item.total2
								dayItem.average2 += item.average2
							}
							if (yAxis3Selected) {
								dayItem.total3 += item.total3
								dayItem.average3 += item.average3
							}
						}
					})
					if (dateGroupSelected !== 'day') {
						let result = []
						dayDataSetRet.forEach((day, index) => {
							let now = new Date(day['xAxisData'])
							let timeUse
							switch (dateGroupSelected) {
								case 'week':
									let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
									let lastSunday = new Date(today.setDate(today.getDate() - today.getDay()))
									timeUse = moment(lastSunday).format('MM/DD/YYYY')
									break
								case 'month':
									timeUse = moment(now).format('MM/YYYY')
									break
								case 'year':
									timeUse = moment(now).format('YYYY')
									break
								default:
									break
							}
							if (!result.length) {
								day['xAxisData'] = timeUse
								result.push(day)
							} else {
								let resultItem = result.find((item) => item['xAxisData'] === timeUse)
								if (resultItem === undefined) {
									day['xAxisData'] = timeUse
									result.push(day)
								} else {
									resultItem.count += day.count
									if (yAxisSelected) {
										resultItem.total += day.total
										resultItem.average += day.average
									}
									if (yAxis2Selected) {
										resultItem.total2 += day.total2
										resultItem.average2 += day.average2
									}
									if (yAxis3Selected) {
										resultItem.total3 += day.total3
										resultItem.average3 += day.average3
									}
								}
							}
						})
						return result
					}
				}

				return dayDataSetRet
			default:
				break
		}
		return dataSet
	}, [dataSet, dateGroupSelected])

	function getCurrencyFormat(colName, value) {
		if (columns?.length === 0) return value

		let colDef = columns?.find((col) => col['title'] === colName)
		if (colDef?.format?.style === 'currency') {
			return currencyFormatter(colDef?.format).format(value)
		}
		if (['INTEGER', 'FLOAT'].includes(colDef?.type)) {
			return value
		}
		return value
	}
	function getToolTipFormatter(params) {
		var res = params[0].name
		for (var i = 0, l = params.length; i < l; i++) {
			res +=
				'<br/>' +
				params[i].marker +
				'  ' +
				params[i].seriesName +
				'  ' +
				'<span style="float: right; margin-left: 20px"><b>' +
				getCurrencyFormat(params[i].seriesName, params[i].value) +
				'</b></span>'
		}

		return res
	}

	function getLabelFormatter(params, showName, showValue, showPercent, totalNum) {
		let res = ''
		if (showName) {
			res += params.name
		}

		if (showValue) {
			if (showName) {
				res += '\n'
			}
			if (totalNum > 0) {
				res += getCurrencyFormat(params.seriesName, params.value) + '/' + totalNum
			} else {
				res += getCurrencyFormat(params.seriesName, params.value)
			}
		}

		if (showPercent) {
			res += ' (' + params.percent + '%)'
		}
		return res
	}

	const handleChartDS = (series, xAxis) => {
		let header = []
		let xAxisSelected = ''
		if (chartSetting.xAxis && chartSetting.xAxis.length > 0) {
			header.push(chartSetting.xAxis[0].title)
		}
		if (chartSetting.yAxis && chartSetting.yAxis.length > 0) {
			if (chartSetting.yAxis.length > 0) {
				header.push(chartSetting.yAxis[0].title)
			}
			if (chartSetting.yAxis.length > 1) {
				header.push(chartSetting.yAxis[1].title)
			}
			if (chartSetting.yAxis.length > 2) {
				header.push(chartSetting.yAxis[2].title)
			}
		} else {
			header.push('Count')
		}
		let ds = []
		ds.push(header)

		if (series && series.length > 0) {
			let item = series[0]
			const hasYAxis2 = series.length > 1 ? true : false
			const hasYAxis3 = series.length > 2 ? true : false
			item.data.forEach((itemData, index) => {
				let rowData = []
				if (xAxis == undefined) {
					rowData.push(itemData.name)
					rowData.push(itemData.value)
					if (hasYAxis2) {
						rowData.push(series[1].data[index].value)
					}
					if (hasYAxis3) {
						rowData.push(series[2].data[index].value)
					}
				} else {
					rowData.push(xAxis.data[index])
					rowData.push(itemData)
					if (hasYAxis2) {
						rowData.push(series[1].data[index])
					}
					if (hasYAxis3) {
						rowData.push(series[2].data[index])
					}
				}
				ds.push(rowData)
			})
		}

		setChartDS(ds)
	}

	const displayLineChart = useMemo(() => {
		if (drawChartSelected !== 'lineChart') return
		if (!dataSet) return
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		let mappedSplitAxisRecord = map(records, splitAxisSelected)
		let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
		let colors = colorScheme()
		let series = []
		let xAxis = {
			type: 'category',
			data: null,
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let lineSeries

		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}
		let showLabel = false
		if (formater != '' && methodSelected !== 'count') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			lineSeries = {
				name: chartName,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}

			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		if (
			(yAxisSelected || methodSelected === 'count') &&
			splitIntoMultipleSeries &&
			distinctMappedSplitAxisRecord
		) {
			distinctMappedSplitAxisRecord.map((item, index) => {
				lineSeries = {
					name: item,
					data: [],
					type: 'line',
					smooth: false,
					label: {
						show: showLabel,
						position: 'top',
						//formatter: formater,
						formatter(params) {
							return getLabelFormatter(params, showName, showValue)
						},
						color: '#000',
					},
					itemStyle: {
						normal: {
							color: colors[index % colors.length],
						},
					},
				}
				if (xAxis.data == null) {
					addXAxis = true
					xAxis.data = []
				}
				chartDataSet.map((itemData) => {
					if (addXAxis) {
						xAxis.data.push(itemData['xAxisData'])
					}
					lineSeries.data.push(itemData[index + '_' + colValName])
				})
				series.push(lineSeries)
				addXAxis = false
			})
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			lineSeries = {
				name: chartName2,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName2])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
					? chartColor[1]
					: chartColor[2]
			lineSeries = {
				name: chartName3,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName3])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		handleChartDS(series, xAxis)

		let options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
				formatter(params) {
					return getToolTipFormatter(params)
				},
			},
			legend: legend,
			// dataZoom: [
			// 	{
			// 		type: 'inside',
			// 	},
			// 	{
			// 		type: 'slider',
			// 		xAxisIndex: 0,
			// 		startValue: xAxis.data[0],
			// 		minSpan: 5,
			// 		bottom: 50,
			// 	},
			// ],
		}

		if (showResizeSlider) {
			options.dataZoom = [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			]
		}

		const handleEchartClick = (params) => {
			onHandleFilters(params, xAxisSelected, dateGroupSelected)
		}

		const onEvents = {
			click: handleEchartClick,
		}
		return (
			<Resizable
				defaultSize={{ height: 400 }}
				minHeight={300}
				maxHeight={'100vh'}
				handleStyles={{ bottom: { cursor: 'ns-resize', borderBottom: '1px solid #ddd' } }}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				onResizeStart={handleResizeMapStart}
				onResize={handleResizingMap}
			>
				<ReactECharts
					option={options}
					notMerge={true}
					style={{ height: '100%' }}
					onEvents={onEvents}
				/>
			</Resizable>
		)
	}, [
		gridRows,
		dataSet,
		dateGroupSelected,
		drawChartSelected,
		methodSelected,
		showName,
		showValue,
		splitAxisSelected,
		splitIntoMultipleSeries,
		titleOfchart,
		xAxisSelected,
		xAxisSelectedTitle,
		yAxis2Selected,
		yAxis2SelectedTitle,
		yAxis3Selected,
		yAxis3SelectedTitle,
		yAxisSelected,
		yAxisSelectedTitle,
	])

	const displayBarChart = useMemo(() => {
		if (drawChartSelected !== 'barChart') return
		if (!dataSet) return

		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		let mappedSplitAxisRecord = map(records, splitAxisSelected)
		let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
		let colors = colorScheme()
		let series = []
		let xAxis = {
			type: 'category',
			data: null,
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let barSeries

		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}
		let showLabel = false
		if (formater != '' && methodSelected !== 'count') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			barSeries = {
				name: chartName,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					// position: 'top',
					position: 'insideTop',
					// formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}
			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName])
			})
			series.push(barSeries)
			addXAxis = false
		}

		if (
			(yAxisSelected || methodSelected === 'count') &&
			splitIntoMultipleSeries &&
			distinctMappedSplitAxisRecord
		) {
			distinctMappedSplitAxisRecord.map((item, index) => {
				barSeries = {
					name: item,
					data: [],
					type: 'bar',
					label: {
						show: showLabel,
						//position: 'top',////
						position: 'insideTop',
						//formatter: formater,
						formatter(params) {
							return getLabelFormatter(params, showName, showValue)
						},
						color: '#000',
					},
					itemStyle: {
						normal: {
							color: colors[index % colors.length],
						},
					},
				}
				if (stackBarSeries) {
					barSeries.stack = 'stack'
				}
				if (xAxis.data == null) {
					addXAxis = true
					xAxis.data = []
				}
				chartDataSet.map((itemData) => {
					if (addXAxis) {
						xAxis.data.push(itemData['xAxisData'])
					}
					barSeries.data.push(itemData[index + '_' + colValName])
				})
				series.push(barSeries)
				addXAxis = false
			})
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			barSeries = {
				name: chartName2,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					// position: 'top',
					position: 'insideTop',
					// formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName2])
			})
			series.push(barSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
					? chartColor[1]
					: chartColor[2]
			barSeries = {
				name: chartName3,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					//position: 'top',/////
					position: 'insideTop',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName3])
			})
			series.push(barSeries)
			addXAxis = false
		}

		handleChartDS(series, xAxis)

		let options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
				formatter(params) {
					return getToolTipFormatter(params)
				},
			},
			legend: legend,
			// dataZoom: [
			// 	{
			// 		type: 'inside',
			// 	},
			// 	{
			// 		type: 'slider',
			// 		xAxisIndex: 0,
			// 		startValue: xAxis.data[0],
			// 		minSpan: 5,
			// 		bottom: 50,
			// 	},
			// ],
		}

		if (showResizeSlider) {
			options.dataZoom = [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			]
		}

		const handleEchartClick = (params) => {
			onHandleFilters(params, xAxisSelected, dateGroupSelected)
		}

		const onEvents = {
			click: handleEchartClick,
		}
		return (
			<Resizable
				defaultSize={{ height: 400 }}
				minHeight={300}
				maxHeight={'100vh'}
				handleStyles={{ bottom: { cursor: 'ns-resize', borderBottom: '1px solid #ddd' } }}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				onResizeStart={handleResizeMapStart}
				onResize={handleResizingMap}
			>
				<ReactECharts
					option={options}
					notMerge={true}
					style={{ height: '100%' }}
					onEvents={onEvents}
				/>
			</Resizable>
		)
	}, [
		gridRows,
		dataSet,
		dateGroupSelected,
		drawChartSelected,
		methodSelected,
		showName,
		showValue,
		splitAxisSelected,
		splitIntoMultipleSeries,
		stackBarSeries,
		titleOfchart,
		xAxisSelected,
		xAxisSelectedTitle,
		yAxis2Selected,
		yAxis2SelectedTitle,
		yAxis3Selected,
		yAxis3SelectedTitle,
		yAxisSelected,
		yAxisSelectedTitle,
	])

	const displayPieChart = useMemo(() => {
		if (drawChartSelected !== 'pieChart') return
		if (methodSelected === 'count') return
		if (!dataSet) return
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		let series = []
		let xAxis = {
			type: 'category',
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let pieSeries

		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (formater != '') {
				formater += '\n'
			}
			formater += '{c}'
		}
		if (showPercent) {
			formater += ' ({d}%)'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}
		if (yAxisSelected) {
			pieSeries = {
				name: chartName,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue, showPercent)
					},
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected) {
			pieSeries = {
				name: chartName2,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue, showPercent)
					},
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName2],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		if (yAxis3Selected && yAxisSelected !== yAxis3Selected && yAxis2Selected !== yAxis3Selected) {
			pieSeries = {
				name: chartName3,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue, showPercent)
					},
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName3],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		let media = [
			{
				option: {
					series: [],
				},
			},
		]

		let chartCount = series.length
		switch (chartCount) {
			case 1:
				media[0].option.series.push({ center: ['50%', '50%'] })
				break
			case 2:
				media[0].option.series.push({ center: ['33.33%', '50%'] })
				media[0].option.series.push({ center: ['66.66%', '50%'] })
				break
			case 3:
				media[0].option.series.push({ center: ['25%', '50%'] })
				media[0].option.series.push({ center: ['50%', '50%'] })
				media[0].option.series.push({ center: ['75%', '50%'] })
				break
		}

		handleChartDS(series, undefined)

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 24, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series.map((item) => {
				item.radius = 110
				return item
			}),
			tooltip: {
				trigger: 'axis',
			},
			legend: legend,
			media: media,
		}

		const handleEchartClick = (params) => {
			onHandleFilters(params, xAxisSelected, dateGroupSelected)
		}

		const onEvents = {
			click: handleEchartClick,
		}
		return (
			<Resizable
				defaultSize={{ height: 400 }}
				minHeight={300}
				maxHeight={'100vh'}
				handleStyles={{ bottom: { cursor: 'ns-resize', borderBottom: '1px solid #ddd' } }}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				onResizeStart={handleResizeMapStart}
				onResize={handleResizingMap}
			>
				<ReactECharts
					option={options}
					notMerge={true}
					style={{ height: '100%' }}
					onEvents={onEvents}
				/>
			</Resizable>
		)
	}, [
		gridRows,
		dateGroupSelected,
		drawChartSelected,
		methodSelected,
		showName,
		showPercent,
		showValue,
		titleOfchart,
		xAxisSelected,
		xAxisSelectedTitle,
		yAxis2Selected,
		yAxis2SelectedTitle,
		yAxis3Selected,
		yAxis3SelectedTitle,
		yAxisSelected,
		yAxisSelectedTitle,
	])

	const onChartLegendselectchanged = (params) => {
		if (!params.selected) return
		let selected = params.selected
		let omittedResult = []
		for (const property in selected) {
			if (selected[property] == false) {
				omittedResult.push(property)
			}
		}
		setSeriesOmit(omittedResult)
	}

	const displayCountPieChart = useMemo(() => {
		if (drawChartSelected !== 'pieChart') return
		if (methodSelected !== 'count') return
		if (!dataSet) return
		let colValName = 'count'
		let chartName = xAxisSelectedTitle

		let series = []
		let xAxis = {
			type: 'category',
		}
		let datas = []
		var totalNum = 0
		var selected = {}
		chartDataSet.map((item) => {
			let dataItem = {
				name: item['xAxisData'],
				value: item[colValName],
			}
			if (seriesOmit == null || seriesOmit.length == 0) {
				totalNum += valueOf(item[colValName])
				selected[item['xAxisData']] = true
			} else {
				if (seriesOmit.indexOf(item['xAxisData']) < 0) {
					totalNum += valueOf(item[colValName])
					selected[item['xAxisData']] = true
				} else {
					selected[item['xAxisData']] = false
				}
			}
			datas.push(dataItem)
		})
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
			selected: selected,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let pieSeries

		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (formater != '') {
				formater += '\n'
			}
			formater += '{c} / ' + totalNum
		}
		if (showPercent) {
			formater += ' ({d}%)'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}

		pieSeries = {
			name: chartName,
			data: datas,
			type: 'pie',
			label: {
				show: showLabel,
				//formatter: formater,
				formatter(params) {
					return getLabelFormatter(params, showName, showValue, showPercent, totalNum)
				},
				color: '#000',
			},
			emphasis: {
				focus: 'self',
			},
		}

		series.push(pieSeries)

		let media = [
			{
				option: {
					series: [{ center: ['50%', '50%'] }],
				},
			},
		]

		handleChartDS(series, undefined)

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 24, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series.map((item) => {
				item.radius = 110
				return item
			}),
			/*tooltip: {
			  trigger: 'axis',
			},*/
			legend: legend,
			media: media,
		}

		const handleEchartClick = (params) => {
			onHandleFilters(params, xAxisSelected, dateGroupSelected)
		}

		const onEvents = {
			click: handleEchartClick,
			legendselectchanged: onChartLegendselectchanged,
		}
		return (
			<Resizable
				defaultSize={{ height: 400 }}
				minHeight={300}
				maxHeight={'100vh'}
				handleStyles={{ bottom: { cursor: 'ns-resize', borderBottom: '1px solid #ddd' } }}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				onResizeStart={handleResizeMapStart}
				onResize={handleResizingMap}
			>
				<ReactECharts
					option={options}
					notMerge={true}
					style={{ height: '100%' }}
					onEvents={onEvents}
				/>
			</Resizable>
		)
	}, [
		gridRows,
		dateGroupSelected,
		drawChartSelected,
		methodSelected,
		seriesOmit,
		showName,
		showPercent,
		showValue,
		titleOfchart,
		xAxisSelected,
		xAxisSelectedTitle,
	])

	const displayScatterPlot = useMemo(() => {
		if (drawChartSelected !== 'scatterPlot') return
		if (!dataSet) return

		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle

		let series = []
		let xAxis = {
			type: 'category',
			data: null,
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let scatterSeries
		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}
		let showLabel = false
		if (formater != '' && methodSelected !== 'count') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			scatterSeries = {
				name: chartName,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			scatterSeries = {
				name: chartName2,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName2])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
					? chartColor[1]
					: chartColor[2]
			scatterSeries = {
				name: chartName3,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					//formatter: formater,
					formatter(params) {
						return getLabelFormatter(params, showName, showValue)
					},
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (xAxis.data == null) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName3])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		handleChartDS(series, xAxis)

		let options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
				formatter(params) {
					return getToolTipFormatter(params)
				},
			},
			legend: legend,
			// dataZoom: [
			// 	{
			// 		type: 'inside',
			// 	},
			// 	{
			// 		type: 'slider',
			// 		xAxisIndex: 0,
			// 		startValue: xAxis.data[0],
			// 		minSpan: 5,
			// 		bottom: 50,
			// 	},
			// ],
		}

		if (showResizeSlider) {
			options.dataZoom = [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			]
		}

		const handleEchartClick = (params) => {
			onHandleFilters(params, xAxisSelected, dateGroupSelected)
		}

		const onEvents = {
			click: handleEchartClick,
		}
		return (
			<Resizable
				defaultSize={{ height: 400 }}
				minHeight={300}
				maxHeight={'100vh'}
				handleStyles={{ bottom: { cursor: 'ns-resize', borderBottom: '1px solid #ddd' } }}
				enable={{
					top: false,
					right: false,
					bottom: true,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				}}
				onResizeStart={handleResizeMapStart}
				onResize={handleResizingMap}
			>
				<ReactECharts
					option={options}
					notMerge={true}
					style={{ height: '100%' }}
					onEvents={onEvents}
				/>
			</Resizable>
		)
	}, [
		gridRows,
		dataSet,
		dateGroupSelected,
		drawChartSelected,
		methodSelected,
		showName,
		showValue,
		splitIntoMultipleSeries,
		titleOfchart,
		xAxisSelected,
		xAxisSelectedTitle,
		yAxis2Selected,
		yAxis2SelectedTitle,
		yAxis3Selected,
		yAxis3SelectedTitle,
		yAxisSelected,
		yAxisSelectedTitle,
	])

	const handleCharting = (e) => {
		setLoading(true)
		setDrawChartSelected(chartSelected)
		let mappedAxisRecord = map(records, xAxisSelected)
		let distinct = uniq(mappedAxisRecord)
		let result = null
		if (!distinct.length) {
			setLoading(false)
			return []
		}

		if (splitIntoMultipleSeries) {
			let mappedSplitAxisRecord = map(records, splitAxisSelected)
			let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
			result = distinct.map((item) => {
				let itemName = item === null || item === '' ? '*Empty*' : item
				let filteredList = records.filter((rec) => rec[xAxisSelected] === item)
				if (!filteredList.length) return []

				let retItem = { xAxisData: itemName, count: filteredList.length, splitData: {} }
				if (yAxisSelected && yAxisSelected !== '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total = sum
					retItem.average = average

					if (distinctMappedSplitAxisRecord) {
						distinctMappedSplitAxisRecord.forEach((splitItem) => {
							let filteredSplitList = filteredList.filter(
								(splitRec) => splitRec[splitAxisSelected] === splitItem
							)
							let initialValue = 0
							let sum = filteredSplitList.reduce(
								(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
								initialValue
							)
							sum = Math.round(sum * 100) / 100
							let average = sum / filteredSplitList.length
							average = Math.round(average * 100) / 100
							if (retItem.splitData === null) {
								retItem.splitData = {}
							}

							retItem.splitData[splitItem] = {
								total: sum,
								average: average,
								count: filteredSplitList.length,
							}
						})
					}
				}

				return retItem
			})
		} else {
			result = distinct.map((item) => {
				let itemName = item === null || item === '' ? '*Empty*' : item
				let filteredList = records.filter((rec) => rec[xAxisSelected] === item)
				if (!filteredList.length) return []

				let retItem = { xAxisData: itemName, count: filteredList.length }
				if (yAxisSelected && yAxisSelected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total = sum
					retItem.average = average
				}
				if (yAxis2Selected && yAxis2Selected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxis2Selected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total2 = sum
					retItem.average2 = average
				}
				if (yAxis3Selected && yAxis3Selected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxis3Selected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total3 = sum
					retItem.average3 = average
				}
				return retItem
			})
		}

		setLoading(false)
		setSeriesOmit([])
		setDataSet([...result])
		setViewChartData()
	}

	const setViewChartData = () => {
		const clonedChart = cloneDeep(chartSetting)
		if (clonedChart) {
			clonedChart.display = chartDisplayed
			clonedChart.type = chartSelected
			clonedChart.method = methodSelected
			clonedChart.step = dateGroupSelected
			clonedChart.xAxis = []
			let colSelected = columns.filter((rec) => rec['name'] === xAxisSelected)
			if (colSelected && colSelected.length > 0) {
				clonedChart.xAxis.push(colSelected[0])
			}
			clonedChart.yAxis = []
			if (yAxisSelected) {
				colSelected = columns.filter((rec) => rec['name'] === yAxisSelected)
				if (colSelected && colSelected.length > 0) {
					clonedChart.yAxis.push(colSelected[0])
				}
			}
			if (yAxis2Selected) {
				colSelected = columns.filter((rec) => rec['name'] === yAxis2Selected)
				if (colSelected && colSelected.length > 0) {
					clonedChart.yAxis.push(colSelected[0])
				}
			}
			if (yAxis3Selected) {
				colSelected = columns.filter((rec) => rec['name'] === yAxis3Selected)
				if (colSelected && colSelected.length > 0) {
					clonedChart.yAxis.push(colSelected[0])
				}
			}
			clonedChart.labels = []
			if (showName) {
				clonedChart.labels.push('showName')
			}
			if (showValue) {
				clonedChart.labels.push('showValue')
			}
			if (showPercent) {
				clonedChart.labels.push('showPercent')
			}
			clonedChart.splitAxis = []
			if (splitIntoMultipleSeries && splitAxisSelected) {
				colSelected = columns.filter((rec) => rec['name'] === splitAxisSelected)
				if (colSelected && colSelected.length > 0) {
					clonedChart.splitAxis.push(colSelected[0])
				}
			}
		}
	}

	const displayChart = () => {
		switch (drawChartSelected) {
			case 'lineChart':
				return displayLineChart
			case 'barChart':
				return displayBarChart
			case 'pieChart':
				if (methodSelected === 'count') {
					return displayCountPieChart
				} else {
					return displayPieChart
				}
			case 'scatterPlot':
				return displayScatterPlot
			default:
				return null
		}
	}

	useEffect(() => {
		if (!chartDS) return
		if (!environment.chartDS) {
			dispatch({
				type: ENV_ACTIONS.CHART_DS,
				payload: chartDS,
			})
		}
	}, [chartDS])

	useEffect(() => {
		if (!renderChart) return
		setRenderChart(false)
		handleCharting()
	}, [renderChart])

	useEffect(() => {
		loadSavedChart()
	}, [])

	if (dataSet.length === 0) return null

	return loading ? (
		<>
			<SkeletonLoaderGridChart />
			<LoadingSpinner />
		</>
	) : (
		<div id={id} className={classes.chart}>
			<div className={classes.editButton}>
				<DoformsDataChart
					isEditChartUI
					hiddenDisplayChartBtn={hiddenDisplayChartBtn}
					hiddenDisplayEditChartBtn={hiddenDisplayEditChartBtn}
					showOnlyChart={false}
					columns={columns}
					gridRows={gridRows}
					chartSetting={chartSetting}
					setChartSetting={setChartSetting}
					environment={environment}
					onForceUpdate={setForceRerender}
				/>
			</div>
			{displayChart()}
		</div>
	)
}

export default DoformsGridChart
