import moment from 'moment'

import { MAP_GPS_SOURCE_TYPE } from './constants'

import { DRIVER_MAP_LOGGED_IN_INFO } from '../../../../../../constants'
import { getMapLoggedInInfoFromStorage } from 'utils/functions/helpers'

export function createDriverLoginLSKey(email) {
	return `${DRIVER_MAP_LOGGED_IN_INFO}-${email}`
}

export function getMapLoggedInInfoFromStorageByGPSSource(gpsSource, email) {
	switch (gpsSource) {
		case MAP_GPS_SOURCE_TYPE.DRIVER_TECHNOLOGIES:
			try {
				const key = createDriverLoginLSKey(email)
				if (localStorage.getItem(key)) {
					return localStorage.getItem(key)
				}
				return null
			} catch {
				return null
			}

		default:
			return getMapLoggedInInfoFromStorage()
	}
}

export function rotateImage(inputImage, rotationDegree) {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	// Determine the size of the canvas to fit the image after rotation
	const width = inputImage.width
	const height = inputImage.height

	canvas.width = width
	canvas.height = height

	// Rotate the image and draw it on the canvas
	ctx.translate(width / 2, height / 2) // Move the focus to the center of the image
	ctx.rotate((rotationDegree * Math.PI) / 180) // Rotate by rotation angle (convert to radians)
	ctx.drawImage(inputImage, -width / 2, -height / 2, width, height) // Draw pictures

	// Convert canvas to rotated image
	const rotatedImage = new Image()
	rotatedImage.src = canvas.toDataURL('image/png')

	return rotatedImage
}

export function formatDuration(stopDuration) {
	// Parse the time string into a Moment object
	const duration = moment.duration(stopDuration)

	// Format the time as "X days Y hours Z minutes"
	const formattedDuration = duration.humanize()

	return formattedDuration
}

export const isTimestampInRange = (timestamp, startDate, endDate) => {
	const ts = moment(timestamp)
	const start = moment.unix(startDate).startOf('day')
	const end = moment.unix(endDate).endOf('day')

	return ts.isBetween(start, end, null, '[]')
}

export const convertMPerSecondToMPH = (speedMps) => {
	if (typeof speedMps !== 'number' || isNaN(speedMps)) return 0
	return speedMps * 2.23694 // Conversion factor from m/s to mph
}

export function convertArrayToObject(arr) {
	const output = {}
	for (let item of arr) {
		const { field, value } = item
		output[field] = value
	}
	return output
}

export function getDriverMapFieldList(t) {
	return [
		{
			name: 'userEmail',
			label: 'User email',
		},
		{
			name: 'fromDate',
			label: t('common:input.fromDate'),
			type: 'DATE',
		},
		{
			name: 'toDate',
			label: t('common:input.toDate'),
			type: 'DATE',
		},
	]
}
