import axios from 'axios'
import { apiUrl, driverMapUrl } from '../apiUrl'
import { isEmpty } from 'lodash'
import moment from 'moment'

import { parseAs } from 'utils/functions/doformsDateUtil'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const driverMapApi = {
	getUserByEmail(userEmail, token) {
		return axios.get(`${url}/integrations/110/users?email=${userEmail}`, {
			headers: apiHeader(token),
		})
	},
	getDrivesByUserId(props) {
		const { showHistory, token, userOffset, ...params } = props
		let queryParams = ''
		if (!showHistory) {
			delete params['end_after']
			const currentMoment = moment().endOf('day')
			const userMoment = parseAs(currentMoment, userOffset)
			params['start_before'] = userMoment.unix()
			params['limit'] = 1
		}

		// add 1s to get next day
		params['start_before'] = params['start_before'] + 1
		Object.keys(params).forEach((key, index) => {
			const value = params[key]
			if (!value) return
			queryParams = queryParams + (index === 0 ? `?${key}=${value}` : `&${key}=${value}`)
		})
		return axios.get(`${url}/integrations/110/drives${queryParams}`, {
			headers: apiHeader(token),
		})
	},
	getDriveLocations(props) {
		const { user_id, drive_id, token, ...params } = props
		let queryParams = ''
		Object.keys(params).forEach((key, index) => {
			const value = props[key]
			if (isEmpty(value)) return
			queryParams = queryParams + (index === 0 ? `?${key}=${value}` : `&${key}=${value}`)
		})
		return axios.get(
			`${url}/integrations/110/users/${user_id}/drives/${drive_id}/locations${queryParams}`,
			{
				headers: apiHeader(token),
			}
		)
	},
	getDriveEvents(props) {
		const { user_id, drive_id, token } = props

		return axios.get(`${url}/integrations/110/users/${user_id}/drives/${drive_id}/events`, {
			headers: apiHeader(token),
		})
	},
}

export default driverMapApi
