import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
    FEED_VIEW_TYPE,
    MOBILE_UNIT_VIEW_TYPE,
} from 'components/data/datagrid/CreatViewComponents/ViewDialogUtils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default function DashboardList({
    title,
    list,
    fields,
    linkedFields,
    onFieldsChange,
    getLabelOption,
    viewType,
}) {
    const { t } = useTranslation('common')
    const sortedList = useMemo(() => {
        if (isEmpty(list)) return []
        return list.sort((a, b) => {
            if (a.key === 'Master_DateTime_Start' && b.key === 'Master_DateTime_End') {
                return -1 // Ensure "Start" comes first
            }
            if (a.key === 'Master_DateTime_End' && a.key === 'Master_DateTime_Start') {
                return 1 // Ensure "End" comes after
            }
            return a.key.localeCompare(b.key) // Default alphabetical sorting
        })
    }, [list])

    return (
        <>
            <Typography variant="subtitle2" sx={{ pl: 1 }}>
                {title}
            </Typography>
            <List
                sx={{
                    width: '100%',
                    minHeight: 180,
                    maxHeight: 180,
                    overflowY: 'scroll',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    mb: 2,
                }}
                dense
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        px: 2,
                    }}
                >
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            {t('common:dis.keyName')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            {t('common:dis.tileName')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2"> {t('common:dis.fields')}</Typography>
                    </Grid>
                </Grid>
                {sortedList.map((item, index) => {
                    let filteredFields = isEmpty(item.selectableTypes)
                        ? fields
                        : fields.filter((field) =>
                              item.selectableTypes?.includes(field.type?.toUpperCase())
                          )
                    if (viewType === FEED_VIEW_TYPE) {
                        if (item.key === 'Master_DateTime') {
                            filteredFields = [
                                {
                                    name: '@FeedReceiveTime',
                                    title: 'Date',
                                    type: 'DATETIME',
                                    format: {
                                        date: 'MM/DD/YYYY h:mm:ss A',
                                    },
                                },
                            ]
                        }
                        if (item.key === 'Master_Text') {
                            filteredFields = [
                                {
                                    name: '@FeedDeviceId',
                                    title: 'Mobile number',
                                    type: 'STRING',
                                    format: {},
                                },
                            ]
                        }
                    } else if (viewType === MOBILE_UNIT_VIEW_TYPE) {
                        filteredFields = []
                    }
                    return (
                        <DashboardItem
                            data={item}
                            key={item?.id || index}
                            fields={filteredFields}
                            onFieldsChange={onFieldsChange}
                            activeField={linkedFields[item.id]}
                            getLabelOption={getLabelOption}
                        />
                    )
                })}
            </List>
        </>
    )
}

const DashboardItem = ({ data, fields, activeField, onFieldsChange, getLabelOption }) => {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                px: 2,
            }}
        >
            <Grid item xs={4}>
                <Tooltip placement="left" title={data?.key || ''}>
                    <Typography
                        sx={{
                            mb: 2,
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {data.key}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={4}>
                <Tooltip placement="left" title={data?.tileName || ''}>
                    <Typography
                        sx={{
                            mb: 2,
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {data.tileName}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={4}>
                {fields?.length === 0 && <Chip label="Empty field" />}
                {fields?.length > 0 && (
                    <FormControl
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{
                            '& .MuiInputLabel-root': {
                                transform: 'translate(0, 3px) scale(1) !important',
                            },
                            '& .MuiInput-root': {
                                mt: '0 !important',
                            },
                        }}
                    >
                        <Select
                            labelId="field-select-small-label"
                            id="field-select-small"
                            value={activeField || ''}
                            onChange={(e) => {
                                onFieldsChange?.(data?.id, e.target.value)
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {fields?.length > 0 &&
                                fields.map((field, index) => (
                                    <MenuItem value={field.name} key={field?.name || index}>
                                        {getLabelOption(field)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
        </Grid>
    )
}
