import { useState, useRef, useMemo, useReducer, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cloneDeep, isEmpty, uniqBy, orderBy } from 'lodash'
import moment from 'moment'

import { Box } from '@mui/material'

import tileApi from 'apis/disApi/tileApi'
import { tileKeys } from '../../hooks/useTileQuery'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { generateRandomID } from '../../helpers'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import TileWrapper from '../../components/TileWrapper'
import MasterDateTimeSelectComponent from './MasterDateTimeSelectComponent'
import MasterTextSelectComponent from './MasterTextSelectComponent'
import MasterDateTimeSettingsDialog from './MasterDateTimeSettingDialog'
import { DEFAULT_CHECKED_PRIMARY_KEYS } from './MasterDateTimeForm'

export const DATE_CONDITION_FILTER_TYPE = {
    OPERATOR: 'operator',
    DATE_COMPONENT: 'date',
}

export const TEXT_CONDITION_FILTER_TYPE = {
    OPERATOR: 'operator',
    TEXT_COMPONENT: 'text',
}

function buildSeclectedMasterDateTime(datetimeCondition) {
    if (isEmpty(datetimeCondition?.values))
        return {
            Master_DateTime: [],
        }
}

function dateConditionFilterReducer(state, action) {
    switch (action.type) {
        case DATE_CONDITION_FILTER_TYPE.OPERATOR: {
            return action.value
        }
        case DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT: {
            return {
                ...state,
                values: action.value,
            }
        }
        default:
            return state
    }
}

function textConditionFilterReducer(state, action) {
    switch (action.type) {
        case TEXT_CONDITION_FILTER_TYPE.OPERATOR: {
            return action.value
        }
        case TEXT_CONDITION_FILTER_TYPE.TEXT_COMPONENT: {
            return {
                ...state,
                values: action.value,
            }
        }
        default:
            return state
    }
}

export function MasterDateTimeTile(props) {
    const { tile } = props

    const { environment } = useSelector((state) => state)
    const iconTheme = environment.theme.icons
    const { id: dashboardKey } = useParams()

    const queryClient = useQueryClient()

    const updateTileMutation = useMutation(tileApi.update, {
        onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
    })

    const { updateMutation: updateDashboard, dashboardKeyList } = useDashboardQuery({
        dashboardKey,
    })

    const [settingsOpen, setSettingsOpen] = useState(false)
    const [dateConditionFilter, dateConditionFilterDispatch] = useReducer(
        dateConditionFilterReducer,
        {}
    )
    const [textConditionFilter, textConditionFilterDispatch] = useReducer(
        textConditionFilterReducer,
        {}
    )

    const { onSelectRow } = useTileDashboard()

    const tileRef = useRef(null)
    const settings = useMemo(() => {
        if (tile?.settings && isJson(tile?.settings)) {
            return JSON.parse(tile?.settings ?? '{}')
        }

        return {}
    }, [tile?.settings])

    const { tileWidth, otherOptionsChecked, checkedPrimaryKeys, dateTimeOperators, textOperators } =
        useMemo(() => settings, [settings])

    const disablePicker = useMemo(
        () => !otherOptionsChecked?.includes('enableEdit'),
        [otherOptionsChecked]
    )

    const handleOpenDialog = () => {
        setSettingsOpen(true)
    }

    const handleCloseDialog = () => {
        setSettingsOpen(false)
    }

    const hasDateTime = useMemo(() => {
        const toCheckedList = checkedPrimaryKeys || DEFAULT_CHECKED_PRIMARY_KEYS
        const datetimeTypes = ['Master_DateTime', 'Master_DateTime_Start', 'Master_DateTime_End']
        for (const datetimeType of datetimeTypes) {
            if (toCheckedList?.includes(datetimeType)) return true
        }

        return false
    }, [checkedPrimaryKeys])

    const hasText = useMemo(() => {
        const toCheckedList = checkedPrimaryKeys || DEFAULT_CHECKED_PRIMARY_KEYS
        return toCheckedList?.includes('Master_Text')
    }, [checkedPrimaryKeys])

    const handleResizeTileWidth = async (width) => {
        try {
            const editedSettings = JSON.stringify({
                ...settings,
                tileWidth: width,
            })

            await updateTileMutation.mutateAsync({
                dashboardKey,
                tileKey: tile.key,
                data: { settings: editedSettings },
                token: environment.apiToken,
            })
        } catch (error) {
            logErrorMessage(error)
        }
    }

    const handleSubmitSetting = async (data) => {
        try {
            const {
                primaryKeyList,
                otherOptionsChecked,
                checkedPrimaryKeys,
                dateTimeOperators,
                textOperators,
            } = data
            const currentDashboardKeyList = cloneDeep(dashboardKeyList)
            const tileName = tile.i
            const tileKey = tile.key
            const otherDashboardKeys = currentDashboardKeyList.filter(
                (item) => item.tileKey !== tileKey && item.tileName !== tileName
            )
            const primaryKeysToSave = [...otherDashboardKeys]
            if (!isEmpty(primaryKeyList)) {
                const newPrimaryKeyList = [
                    ...primaryKeyList.map((item) => {
                        const primaryInfo = {
                            id: generateRandomID(),
                            key: item,
                            tileName,
                            tileKey,
                        }
                        if (
                            [
                                'Master_DateTime',
                                'Master_DateTime_Start',
                                'Master_DateTime_End',
                            ].includes(item)
                        ) {
                            primaryInfo['selectableTypes'] = ['DATE', 'DATETIME']
                        }
                        return primaryInfo
                    }),
                ]
                const uniqNewPrimaryKeyList = uniqBy(
                    newPrimaryKeyList,
                    (item) => `${item.key}-${tile.tileName}-${tile.tileKey}`
                )

                // mark delete primary key
                const deletedPrimaryKeyList = currentDashboardKeyList
                    .filter((item) => tileName === item.tileName && tileKey === item.tileKey)
                    .map((oldItem) => {
                        const hasInNewList = uniqNewPrimaryKeyList.find(
                            (newItem) =>
                                oldItem.key === newItem.key &&
                                oldItem.tileName === newItem.tileName &&
                                oldItem.tileKey === newItem.tileKey
                        )

                        if (hasInNewList) return null

                        return {
                            ...oldItem,
                            deleted: true,
                        }
                    })
                    .filter((item) => !!item)

                const allTileKeys = [...uniqNewPrimaryKeyList, ...deletedPrimaryKeyList]

                const keepOldPrimaryKeyList = allTileKeys
                    .map((item) => {
                        const deletedKey = deletedPrimaryKeyList.find(
                            (deletedItem) =>
                                deletedItem.key === item.key &&
                                deletedItem.tileName === item.tileName &&
                                deletedItem.tileKey === item.tileKey
                        )
                        if (Boolean(deletedKey)) {
                            return null
                        }

                        const oldDashboardKeyItem = currentDashboardKeyList.find(
                            (oldItem) =>
                                oldItem.key === item.key &&
                                oldItem.tileName === item.tileName &&
                                oldItem.tileKey === item.tileKey
                        )
                        if (Boolean(oldDashboardKeyItem)) {
                            return { ...oldDashboardKeyItem, old: true }
                        }
                        return item
                    })
                    .filter((item) => !!item)

                primaryKeysToSave.push(...keepOldPrimaryKeyList)
            }

            const orderedSaveList = orderBy(
                primaryKeysToSave,
                (item) => `${item.tileName}-${item.tileKey}-${item.id}`
            )

            await Promise.all([
                updateDashboard.mutateAsync({
                    key: dashboardKey,
                    data: {
                        variables: JSON.stringify(orderedSaveList),
                    },
                    token: environment.apiToken,
                }),
                updateTileMutation.mutateAsync({
                    dashboardKey,
                    tileKey: tile.key,
                    data: {
                        settings: JSON.stringify({
                            ...settings,
                            checkedPrimaryKeys,
                            otherOptionsChecked,
                            dateTimeOperators,
                            textOperators,
                        }),
                    },
                    token: environment.apiToken,
                }),
            ])
        } catch (error) {
            logErrorMessage(error)
        } finally {
            handleCloseDialog()
        }
    }

    function handleApply() {
        if (!hasDateTime && !hasText) return
        const searchValue = {}
        if (hasDateTime) {
            let dateCondition = cloneDeep(dateConditionFilter)
            const actualValues = dateCondition?.values || []
            if (!isEmpty(dateCondition?.preset)) {
                dateCondition.values = []
            }

            searchValue.Master_DateTime = dateCondition
            switch (actualValues.length) {
                case 1:
                    const currentValue = actualValues[0]
                    searchValue.Master_DateTime_Start = currentValue
                    searchValue.Master_DateTime_End = moment
                        .utc(currentValue)
                        .add(23, 'hours')
                        .add(59, 'minutes')
                        .add(59, 'seconds')
                        .toISOString()
                    break

                case 2:
                    searchValue.Master_DateTime_Start = actualValues[0]
                    searchValue.Master_DateTime_End = actualValues[1]
                    break

                default:
                    searchValue.Master_DateTime_Start = ''
                    searchValue.Master_DateTime_End = ''
                    break
            }
        }

        if (hasText) {
            searchValue.Master_Text = textConditionFilter
        }

        onSelectRow(tile.key, searchValue)
    }
    
    useEffect(() => {
        handleApply()
    }, [dateConditionFilter])
    
    return (
        <IconThemeProvider values={iconTheme}>
            <TileWrapper
                title={tile?.i}
                onSettingClick={handleOpenDialog}
                ref={tileRef}
                isExpandDialogBtn
            >
                <MasterDateTimeSettingsDialog
                    tileElementWidth={tileRef?.current?.clientWidth}
                    defaultTileWidth={tileWidth}
                    tile={tile}
                    settings={settings}
                    isSubmitting={updateTileMutation.isLoading}
                    open={settingsOpen}
                    onClose={handleCloseDialog}
                    onSubmit={handleSubmitSetting}
                    onResizeTileWidth={handleResizeTileWidth}
                    dashboardKeyList={dashboardKeyList}
                />

                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0,
                        background: '#fff',
                        alignItems: 'center',
                        display: 'flex',
                        overflowY: `${hasDateTime && hasText ? 'auto' : 'hidden'}`,
                        overflowX: 'hidden',

                        '& .MuiDataGrid-selectedRowCount': {
                            opacity: '0 !important',
                        },
                    }}
                >
                    <div
                        style={{
                            display: 'block',
                            width: '98%',
                            height: '50%',
                        }}
                    >
                        {hasDateTime && (
                            <MasterDateTimeSelectComponent
                                disablePicker={disablePicker}
                                dateConditionFilterDispatch={dateConditionFilterDispatch}
                                dateTimeOperators={dateTimeOperators}
                            />
                        )}
                        {hasText && (
                            <MasterTextSelectComponent
                                disablePicker={disablePicker}
                                textConditionFilterDispatch={textConditionFilterDispatch}
                                hasDateTime={hasDateTime}
                                handleApply={handleApply}
                                textOperators={textOperators}
                            />
                        )}
                    </div>
                </Box>
            </TileWrapper>
        </IconThemeProvider>
    )
}
