import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css'
import { isEmpty, map, sortBy, uniq } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState, memo, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import ReactECharts from 'echarts-for-react'

import {
	BarChart as BarChartIcon,
	Close,
	ScatterPlot as DotChartIcon,
	InsertChart as InsertChartIcon,
	Timeline as LineChartIcon,
	PieChart as PieChartIcon,
	Settings as SettingsIcon,
	Tune,
} from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { LoadingButton } from '@mui/lab'
import {
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	MenuItem,
	Paper,
	Stack as StackMui,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Modal from '@mui/material/Modal'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'

import colorScheme from 'utils/functions/colorsOptions'
import useCacheState from 'utils/hooks/useCacheState'
import { HELP_LINK_DATA_CHART } from '../../../../../constants'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { DEVICES_ACTIONS } from '../../../../../reducers/devicesReducer'
import { ENV_ACTIONS } from '../../../../../reducers/environmentReducer'
import { FORM_ACTIONS } from '../../../../../reducers/formsReducer'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { SETTING_TYPES, shouldPreventRender } from '../ViewDialogUtils'
import CollapseComponent from './CollapseComponent'

const theme = createTheme({
	typography: {
		h6: {
			fontSize: '0.875rem',
		},
	},
})

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
	axisBox: {
		display: 'inline-grid',
		marginTop: '10px',
		textAlign: 'left',
	},
	splitAxisBox: {
		display: 'inline-grid',
		marginTop: '10px',
		textAlign: 'left',
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},
	buttonGroup: {
		'& > button': {
			display: 'flex',
			alignItems: 'center',
			gap: '10px',
		},
		'& .MuiToggleButtonGroup-grouped': {
			border: 0,
			textTransform: 'none',
			'&.Mui-disabled': {
				border: 0,
			},
		},
	},
	listBody: {
		maxHeight: '15em',
		overflow: 'auto',
	},
	footer: {
		display: 'flex',
		maxHeight: '30px',
		minHeight: '30px',
		alignItems: 'center',
		justifyContent: 'end',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	chart: (props) => ({
		paddingBottom: '20px',
		'& #top-container': {
			alignItems: 'center',
			display: 'block !important',
			justifyContent: 'end',
			'& .MuiTypography-root': {
				marginBottom: '0 !important',
			},
			'& .MuiList-root': {
				flexDirection: 'row-reverse !important',
				flexWrap: 'wrap',
				marginBottom: '20px !important',
			},
			'& .MuiListItem-root': {
				textTransform: 'none !important',
				width: 'auto',
			},
		},
	}),
	deleteIcon: {
		position: 'absolute',
		top: '-5px',
		right: '-10px',
	},
}))

const boxStyle = {
	maxHeight: '100%',
	overflow: 'auto',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: 'background.paper',
	boxShadow: 24,
	borderRadius: '5px',
	padding: '16px 32px',
	textAlign: 'center',
}

function ChartComponent(props) {
	const {
		isAddNew,
		viewData,
		tab,
		gridRows,
		recordsLoading,
		environment,
		hiddenDisplayChartBtn,
		hiddenDisplayEditChartBtn,
		listActiveFieldsOptions,
		onForceUpdate,
		saveSettingDispatch,
		expandedAccordion,
		setExpandedAccordion,
	} = props

	const [t] = useTranslation('common')
	const title = useMemo(() => t('common:misc.addChart'), [t])

	const dispatch = useDispatch()
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [loading, setLoading] = useState(false)

	const [seriesOmit, setSeriesOmit] = useState([])
	const [openChartSection, setOpenChartSection] = useCacheState(false, 'open-chart-section')

	const axesOptions = useMemo(() => {
		let result = sortBy(listActiveFieldsOptions, 'name')
		return [...result]
	}, [listActiveFieldsOptions])

	const records = useMemo(() => {
		return isEmpty(gridRows) ? [] : [...gridRows]
	}, [gridRows])

	const chartOptions = [
		{ name: 'lineChart', title: t('common:chart.lineChart'), icon: LineChartIcon },
		{ name: 'barChart', title: t('common:chart.barChart'), icon: BarChartIcon },
		{ name: 'pieChart', title: t('common:chart.pieChart'), icon: PieChartIcon },
		{ name: 'scatterPlot', title: t('common:chart.scatterPlot'), icon: DotChartIcon },
	]

	const methodOptions = [
		{ name: 'sum', title: t('common:chart.total') },
		{ name: 'average', title: t('common:chart.average') },
		{ name: 'count', title: t('common:chart.count') },
		// {name: "all", title: "All"}
	]

	const dateTimeGroup = [
		{ name: 'day', title: t('common:chart.day') },
		{ name: 'week', title: t('common:chart.week') },
		{ name: 'month', title: t('common:chart.month') },
		{ name: 'year', title: t('common:chart.year') },
	]

	const chartColor = ['red', 'green', 'blue']

	const [chartSelected, setChartSelected] = useState(null)
	const [drawChartSelected, setDrawChartSelected] = useState(null)
	const [methodSelected, setMethodSelected] = useState(null)
	const [dateGroupSelected, setDateGroupSelected] = useState('day')
	const [xAxisSelected, setXAxisSelected] = useState('')
	const [yAxisSelected, setYAxisSelected] = useState('')
	const [yAxis2Selected, setYAxis2Selected] = useState('')
	const [yAxis3Selected, setYAxis3Selected] = useState('')
	const [xAxisSelectedTitle, setXAxisSelectedTitle] = useState('')
	const [yAxisSelectedTitle, setYAxisSelectedTitle] = useState('')
	const [yAxis2SelectedTitle, setYAxis2SelectedTitle] = useState('')
	const [yAxis3SelectedTitle, setYAxis3SelectedTitle] = useState('')

	const [splitAxisSelected, setSplitAxisSelected] = useState('')
	const [splitAxisSelectedTitle, setSplitAxisSelectedTitle] = useState('')

	const [dataSet, setDataSet] = useState([])
	const [showDataSet, setShowDataSet] = useState(false)
	const [renderChart, setRenderChart] = useState(false)
	const [chartDisplayed, setChartDisplayed] = useState(false)
	const [showGroupBy, setShowGroupBy] = useState(false)
	const [splitIntoMultipleSeries, setSplitIntoMultipleSeries] = useState(false)
	const [showName, setShowName] = useState(false)
	const [showValue, setShowValue] = useState(false)
	const [showPercent, setShowPercent] = useState(false)
	const [toggleRedrawChart, setToggleRedrawChart] = useState(false)
	const [stackBarSeries, setStackBarSeries] = useState(false)
	const [chartSettings, setChartSettings] = useState({})

	useEffect(() => {
		let chart = {}
		chart.display = chartDisplayed
		chart.type = chartSelected
		chart.method = methodSelected
		chart.step = dateGroupSelected
		chart.xAxis = []
		let colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === xAxisSelected)
		if (colSelected && colSelected.length > 0) {
			chart.xAxis.push(colSelected[0])
		}
		chart.yAxis = []
		if (yAxisSelected) {
			colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxisSelected)
			if (colSelected && colSelected.length > 0) {
				chart.yAxis.push(colSelected[0])
			}
		}
		if (yAxis2Selected) {
			colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxis2Selected)
			if (colSelected && colSelected.length > 0) {
				chart.yAxis.push(colSelected[0])
			}
		}
		if (yAxis3Selected) {
			colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxis3Selected)
			if (colSelected && colSelected.length > 0) {
				chart.yAxis.push(colSelected[0])
			}
		}
		chart.labels = []
		if (showName) {
			chart.labels.push('showName')
		}
		if (showValue) {
			chart.labels.push('showValue')
		}
		if (showPercent) {
			chart.labels.push('showPercent')
		}
		chart.splitAxis = []
		if (splitIntoMultipleSeries && splitAxisSelected) {
			colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === splitAxisSelected)
			if (colSelected && colSelected.length > 0) {
				chart.splitAxis.push(colSelected[0])
			}
		}
		chart.features = []
		if (stackBarSeries) {
			chart.features.push('stack')
		}

		if (isEmpty(chart)) return

		setChartSettings(chart)
	}, [
		chartSelected,
		chartDisplayed,
		methodSelected,
		dateGroupSelected,
		xAxisSelected,
		yAxisSelected,
		yAxis2Selected,
		yAxis3Selected,
		showName,
		showValue,
		showPercent,
		splitIntoMultipleSeries,
		splitAxisSelected,
		stackBarSeries,
	])

	useEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.CHART,
			value: chartSettings,
		})
	}, [chartSettings])

	const loadSavedChart = () => {
		let isRenderChart = true
		if (viewData && viewData.chart) {
			if (viewData.chart.display) {
				setChartDisplayed(viewData.chart.display)
			}
			if (viewData.chart.type) {
				setChartSelected(viewData.chart.type)
			} else {
				isRenderChart = false
			}
			if (viewData.chart.method) {
				setMethodSelected(viewData.chart.method)
			} else {
				isRenderChart = false
			}

			if (viewData.chart.step) {
				setDateGroupSelected(viewData.chart.step)
			}

			if (viewData.chart.splitAxis && viewData.chart.splitAxis.length > 0) {
				setSplitIntoMultipleSeries(true)
				setSplitAxisSelected(viewData.chart.splitAxis[0].name)
				setSplitAxisSelectedTitle(viewData.chart.splitAxis[0].title)
			} else {
				setSplitIntoMultipleSeries(false)
				setSplitAxisSelected('')
				setSplitAxisSelectedTitle('')
			}

			if (viewData.chart.features && viewData.chart.features.length > 0) {
				if (viewData.chart.features.includes('stack')) {
					setStackBarSeries(true)
				} else {
					setStackBarSeries(false)
				}
			} else {
				setStackBarSeries(false)
			}

			if (viewData.chart.xAxis && viewData.chart.xAxis.length > 0) {
				setXAxisSelected(viewData.chart.xAxis[0].name)
				setXAxisSelectedTitle(viewData.chart.xAxis[0].title)
				handleShowGroupBy(viewData.chart.xAxis[0].name)
			} else {
				isRenderChart = false
			}
			if (viewData.chart.yAxis && viewData.chart.yAxis.length > 0) {
				if (viewData.chart.yAxis.length > 0) {
					setYAxisSelected(viewData.chart.yAxis[0].name)
					setYAxisSelectedTitle(viewData.chart.yAxis[0].title)
				}
				if (viewData.chart.yAxis.length > 1) {
					setYAxis2Selected(viewData.chart.yAxis[1].name)
					setYAxis2SelectedTitle(viewData.chart.yAxis[1].title)
				}
				if (viewData.chart.yAxis.length > 2) {
					setYAxis3Selected(viewData.chart.yAxis[2].name)
					setYAxis3SelectedTitle(viewData.chart.yAxis[2].title)
				}
			} else if (isRenderChart) {
				if (viewData.chart.method !== 'count') {
					isRenderChart = false
				}
			}
			if (isRenderChart) {
				setRenderChart(true)
			}
			if (viewData.chart.labels && viewData.chart.labels.length > 0) {
				viewData.chart.labels.forEach((item, index) => {
					switch (item) {
						case 'showName':
							setShowName(true)
							break
						case 'showValue':
							setShowValue(true)
							break
						case 'showPercent':
							setShowPercent(true)
							break
						default:
							break
					}
				})
			} else {
				setShowName(false)
				setShowValue(false)
				setShowPercent(false)
			}
		} else {
			setChartDisplayed(true)
		}
	}

	useLayoutEffect(() => {
		if (isEmpty(listActiveFieldsOptions) || isEmpty(viewData)) return

		loadSavedChart()
	}, [listActiveFieldsOptions, viewData])

	const handleClick = (event) => {
		event.preventDefault()
		loadSavedChart()
	}

	const handleOk = () => {
		if (environment && environment.chartDS) {
			dispatch({
				type: ENV_ACTIONS.CHART_DS,
				payload: null,
			})
		}
		handleCharting()
		handleClose()
		onForceUpdate?.()
	}

	const handleHelp = () => {
		window.open(HELP_LINK_DATA_CHART, '_blank')
	}

	const handleClose = () => {
		setChartDisplayed(null)
		setChartSelected(null)
		setMethodSelected(null)
		setDateGroupSelected(null)
		setXAxisSelected('')
		setYAxisSelected('')
		setYAxis2Selected('')
		setYAxis3Selected('')
		setSplitAxisSelected('')
		setXAxisSelectedTitle('')
		setYAxisSelectedTitle('')
		setYAxis2SelectedTitle('')
		setYAxis3SelectedTitle('')
		setSplitAxisSelectedTitle('')
		setDataSet([])
		setSeriesOmit([])

		tab !== 'devices'
			? dispatch({
				type: FORM_ACTIONS.VIEW_UPDATE,
				payload: {},
			})
			: dispatch({
				type: DEVICES_ACTIONS.VIEW_UPDATE,
				payload: {},
			})
	}

	const handleChartSelection = (e, newOption) => {
		setChartSelected(newOption)
		if (newOption === 'pieChart' || newOption === 'scatterPlot') {
			setSplitIntoMultipleSeries(false)
		} else if (newOption === 'barChart') {
			setStackBarSeries(false)
		}
	}

	const handleMethodSelection = (e, newOption) => {
		if (newOption === 'count') {
			setYAxisSelected(null)
			setYAxis2Selected(null)
			setYAxis3Selected(null)
			setSplitAxisSelected(null)
			setYAxisSelectedTitle(null)
			setYAxis2SelectedTitle(null)
			setYAxis3SelectedTitle(null)
			setSplitAxisSelectedTitle(null)
			setSplitIntoMultipleSeries(false)
		}
		setMethodSelected(newOption)
	}

	const handleDateGroupSelected = (e) => {
		setDateGroupSelected(e.target.value || 'day')
	}

	const handleXAxisSelection = (e, newOption) => {
		setXAxisSelected(e.target.value)
		setXAxisSelectedTitle(newOption.props.children)
		handleShowGroupBy(e.target.value)
	}

	const handleShowGroupBy = (xAxisSelectedName) => {
		let dateTimeSelected = axesOptions.filter(
			(item) => ['DATETIME', 'DATE'].includes(item.type) && item.name == xAxisSelectedName
		)
		if (dateTimeSelected.length > 0) {
			setShowGroupBy(true)
		} else {
			setShowGroupBy(false)
		}
	}

	const handleYAxisSelection = (e, newOption) => {
		if (!e.target.value || e.target.value === '') {
			setYAxisSelected(null)
			setYAxisSelectedTitle(null)
		} else {
			setYAxisSelected(e.target.value)
			setYAxisSelectedTitle(newOption.props.children)
		}
	}

	const handleSplitAxisSelection = (e, newOption) => {
		if (!e.target.value || e.target.value === '') {
			setSplitAxisSelected(null)
			setSplitAxisSelectedTitle(null)
		} else {
			setSplitAxisSelected(e.target.value)
			setSplitAxisSelectedTitle(newOption.props.children)
		}
	}

	const handleSplitIntoMultipleSeriesChecked = (event) => {
		if (event && event.target.checked) {
			setSplitIntoMultipleSeries(true)

			setYAxis2Selected(null)
			setYAxis3Selected(null)

			setYAxis2SelectedTitle(null)
			setYAxis3SelectedTitle(null)
		} else {
			setSplitIntoMultipleSeries(false)

			setSplitAxisSelected(null)
			setSplitAxisSelectedTitle(null)
		}
	}

	const handleStackBarSeriesChecked = (event) => {
		setStackBarSeries(!stackBarSeries)
	}

	const handleYAxis2Selection = (e, newOption) => {
		if (!e.target.value || e.target.value === '') {
			setYAxis2Selected(null)
			setYAxis2SelectedTitle(null)
		} else {
			setYAxis2Selected(e.target.value)
			setYAxis2SelectedTitle(newOption.props.children)
		}
	}

	const handleYAxis3Selection = (e, newOption) => {
		if (!e.target.value || e.target.value === '') {
			setYAxis3Selected(null)
			setYAxis3SelectedTitle(null)
		} else {
			setYAxis3Selected(e.target.value)
			setYAxis3SelectedTitle(newOption.props.children)
		}
	}

	const handleApply = (event) => {
		event.preventDefault()
		setRenderChart(true)
		setOpenChartSection(true)
	}

	const displayChartSelection = () => {
		return (
			<div>
				<ThemeProvider theme={theme}>
					<Typography id="transition-modal-title" variant="h6" sx={{ textAlign: 'left' }}>
						{t('common:chart.chartType')}
					</Typography>
				</ThemeProvider>
				<ToggleButtonGroup
					className={classes.buttonGroup}
					sx={{ pt: 1 }}
					value={chartSelected}
					exclusive
					onChange={handleChartSelection}
					fullWidth
				>
					{chartOptions.map((option) => {
						const Icon = option.icon
						return (
							<ToggleButton key={option.name} value={option.name} className={classes.icon}>
								<Icon color="primary" fontSize="small" /> {option.title}
							</ToggleButton>
						)
					})}
				</ToggleButtonGroup>
			</div>
		)
	}

	const displayMethodSelection = () => {
		return (
			<div>
				<ThemeProvider theme={theme}>
					<Typography id="transition-modal-title" variant="h6" sx={{ textAlign: 'left', pt: 1.5 }}>
						{t('common:chart.chartMethod')}
					</Typography>
				</ThemeProvider>
				<ToggleButtonGroup
					className={classes.buttonGroup}
					sx={{ pt: 1 }}
					value={methodSelected}
					exclusive
					onChange={handleMethodSelection}
					fullWidth
				>
					{methodOptions.map((option) => (
						<ToggleButton key={option.name} value={option.name} className={classes.icon}>
							{option.title}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</div>
		)
	}

	const displayAxisSelection = () => {
		return (
			<div>
				<ThemeProvider theme={theme}>
					<Typography id="transition-modal-title" variant="h6" sx={{ textAlign: 'left', pt: 1.5 }}>
						{t('common:chart.chartData')}
					</Typography>
				</ThemeProvider>
				{showXAxisOptions()}
				{showGroupBy && showGroupByOptions()}
				{xAxisSelected && methodSelected !== 'count' && showYAxisOptions()}
				{xAxisSelected && methodSelected === 'count' && showLabelsOptions()}
			</div>
		)
	}

	const handleToggleDisplayChart = (event) => {
		setChartDisplayed(event.target.checked)
	}

	const toggleDisplayChart = () => {
		if (hiddenDisplayChartBtn) return null
		return (
			<FormGroup sx={{ mt: 1 }}>
				<FormControlLabel
					sx={{
						'& .MuiFormControlLabel-label': {
							fontSize: '0.875rem !important',
						},
					}}
					control={
						<Switch
							checked={chartDisplayed}
							disabled={
								methodSelected === 'count'
									? !xAxisSelected
									: !yAxisSelected && !yAxis2Selected && !yAxis3Selected
							}
							onChange={handleToggleDisplayChart}
						/>
					}
					label={t('common:chart.displayChart')}
				/>
			</FormGroup>
		)
	}

	const showXAxisOptions = () => (
		<TextField
			id="axis-options"
			className={classes.axisBox}
			fullWidth
			size="small"
			color="primary"
			variant="outlined"
			select={true}
			label={chartSelected === 'pieChart' ? t('common:chart.category') : t('common:chart.xAxis')}
			value={xAxisSelected}
			onChange={handleXAxisSelection}
		>
			{axesOptions.map((option, index) => (
				<MenuItem key={index} value={option.name}>
					{option.title}
				</MenuItem>
			))}
		</TextField>
	)

	const showGroupByOptions = () => {
		return (
			<FormControl style={{ width: '100%', display: 'inline-block' }}>
				<FormLabel
					sx={{
						'& .MuiFormLabel-root': {
							fontSize: '0.875rem !important',
						},
					}}
					style={{ textAlign: 'left', float: 'left', width: '100px', lineHeight: '3' }}
					id="demo-row-radio-buttons-group-label"
				>
					{t('common:chart.groupBy')}
				</FormLabel>
				<RadioGroup
					style={{ float: 'left' }}
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					onChange={handleDateGroupSelected}
				>
					{dateTimeGroup.map((option, index) => (
						<FormControlLabel
							sx={{
								'& .MuiFormControlLabel-label': {
									fontSize: '0.875rem !important',
								},
							}}
							key={index}
							value={option.name}
							control={<Radio checked={dateGroupSelected === option.name} />}
							label={option.title}
						/>
					))}
				</RadioGroup>
			</FormControl>
		)
	}

	const showLabelsOptions = () => {
		return (
			<>
				<FormGroup style={{ width: '100%', display: 'inline-block' }}>
					<FormLabel
						sx={{
							'& .MuiFormLabel-root': {
								fontSize: '0.875rem !important',
							},
						}}
						style={{ textAlign: 'left', float: 'left', width: '100px', lineHeight: '3' }}
						id="demo-row-radio-buttons-group-label"
					>
						{t('common:chart.labels')}
					</FormLabel>
					<FormControlLabel
						style={{ float: 'left' }}
						control={<Checkbox checked={showName} onChange={handleShowName} />}
						label={t('common:chart.name')}
					/>
					<FormControlLabel
						style={{ float: 'left' }}
						control={<Checkbox checked={showValue} onChange={handleShowValue} />}
						label={t('common:chart.value')}
					/>
					{chartSelected === 'pieChart' && (
						<FormControlLabel
							style={{ float: 'left' }}
							control={<Checkbox checked={showPercent} onChange={handleShowPercent} />}
							label={t('common:chart.percent')}
						/>
					)}
				</FormGroup>
			</>
		)
	}

	const showYAxisOptions = () => {
		return (
			<>
				<ThemeProvider theme={theme}>
					<Typography id="transition-modal-title" variant="h6" sx={{ textAlign: 'left', pt: 1.5 }}>
						{chartSelected === 'pieChart' ? t('common:chart.series') : t('common:chart.yAxis')}
					</Typography>
				</ThemeProvider>
				<TextField
					hiddenLabel
					label={t('common:chart.series1')}
					id="yxis-options"
					className={classes.axisBox}
					fullWidth
					size="small"
					color="primary"
					variant="outlined"
					select={true}
					disabled={methodSelected === 'count'}
					value={yAxisSelected}
					onChange={handleYAxisSelection}
				>
					<MenuItem value={null}>&nbsp;</MenuItem>
					{axesOptions.map((option, index) => (
						<MenuItem key={index} disabled={disabledYAxisItem(option)} value={option.name}>
							{option.title}
						</MenuItem>
					))}
				</TextField>
				<FormGroup>
					<FormControlLabel
						sx={{
							'& .MuiFormControlLabel-label': {
								fontSize: '0.875rem !important',
							},
						}}
						control={
							<Checkbox
								disabled={chartSelected === 'pieChart' || chartSelected === 'scatterPlot'}
								checked={splitIntoMultipleSeries}
								onChange={handleSplitIntoMultipleSeriesChecked}
							/>
						}
						label={t('common:chart.splitIntoMultipleSeries')}
					/>
				</FormGroup>
				{splitIntoMultipleSeries && (
					<TextField
						hiddenLabel
						label=""
						id="splitAxis"
						className={classes.splitAxisBox}
						fullWidth
						size="small"
						color="primary"
						select={true}
						disabled={methodSelected === 'count'}
						value={splitAxisSelected}
						onChange={handleSplitAxisSelection}
					>
						<MenuItem value={null}>&nbsp;</MenuItem>
						{axesOptions.map((option, index) => (
							<MenuItem key={index} disabled={disabledSplitAxisItem(option)} value={option.name}>
								{option.title}
							</MenuItem>
						))}
					</TextField>
				)}
				{!splitIntoMultipleSeries && (
					<TextField
						hiddenLabel
						label={t('common:chart.series2')}
						id="yxis-options2"
						className={classes.axisBox}
						fullWidth
						size="small"
						color="primary"
						variant="outlined"
						select={true}
						disabled={methodSelected === 'count'}
						value={yAxis2Selected}
						onChange={handleYAxis2Selection}
					>
						<MenuItem value={null}>&nbsp;</MenuItem>
						{axesOptions.map((option, index) => (
							<MenuItem key={index} disabled={disabledYAxisItem(option)} value={option.name}>
								{option.title}
							</MenuItem>
						))}
					</TextField>
				)}
				{!splitIntoMultipleSeries && (
					<TextField
						hiddenLabel
						label={t('common:chart.series3')}
						id="yxis-options3"
						className={classes.axisBox}
						fullWidth
						size="small"
						color="primary"
						variant="outlined"
						select={true}
						disabled={methodSelected === 'count'}
						value={yAxis3Selected}
						onChange={handleYAxis3Selection}
					>
						<MenuItem value={null}>&nbsp;</MenuItem>
						{axesOptions.map((option, index) => (
							<MenuItem key={index} disabled={disabledYAxisItem(option)} value={option.name}>
								{option.title}
							</MenuItem>
						))}
					</TextField>
				)}
				<FormGroup style={{ width: '100%', display: 'inline-block' }}>
					<FormLabel
						sx={{
							'& .MuiFormLabel-root': {
								fontSize: '0.875rem !important',
							},
						}}
						style={{ textAlign: 'left', float: 'left', width: '100px', lineHeight: '3' }}
						id="demo-row-radio-buttons-group-label"
					>
						{t('common:chart.labels')}
					</FormLabel>
					<FormControlLabel
						sx={{
							'& .MuiFormControlLabel-label': {
								fontSize: '0.875rem !important',
							},
						}}
						style={{ float: 'left' }}
						control={<Checkbox checked={showName} onChange={handleShowName} />}
						label={t('common:chart.name')}
					/>
					<FormControlLabel
						sx={{
							'& .MuiFormControlLabel-label': {
								fontSize: '0.875rem !important',
							},
						}}
						style={{ float: 'left' }}
						control={<Checkbox checked={showValue} onChange={handleShowValue} />}
						label={t('common:chart.value')}
					/>
					{chartSelected === 'pieChart' && (
						<FormControlLabel
							sx={{
								'& .MuiFormControlLabel-label': {
									fontSize: '0.875rem !important',
								},
							}}
							style={{ float: 'left' }}
							control={<Checkbox checked={showPercent} onChange={handleShowPercent} />}
							label={t('common:chart.percent')}
						/>
					)}
				</FormGroup>
				{chartSelected === 'barChart' && (
					<FormGroup>
						<FormControlLabel
							sx={{
								'& .MuiFormControlLabel-label': {
									fontSize: '0.875rem !important',
								},
							}}
							control={<Checkbox checked={stackBarSeries} onChange={handleStackBarSeriesChecked} />}
							label={t('common:chart.stackSeries')}
						/>
					</FormGroup>
				)}
			</>
		)
	}

	const disabledYAxisItem = (option) => {
		switch (option.type) {
			case 'INTEGER':
				return false
			case 'FLOAT':
				return false
			case 'STRING':
				if (
					option.format !== undefined &&
					option.format.style !== undefined &&
					option.format.style === 'currency'
				) {
					return false
				}
				break
			default:
				break
		}
		return true
	}

	const valueOf = (value) => {
		if (typeof value === 'string') {
			value = parseFloat(value)
		}
		return !Number.isNaN(value) ? value : 0
	}

	const disabledSplitAxisItem = (option) => {
		switch (option.type) {
			case 'DATETIME':
				return true
			case 'TIME':
				return true
			case 'Date':
				return true
			default:
				break
		}
		return false
	}

	const displayDataSet = () => {
		return (
			<div>
				<Button onClick={() => setShowDataSet(!showDataSet)} style={{ textTransform: 'none' }}>
					{showDataSet ? t('common:chart.hideChartDS') : t('common:chart.showChartDS')}
				</Button>
				{showDataSet ? dataSetGrid : null}
			</div>
		)
	}

	const dataSetGrid = useMemo(() => {
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		return (
			<TableContainer component={Paper} style={{ maxHeight: '20em' }}>
				<Table sx={{ minWidth: 100 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{xAxisSelectedTitle}</TableCell>
							{(yAxisSelected || methodSelected === 'count') && (
								<TableCell align="left">
									{methodSelected === 'count'
										? t('common:chart.count')
										: methodSelected === 'sum'
											? `${t('common:chart.total')} ` + chartName
											: `${t('common:chart.average')} ` + chartName}
								</TableCell>
							)}
							{yAxis2Selected && (
								<TableCell align="left">
									{methodSelected === 'sum'
										? `${t('common:chart.total')} ` + chartName2
										: `${t('common:chart.average')} ` + chartName2}
								</TableCell>
							)}
							{yAxis3Selected && (
								<TableCell align="left">
									{methodSelected === 'sum'
										? `${t('common:chart.total')} ` + chartName3
										: `${t('common:chart.average')} ` + chartName3}
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{dataSet.map((row, index) => (
							<TableRow key={index}>
								<TableCell component="th" scrope="row">
									{' '}
									{row.xAxisData}
								</TableCell>
								{(yAxisSelected || methodSelected === 'count') && (
									<TableCell align="left">
										{methodSelected === 'count'
											? row.count
											: methodSelected === 'sum'
												? row.total
												: row.average}
									</TableCell>
								)}
								{yAxis2Selected && (
									<TableCell align="left">
										{methodSelected === 'sum' ? row.total2 : row.average2}
									</TableCell>
								)}
								{yAxis3Selected && (
									<TableCell align="left">
										{methodSelected === 'sum' ? row.total3 : row.average3}
									</TableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}, [dataSet])

	const displayChart = () => {
		switch (drawChartSelected) {
			case 'lineChart':
				return displayLineChart
			case 'barChart':
				return displayBarChart
			case 'pieChart':
				if (methodSelected === 'count') {
					return displayCountPieChart
				} else {
					return displayPieChart
				}
			case 'scatterPlot':
				return displayScatterPlot
			default:
				return null
		}
	}

	const titleOfchart = useMemo(() => {
		let colValName =
			methodSelected === 'count'
				? t('common:chart.count')
				: methodSelected === 'sum'
					? t('common:chart.total')
					: t('common:chart.average')
		switch (methodSelected) {
			case 'count':
				return colValName + ` ${t('common:chart.by')} ` + xAxisSelectedTitle
				return
			default:
				let ofLabel = ''
				let s = ''
				if (yAxisSelectedTitle) {
					ofLabel += s
					ofLabel += yAxisSelectedTitle
					s = ', '
				}
				if (yAxis2SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis2SelectedTitle
					s = ', '
				}
				if (yAxis3SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis3SelectedTitle
				}
				return (
					colValName +
					` ${t('common:chart.of')} ` +
					ofLabel +
					` ${t('common:chart.by')} ` +
					xAxisSelectedTitle
				)
		}
	}, [dataSet])

	const computeStep = (xAxisData) => {
		if (!xAxisData) return []
		if (!xAxisData.length) return []

		var result = []

		var dateRange = xAxisData.sort(function (left, right) {
			return new Date(left).getTime() - new Date(right).getTime()
		})
		var oldest = dateRange[0]
		var recent = dateRange[dateRange.length - 1]

		var differenceInTime = new Date(recent).getTime() - new Date(oldest).getTime()
		var differenceInDays = differenceInTime / (1000 * 3600 * 24)

		for (var i = 0; i <= differenceInDays; i++) {
			var dateObj = new Date(oldest)
			var momentObj = moment(dateObj)

			result.push(momentObj.format('MM/DD/YYYY'))

			var momentNext = momentObj.add(1, 'days')
			var momentString = momentNext.format('MM/DD/YYYY')
			oldest = momentNext.format('MM/DD/YYYY')
		}
		return result
	}

	const xAxisDataSet = () => {
		if (dataSet == null) return []
		let xAxisData = dataSet.map((item) => {
			return item['xAxisData']
		})
		return xAxisData
	}

	const yAxisDataSet = useMemo(() => {
		if (dataSet == null) return []
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let yAxisData = dataSet.map((item) => {
			return item[colValName]
		})
		return yAxisData
	}, [dataSet])

	const chartDataSet = useMemo(() => {
		if (!dataSet || dataSet.length == 0) return []
		let colXSelected = listActiveFieldsOptions.filter((col) => col['name'] === xAxisSelected)
		if (colXSelected.length == 0) return []
		switch (colXSelected[0].type) {
			case 'DATE':
			case 'DATETIME':
			case 'TIME':
				let dayDataSetRet = []
				let dayDataSet = computeStep(xAxisDataSet())
				if (splitIntoMultipleSeries) {
					let mappedSplitAxisRecord = map(records, splitAxisSelected)
					let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
					dayDataSet.forEach((item) => {
						let itemDayData = { xAxisData: item, count: 0, total: 0, average: 0 }
						if (distinctMappedSplitAxisRecord) {
							distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
								itemDayData[index + '_total'] = 0
								itemDayData[index + '_average'] = 0
								itemDayData[index + '_count'] = 0
							})
						}
						dayDataSetRet.push(itemDayData)
					})
					if (dayDataSetRet.length === 0) return dataSet
					dataSet.forEach((item) => {
						var xVal = item['xAxisData']
						xVal = new Date(xVal)
						var momentObj = moment(xVal)
						xVal = momentObj.format('MM/DD/YYYY')
						let dayItem = dayDataSetRet.filter((item) => item['xAxisData'] === xVal)[0]
						if (dayItem !== undefined) {
							dayItem.count += item.count
							if (yAxisSelected) {
								dayItem.total += item.total
								dayItem.average += item.average
							}
							if (distinctMappedSplitAxisRecord && item.splitData) {
								distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
									let total = 0
									let average = 0
									let caount = 0
									if (item.splitData[splitItem] != undefined) {
										total = item.splitData[splitItem].total
										average = item.splitData[splitItem].average
										caount = item.splitData[splitItem].caount
									}
									dayItem[index + '_total'] += isNaN(total) ? 0 : total
									dayItem[index + '_average'] += isNaN(average) ? 0 : average
									dayItem[index + '_count'] += isNaN(caount) ? 0 : caount
								})
							}
						}
					})
					if (dateGroupSelected !== 'day') {
						let result = []
						dayDataSetRet.forEach((day, index) => {
							let now = new Date(day['xAxisData'])
							let timeUse
							switch (dateGroupSelected) {
								case 'week':
									let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
									let lastSunday = new Date(today.setDate(today.getDate() - today.getDay()))
									timeUse = moment(lastSunday).format('MM/DD/YYYY')
									break
								case 'month':
									timeUse = moment(now).format('MM/YYYY')
									break
								case 'year':
									timeUse = moment(now).format('YYYY')
									break
								default:
									break
							}
							if (!result.length) {
								day['xAxisData'] = timeUse
								result.push(day)
							} else {
								let resultItem = result.find((item) => item['xAxisData'] === timeUse)
								if (resultItem === undefined) {
									day['xAxisData'] = timeUse
									result.push(day)
								} else {
									resultItem.count += day.count
									if (yAxisSelected) {
										resultItem.total += day.total
										resultItem.average += day.average
									}
									if (distinctMappedSplitAxisRecord) {
										distinctMappedSplitAxisRecord.forEach((splitItem, index) => {
											resultItem[index + '_total'] += day[index + '_total']
											resultItem[index + '_average'] += day[index + '_average']
											resultItem[index + '_count'] += day[index + '_count']
										})
									}
								}
							}
						})
						return result
					}
				} else {
					dayDataSet.forEach((item) => {
						dayDataSetRet.push({
							xAxisData: item,
							count: 0,
							total: 0,
							average: 0,
							total2: 0,
							average2: 0,
							total3: 0,
							average3: 0,
						})
					})
					if (dayDataSetRet.length === 0) return dataSet
					dataSet.forEach((item) => {
						var xVal = item['xAxisData']
						xVal = new Date(xVal)
						var momentObj = moment(xVal)
						xVal = momentObj.format('MM/DD/YYYY')
						let dayItem = dayDataSetRet.filter((item) => item['xAxisData'] === xVal)[0]
						if (dayItem != undefined) {
							dayItem.count += item.count
							if (yAxisSelected) {
								dayItem.total += item.total
								dayItem.average += item.average
							}
							if (yAxis2Selected) {
								dayItem.total2 += item.total2
								dayItem.average2 += item.average2
							}
							if (yAxis3Selected) {
								dayItem.total3 += item.total3
								dayItem.average3 += item.average3
							}
						}
					})
					if (dateGroupSelected !== 'day') {
						let result = []
						dayDataSetRet.forEach((day, index) => {
							let now = new Date(day['xAxisData'])
							let timeUse
							switch (dateGroupSelected) {
								case 'week':
									let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
									let lastSunday = new Date(today.setDate(today.getDate() - today.getDay()))
									timeUse = moment(lastSunday).format('MM/DD/YYYY')
									break
								case 'month':
									timeUse = moment(now).format('MM/YYYY')
									break
								case 'year':
									timeUse = moment(now).format('YYYY')
									break
								default:
									break
							}
							if (!result.length) {
								day['xAxisData'] = timeUse
								result.push(day)
							} else {
								let resultItem = result.find((item) => item['xAxisData'] === timeUse)
								if (resultItem === undefined) {
									day['xAxisData'] = timeUse
									result.push(day)
								} else {
									resultItem.count += day.count
									if (yAxisSelected) {
										resultItem.total += day.total
										resultItem.average += day.average
									}
									if (yAxis2Selected) {
										resultItem.total2 += day.total2
										resultItem.average2 += day.average2
									}
									if (yAxis3Selected) {
										resultItem.total3 += day.total3
										resultItem.average3 += day.average3
									}
								}
							}
						})
						return result
					}
				}

				return dayDataSetRet
			default:
				break
		}
		return dataSet
	}, [dataSet, dateGroupSelected])

	const displayLineChart = useMemo(() => {
		if (drawChartSelected !== 'lineChart') return
		if (!dataSet) return
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		let mappedSplitAxisRecord = map(records, splitAxisSelected)
		let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
		let colors = colorScheme()

		let series = []
		let xAxis = {
			type: 'category',
			data: [],
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let lineSeries

		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}

		let showLabel = false
		if (formater != '') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			lineSeries = {
				name: chartName,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}

			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		if (
			(yAxisSelected || methodSelected === 'count') &&
			splitIntoMultipleSeries &&
			distinctMappedSplitAxisRecord
		) {
			distinctMappedSplitAxisRecord.map((item, index) => {
				lineSeries = {
					name: item,
					data: [],
					type: 'line',
					smooth: false,
					label: {
						show: showLabel,
						position: 'top',
						formatter: formater,
						color: '#000',
					},
					itemStyle: {
						normal: {
							color: colors[index % colors.length],
						},
					},
				}
				if (xAxis.data == null || xAxis.data?.length === 0) {
					addXAxis = true
					xAxis.data = []
				}
				chartDataSet.map((itemData) => {
					if (addXAxis) {
						xAxis.data.push(itemData['xAxisData'])
					}
					lineSeries.data.push(itemData[index + '_' + colValName])
				})
				series.push(lineSeries)
				addXAxis = false
			})
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			lineSeries = {
				name: chartName2,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName2])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
						? chartColor[1]
						: chartColor[2]
			lineSeries = {
				name: chartName3,
				data: [],
				type: 'line',
				smooth: false,
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				lineSeries.data.push(item[colValName3])
			})
			series.push(lineSeries)
			addXAxis = false
		}

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
			},
			legend: legend,
			dataZoom: [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			],
		}

		return <ReactECharts option={options} notMerge={true} style={{ height: 400 }} />
	}, [dataSet])

	const displayBarChart = useMemo(() => {
		if (drawChartSelected !== 'barChart') return
		if (!dataSet) return

		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle
		let mappedSplitAxisRecord = map(records, splitAxisSelected)
		let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
		let colors = colorScheme()

		let series = []
		let xAxis = {
			type: 'category',
			data: [],
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let barSeries

		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			barSeries = {
				name: chartName,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}
			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName])
			})
			series.push(barSeries)
			addXAxis = false
		}

		if (
			(yAxisSelected || methodSelected === 'count') &&
			splitIntoMultipleSeries &&
			distinctMappedSplitAxisRecord
		) {
			distinctMappedSplitAxisRecord.map((item, index) => {
				barSeries = {
					name: item,
					data: [],
					type: 'bar',
					label: {
						show: showLabel,
						position: 'top',
						formatter: formater,
						color: '#000',
					},
					itemStyle: {
						normal: {
							color: colors[index % colors.length],
						},
					},
				}
				if (stackBarSeries) {
					barSeries.stack = 'stack'
				}
				if (xAxis.data == null || xAxis.data?.length === 0) {
					addXAxis = true
					xAxis.data = []
				}
				chartDataSet.map((itemData) => {
					if (addXAxis) {
						xAxis.data.push(itemData['xAxisData'])
					}
					barSeries.data.push(itemData[index + '_' + colValName])
				})
				series.push(barSeries)
				addXAxis = false
			})
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			barSeries = {
				name: chartName2,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}

			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName2])
			})
			series.push(barSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
						? chartColor[1]
						: chartColor[2]
			barSeries = {
				name: chartName3,
				data: [],
				type: 'bar',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (stackBarSeries) {
				barSeries.stack = 'stack'
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				barSeries.data.push(item[colValName3])
			})
			series.push(barSeries)
			addXAxis = false
		}

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
			},
			legend: legend,
			dataZoom: [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			],
		}
		return <ReactECharts option={options} notMerge={true} style={{ height: 400 }} />
	}, [dataSet])

	const onChartLegendselectchanged = (params) => {
		if (!params.selected) return
		let selected = params.selected
		let omittedResult = []
		for (const property in selected) {
			if (selected[property] == false) {
				omittedResult.push(property)
			}
		}
		setSeriesOmit(omittedResult)
	}

	const displayPieChart = useMemo(() => {
		if (drawChartSelected !== 'pieChart') return
		if (!dataSet) return
		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle

		let series = []
		let xAxis = {
			type: 'category',
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let pieSeries

		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (formater != '') {
				formater += '\n'
			}
			formater += '{c}'
		}
		if (showPercent) {
			formater += ' ({d}%)'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}
		if (yAxisSelected) {
			pieSeries = {
				name: chartName,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					formatter: formater,
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected) {
			pieSeries = {
				name: chartName2,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					formatter: formater,
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName2],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		if (yAxis3Selected && yAxisSelected !== yAxis3Selected && yAxis2Selected !== yAxis3Selected) {
			pieSeries = {
				name: chartName3,
				data: [],
				type: 'pie',
				label: {
					show: showLabel,
					formatter: formater,
					color: '#000',
				},
				emphasis: {
					focus: 'self',
				},
			}

			chartDataSet.map((item) => {
				let dataItem = {
					name: item['xAxisData'],
					value: item[colValName3],
				}
				pieSeries.data.push(dataItem)
			})
			series.push(pieSeries)
		}

		let media = [
			{
				option: {
					series: [],
				},
			},
		]

		let chartCount = series.length
		switch (chartCount) {
			case 1:
				media[0].option.series.push({ center: ['50%', '50%'] })
				break
			case 2:
				media[0].option.series.push({ center: ['33.33%', '50%'] })
				media[0].option.series.push({ center: ['66.66%', '50%'] })
				break
			case 3:
				media[0].option.series.push({ center: ['25%', '50%'] })
				media[0].option.series.push({ center: ['50%', '50%'] })
				media[0].option.series.push({ center: ['75%', '50%'] })
				break
		}
		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 24, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series.map((item) => {
				item.radius = 110
				return item
			}),
			tooltip: {
				trigger: 'axis',
			},
			legend: legend,
			media: media,
		}

		return <ReactECharts option={options} notMerge={true} style={{ height: 400 }} />
	}, [dataSet])

	const displayCountPieChart = useMemo(() => {
		if (drawChartSelected !== 'pieChart') return
		if (!dataSet) return
		let colValName = 'count'
		let chartName = xAxisSelectedTitle

		let series = []
		let xAxis = {
			type: 'category',
		}
		let datas = []
		var totalNum = 0
		var selected = {}
		chartDataSet.map((item) => {
			let dataItem = {
				name: item['xAxisData'],
				value: item[colValName],
			}
			if (seriesOmit == null || seriesOmit.length == 0) {
				totalNum += valueOf(item[colValName])
				selected[item['xAxisData']] = true
			} else {
				if (seriesOmit.indexOf(item['xAxisData']) < 0) {
					totalNum += valueOf(item[colValName])
					selected[item['xAxisData']] = true
				} else {
					selected[item['xAxisData']] = false
				}
			}
			datas.push(dataItem)
		})
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
			selected: selected,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let pieSeries

		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (formater != '') {
				formater += '\n'
			}
			formater += '{c} / ' + totalNum
		}
		if (showPercent) {
			formater += ' ({d}%)'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}

		pieSeries = {
			name: chartName,
			data: datas,
			type: 'pie',
			label: {
				show: showLabel,
				formatter: formater,
				color: '#000',
			},
			emphasis: {
				focus: 'self',
			},
		}

		series.push(pieSeries)

		let media = [
			{
				option: {
					series: [{ center: ['50%', '50%'] }],
				},
			},
		]

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 24, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series.map((item) => {
				item.radius = 110
				return item
			}),
			/*tooltip: {
			  trigger: 'axis',
			},*/
			legend: legend,
			media: media,
		}

		const onEvents = {
			legendselectchanged: onChartLegendselectchanged,
		}
		return (
			<ReactECharts option={options} notMerge={true} style={{ height: 400 }} onEvents={onEvents} />
		)
	}, [dataSet, seriesOmit])

	const displayScatterPlot = useMemo(() => {
		if (drawChartSelected !== 'scatterPlot') return
		if (!dataSet) return

		let colValName =
			methodSelected === 'count' ? 'count' : methodSelected === 'sum' ? 'total' : 'average'
		let colValName2 = methodSelected === 'sum' ? 'total2' : 'average2'
		let colValName3 = methodSelected === 'sum' ? 'total3' : 'average3'
		let chartName = methodSelected === 'count' ? xAxisSelectedTitle : yAxisSelectedTitle
		let chartName2 = yAxis2SelectedTitle
		let chartName3 = yAxis3SelectedTitle

		let series = []
		let xAxis = {
			type: 'category',
			data: [],
		}
		let legend = {
			orient: 'horizontal',
			y: 'top',
			x: 'right',
			top: 30,
		}
		let title = {
			text: titleOfchart,
			x: 'center',
		}
		let scatterSeries
		let addXAxis = false
		let formater = ''
		if (showName) {
			formater = '{b}'
		}
		if (showValue) {
			if (showName) {
				formater += '\n'
			}
			formater += '{c}'
		}
		let showLabel = false
		if (formater != '') {
			showLabel = true
		}
		if ((yAxisSelected || methodSelected === 'count') && !splitIntoMultipleSeries) {
			scatterSeries = {
				name: chartName,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: chartColor[0],
					},
				},
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		if (yAxis2Selected && yAxisSelected !== yAxis2Selected && !splitIntoMultipleSeries) {
			let color = chartName === chartName2 ? chartColor[0] : chartColor[1]
			scatterSeries = {
				name: chartName2,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName2])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		if (
			yAxis3Selected &&
			yAxisSelected !== yAxis3Selected &&
			yAxis2Selected !== yAxis3Selected &&
			!splitIntoMultipleSeries
		) {
			let color =
				chartName === chartName3
					? chartColor[0]
					: chartName2 === chartName3
						? chartColor[1]
						: chartColor[2]
			scatterSeries = {
				name: chartName3,
				data: [],
				type: 'scatter',
				label: {
					show: showLabel,
					position: 'top',
					formatter: formater,
					color: '#000',
				},
				itemStyle: {
					normal: {
						color: color,
					},
				},
			}
			if (xAxis.data == null || xAxis.data?.length === 0) {
				addXAxis = true
				xAxis.data = []
			}
			chartDataSet.map((item) => {
				if (addXAxis) {
					xAxis.data.push(item['xAxisData'])
				}
				scatterSeries.data.push(item[colValName3])
			})
			series.push(scatterSeries)
			addXAxis = false
		}

		const options = {
			title: title,
			grid: { top: 60, right: 8, bottom: 125, left: 50 },
			xAxis: xAxis,
			yAxis: {
				type: 'value',
			},
			series: series,
			tooltip: {
				trigger: 'axis',
			},
			legend: legend,
			dataZoom: [
				{
					type: 'inside',
				},
				{
					type: 'slider',
					xAxisIndex: 0,
					startValue: xAxis.data[0],
					minSpan: 5,
					bottom: 50,
				},
			],
		}
		return <ReactECharts option={options} notMerge={true} style={{ height: 400 }} />
	}, [dataSet])

	const handleCharting = (e) => {
		setLoading(true)
		setDrawChartSelected(chartSelected)
		let mappedAxisRecord = map(records, xAxisSelected)
		let distinct = uniq(mappedAxisRecord)
		let result = null
		if (!distinct.length) {
			setLoading(false)
			return []
		}

		if (splitIntoMultipleSeries) {
			let mappedSplitAxisRecord = map(records, splitAxisSelected)
			let distinctMappedSplitAxisRecord = uniq(mappedSplitAxisRecord)
			result = distinct.map((item) => {
				let itemName = item === null || item === '' ? '*Empty*' : item
				let filteredList = records.filter((rec) => rec[xAxisSelected] === item)
				if (!filteredList.length) return []

				let retItem = { xAxisData: itemName, count: filteredList.length, splitData: {} }
				if (yAxisSelected && yAxisSelected !== '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total = sum
					retItem.average = average

					if (distinctMappedSplitAxisRecord) {
						distinctMappedSplitAxisRecord.forEach((splitItem) => {
							let filteredSplitList = filteredList.filter(
								(splitRec) => splitRec[splitAxisSelected] === splitItem
							)
							let initialValue = 0
							let sum = filteredSplitList.reduce(
								(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
								initialValue
							)
							sum = Math.round(sum * 100) / 100
							let average = sum / filteredSplitList.length
							average = Math.round(average * 100) / 100
							if (retItem.splitData === null) {
								retItem.splitData = {}
							}

							retItem.splitData[splitItem] = {
								total: sum,
								average: average,
								count: filteredSplitList.length,
							}
						})
					}
				}

				return retItem
			})
		} else {
			result = distinct.map((item) => {
				let itemName = item === null || item === '' ? '*Empty*' : item
				let filteredList = records.filter((rec) => rec[xAxisSelected] === item)
				if (!filteredList.length) return []

				let retItem = { xAxisData: itemName, count: filteredList.length }
				if (yAxisSelected && yAxisSelected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxisSelected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total = sum
					retItem.average = average
				}
				if (yAxis2Selected && yAxis2Selected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxis2Selected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total2 = sum
					retItem.average2 = average
				}
				if (yAxis3Selected && yAxis3Selected != '') {
					let initialValue = 0
					let sum = filteredList.reduce(
						(prev, curr) => valueOf(prev) + valueOf(curr[yAxis3Selected]),
						initialValue
					)
					sum = Math.round(sum * 100) / 100
					let average = sum / filteredList.length
					average = Math.round(average * 100) / 100
					retItem.total3 = sum
					retItem.average3 = average
				}
				return retItem
			})
		}

		setLoading(false)
		setSeriesOmit([])
		setDataSet([...result])
		setViewChartData()
	}

	const handleShowName = (event) => {
		setShowName(!showName)
	}

	const handleShowValue = (event) => {
		setShowValue(!showValue)
	}

	const handleShowPercent = (event) => {
		setShowPercent(!showPercent)
	}

	const setViewChartData = () => {
		if (viewData) {
			if (!viewData.chart) {
				viewData.chart = {}
			}
			viewData.chart.display = chartDisplayed
			viewData.chart.type = chartSelected
			viewData.chart.method = methodSelected
			viewData.chart.step = dateGroupSelected
			viewData.chart.xAxis = []
			let colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === xAxisSelected)
			if (colSelected && colSelected.length > 0) {
				viewData.chart.xAxis.push(colSelected[0])
			}
			viewData.chart.yAxis = []
			if (yAxisSelected) {
				colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxisSelected)
				if (colSelected && colSelected.length > 0) {
					viewData.chart.yAxis.push(colSelected[0])
				}
			}
			if (yAxis2Selected) {
				colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxis2Selected)
				if (colSelected && colSelected.length > 0) {
					viewData.chart.yAxis.push(colSelected[0])
				}
			}
			if (yAxis3Selected) {
				colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === yAxis3Selected)
				if (colSelected && colSelected.length > 0) {
					viewData.chart.yAxis.push(colSelected[0])
				}
			}
			viewData.chart.labels = []
			if (showName) {
				viewData.chart.labels.push('showName')
			}
			if (showValue) {
				viewData.chart.labels.push('showValue')
			}
			if (showPercent) {
				viewData.chart.labels.push('showPercent')
			}
			viewData.chart.splitAxis = []
			if (splitIntoMultipleSeries && splitAxisSelected) {
				colSelected = listActiveFieldsOptions.filter((rec) => rec['name'] === splitAxisSelected)
				if (colSelected && colSelected.length > 0) {
					viewData.chart.splitAxis.push(colSelected[0])
				}
			}
			viewData.chart.features = []
			if (stackBarSeries) {
				viewData.chart.features.push('stack')
			}
		}
	}

	useEffect(() => {
		if (!renderChart) return
		setRenderChart(false)
		handleCharting()
	}, [renderChart])

	return (
		// Apply button only visible for edit wizard
		<CollapseComponent
			title={title}
			helpBtn={
				<Button
					aria-label="help"
					size="small"
					onClick={handleHelp}
					sx={{
						borderRadius: '50%',
						width: '40px !important',
						minWidth: '40px !important',
						height: '40px !important',
						alignItems: 'baseline'
					}}
				>
					<HelpOutlineIcon fontSize="small" />
				</Button >
			}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				!isEmpty(axesOptions) && (
					<StackMui direction={'column'}>
						<Box sx={{ flex: 1 }}>
							{displayChartSelection()}
							{chartSelected && displayMethodSelection()}
							{methodSelected && displayAxisSelection()}
							{methodSelected && toggleDisplayChart()}
							<div className={classes.footer}>
								{!isAddNew && (
									<LoadingButton
										className={classes.icon}
										loading={loading}
										onClick={handleApply}
										disabled={
											methodSelected === 'count'
												? !xAxisSelected
												: !yAxisSelected && !yAxis2Selected && !yAxis3Selected
										}
									>
										{t('common:misc.apply')}
									</LoadingButton>
								)}
							</div>
						</Box>
						{!isAddNew && openChartSection && !isEmpty(dataSet) && (
							<>
								<Divider sx={{ ml: 2 }} orientation="horizontal" variant="middle" />
								<Box sx={{ flex: 1, position: 'relative' }}>
									{!isEmpty(dataSet) && displayChart()}
									{!isEmpty(dataSet) && displayDataSet()}
									<div className={classes.deleteIcon}>
										<IconButton aria-label="delete" onClick={() => setOpenChartSection(false)}>
											<Close />
										</IconButton>
									</div>
								</Box>
							</>
						)}
					</StackMui>
				)
			}
		/>
	)
}

export const ChartCollapse = memo(ChartComponent, shouldPreventRender)
