import { useContext, useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { IconThemeContext } from '../../../../../custom-components/context/IconThemesContext'

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Grid,
	Stack,
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
	colLeft: {
		textAlign: 'left',
		// paddingTop: '10px',
		lineHeight: '30px',
	},
	colRight: {
		display: 'inline-block',
		width: '65%',
		textAlign: 'left',
		color: '#a6a6a6',
		// paddingTop: '15px',
		minHeight: '41px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	disabledColRight: {
		display: 'inline-block',
		width: '65%',
		textAlign: 'left',
		// paddingTop: '15px',
		minHeight: '30px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		display: 'flex',
		paddingLeft: '10px',
		minHeight: '32px',
	},
}))

const theme = createTheme({
	typography: {
		subtitle1: {
			fontSize: '0.875rem',
			color: '#999999',
		},
		subtitle2: {
			fontSize: '0.875rem',
			fontWeight: 400,
		},
		h6: {
			fontSize: '0.875rem',
		},
	},
})

const CollapseCompent = ({
	title,
	helpBtn,
	value,
	content,
	footer,
	expanded,
	setExpandedAccordion,
}) => {
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const disabled = useMemo(() => !setExpandedAccordion, [setExpandedAccordion])

	const accordionClicked = () => {
		if (disabled) return

		if (expanded) {
			setExpandedAccordion('')
			return
		}

		setExpandedAccordion(title)
	}

	return (
		<Accordion
			key={title}
			onChange={() => accordionClicked()}
			expanded={expanded}
			sx={{
				'& .MuiAccordionDetails-root': {
					padding: '0 16px !important',
				},
				'& .MuiAccordionSummary-root': {
					minHeight: '48px !important',
				},
				'& .MuiAccordionSummary-content': {
					margin: '0 !important',
				},
				'& .MuiOutlinedInput-input': {
					fontSize: '0.875rem',
				},
				'& .MuiInput-input': {
					fontSize: '0.875rem',
				},
				'& .MuiGrid-root': {
					marginTop: '0',
				},
			}}
		>
			<AccordionSummary disabled={disabled} expandIcon={<ExpandMoreOutlined />}>
				<Grid container spacing={2}>
					<Grid xs={4} md={4}>
						<Stack direction="row">
							<ThemeProvider theme={theme}>
								<Typography variant="h6" className={`${classes.title} ${classes.colLeft}`}>
									{title}
								</Typography>
							</ThemeProvider>
							{helpBtn}
						</Stack>
					</Grid>
					<Grid xs md>
						{typeof value === 'string' ? (
							<ThemeProvider theme={theme}>
								<Typography
									variant="subtitle2"
									className={disabled ? classes.disabledColRight : classes.colRight}
								>
									{value}
								</Typography>
							</ThemeProvider>
						) : (
							value
						)}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<div
					style={{
						margin: '5px 0 0 !important',
					}}
				>
					{content}
				</div>
				{footer && (
					<div>
						<ThemeProvider theme={theme}>
							<Typography variant="subtitle1">{footer}</Typography>
						</ThemeProvider>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default CollapseCompent
