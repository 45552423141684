export const MAP_GPS_SOURCE_TYPE = {
	DRIVER_TECHNOLOGIES: 'Driver Technologies',
	GEOTAB_ADDINS: 'Geotab Add-ins',
}

export const defaultMapState = {
	center: { lat: 33.753746, lng: -84.38633 },
	zoom: 11,
}

export const DEFAULT_REPEAT_RELOAD_LOCATION_SECONDS = 10

export const DRIVER_EVENT_TYPE = {
	CUSTOM_IMU_HARD_ACCELERATION: 'Hard acceleration',
	CUSTOM_IMU_HARD_BRAKING: 'Hard braking',
	HARD_BRAKING: 'Hard braking',
	HARD_ACCELERATION: 'Hard acceleration',
	HIGH_SPEED: 'High speed',
	ECO_DRIVING: 'Eco driving',
	IMU_ACCELERATION: 'Acceleration',
	IMU_BRAKING: 'Braking',
	IMU_TURNING: 'Turning',
	IMU_HARD_ACCELERATION: 'Hard acceleration',
	IMU_HARD_BRAKING: 'Hard braking',
	IMU_HARD_TURNING: 'Hard turning',
	RAN_STOP_SIGN: 'Ran stop sign',
	TAILGATING: 'Tailgating',
	NEAR_ACCIDENT: 'Near accident',
	SCREEN_TAP: 'Screen tap',
	FORWARD_COLLISION_ALERT: 'Forward collision alert',
	DROWSY_DRIVING_ALERT: 'Drowsy driving alert',
	DISTRACTED_DRIVING_ALERT: 'Distracted driving alert',
	SPEED_OVER_LIMIT: 'Speed over limit',
}

export const HIDDEN_DRIVER_EVENT_TYPES = new Set([
	DRIVER_EVENT_TYPE.ECO_DRIVING,
	DRIVER_EVENT_TYPE.IMU_ACCELERATION,
	DRIVER_EVENT_TYPE.IMU_BRAKING,
	DRIVER_EVENT_TYPE.IMU_TURNING,
])
