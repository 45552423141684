import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Modal,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CoreContext } from '../../../../../custom-components/context/CoreContext'
import { IconThemeContext } from '../../../../../custom-components/context/IconThemesContext'
import DoformsDefaultForm from '../../../../../custom-components/DoformsDefaultForm'
import DoformsMessage from '../../../../../custom-components/DoformsMessage'
import DoformsPortal from '../../../../../custom-components/DoformsPortal'
import LoadingSpinner from '../../../../../custom-components/LoadingSpinner'
import SkeletonLoaderDialog from '../../../../../custom-components/skeletons/SkeletonLoaderDialog'
import { ENV_ACTIONS } from '../../../../../reducers/environmentReducer'
import {
	performDispatchDatagridAction,
	performFormAction,
} from '../../../../data/datagrid/recordsHelper'
import { getAllProjects, getProjectForms } from '../../../../data/dataServices'
import { PROJECT_FORM_VIEW_TYPE } from 'components/data/datagrid/CreatViewComponents/ViewDialogUtils'

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	exitConfirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
	hidden: {
		display: 'none',
	},
}))

const DatagridSendDispatch = (props) => {
	const [t] = useTranslation('common')
	const {
		environment,
		formSelected,
		title,
		action,
		recordsLoading,
		tab,
		source,
		deviceSelected,
		setOpenSendDispatch,
		selectedDate,
		duration,
		viewType,
		openSendDispatch,
	} = props
	//const {environment} = useSelector((state) => state);
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const initialState = {
		loaded: false,
		error: null,
	}

	const [values, setValues] = useState(initialState)
	const [open, setOpen] = useState(false)
	const [dispatchForm, setDispatchForm] = useState(formSelected)
	const [exitConfirmation, setExitConfirmation] = useState(false)
	const [allProjectLoaded, setAllProjectLoaded] = useState(false)
	const [iframe, setIframe] = useState(null)

	const { loaded, error } = values
	const dispatch = useDispatch()

	useEffect(() => {
		if (viewType === PROJECT_FORM_VIEW_TYPE && allProjectLoaded) return
		if (!openSendDispatch) return
		confirmSend()
	}, [openSendDispatch, viewType, allProjectLoaded])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setValues({ ...values, error: null })
	}

	const showLoading = () => (
		<>
			<LoadingSpinner />
			<SkeletonLoaderDialog />
		</>
	)

	const confirmSend = () => {
		if (viewType === PROJECT_FORM_VIEW_TYPE) {
			if (_.isEmpty(formSelected)) return
			const dispatchFormTmp = { ...formSelected, projectKey: formSelected.projectKey }
			setValues({ ...values, loaded: false })
			performFormAction(
				environment,
				dispatchFormTmp,
				action,
				tab,
				deviceSelected,
				selectedDate,
				duration
			)
				.then((res) => {
					setIframe(res)
				})
				.catch((err) => {
					setValues({
						...values,
						error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
					})
				})
		} else {
			setValues({ ...values, loaded: false })
			performDispatchDatagridAction(
				environment,
				action,
				tab,
				deviceSelected,
				selectedDate,
				duration
			)
				.then((res) => {
					setIframe(res)
				})
				.catch((err) => {
					setValues({
						...values,
						error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
					})
				})
		}
	}

	const handleClose = (event, reason) => {
		if (
			!dispatchForm ||
			(reason !== 'backdropClick' && reason !== 'escapeKeyDown' && reason !== 'buttonClick')
		) {
			setExitConfirmation(false)
			setOpen(false)
			setValues(initialState)
			setOpenSendDispatch(false)
		} else {
			setExitConfirmation(true)
		}
	}

	const handleLoaded = (event) => {
		event.preventDefault()
		setValues({ ...values, loaded: true })
	}

	const showTitle = () => {
		return `${t('tooltip.send')} ${title}`
	}

	const showTitleForDeviceTab = () => {
		return (
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} style={{ alignItems: 'center' }}>
					<Grid item xs={12} style={{ textAlign: 'left' }}>
						{title}
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							display: 'inline-flex',
							alignItems: 'left',
							textAlign: 'left',
							paddingTop: '0px',
						}}
					>
						<Grid item xs={5}>
							<DoformsDefaultForm tab="devices" source={source} />
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const ExitConfirmationDialog = (props) => {
		const { open, onClose } = props
		const { coreValues } = useContext(CoreContext)
		const { theme } = coreValues

		const classes = useStyles(theme.topBar)

		const buttonStyle = [
			{ color: theme.icons.color },
			{
				'&:hover': {
					color: theme.icons.active.color,
				},
			},
		]

		const handleCloseRecordDialog = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
			onClose()
		}

		const handleCloseExitConfirmation = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
		}

		return (
			<div>
				<Modal
					open={open}
					onClose={handleCloseExitConfirmation}
					className={classes.exitConfirmation}
				>
					<div className={classes.paper} style={{ backgroundColor: 'white' }}>
						<p>{t('common:dialog.cancelSendDispatch')}</p>
						<Button onClick={handleCloseRecordDialog} sx={buttonStyle}>
							{t('common:misc.continue')}
						</Button>
						<Button onClick={handleCloseExitConfirmation} sx={buttonStyle}>
							{t('common:misc.cancel')}
						</Button>
					</div>
				</Modal>
			</div>
		)
	}

	useEffect(() => {
		if (viewType !== PROJECT_FORM_VIEW_TYPE) return
		if (!environment.isProjectFormsLoaded) {
			initiateLoadAllProjects()
		}
	}, [viewType, environment.isProjectFormsLoaded])

	const initiateLoadAllProjects = () => {
		setAllProjectLoaded(true)
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = _.sortBy(res.data, 'name')
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				console.log('Code ' + err.response.data.code + ': ' + err.response.data.message)
				setAllProjectLoaded(false)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const initiateLoadFormsByProject = (projects) => {
		loadFormsByProject(projects)
			.then((res) => {
				const newProjects = _.sortBy(res, 'name')
				dispatch({
					type: ENV_ACTIONS.SET_NEW_PROJECTS,
					payload: newProjects,
				})
				setAllProjectLoaded(false)
			})
			.catch((err) => {
				console.log('Code ' + err.response.data.code + ': ' + err.response.data.message)
				setAllProjectLoaded(false)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_LOADING_PROJECTS,
					payload: false,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const loadFormsByProject = async (projects) => {
		let promises = []
		for (let i = 0; i < projects.length; i++) {
			const promise = getProjectForms(projects[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { key: projects[i].key, name: projects[i].name, forms: [] }
				if (resp?.data && _.isArray(resp.data) && resp.data.length) {
					promiseObject.forms = _.sortBy(resp.data, 'name')
					dispatch({
						type: ENV_ACTIONS.GET_FORMS,
						payload: promiseObject.forms,
					})
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	return (
		<>
			<Dialog
				open={openSendDispatch}
				onClose={handleClose}
				fullWidth={true}
				maxWidth="md"
				className={classes.dialog}
				sx={{
					'& .MuiDialog-paper': {
						height: '90% !important',
					},
				}}
			>
				<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
					{tab !== 'devices' ? showTitle() : showTitleForDeviceTab()}
					<IconButton onClick={(e) => handleClose(e, 'buttonClick')}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{showErrorMessage()}
					{allProjectLoaded ? (
						<LoadingSpinner />
					) : (
						<DoformsPortal iframeSrc={iframe} onClose={handleClose} onLoaded={handleLoaded} />
					)}
				</DialogContent>
			</Dialog>
			<ExitConfirmationDialog
				onClose={(e) => handleClose(e, null)}
				onMouseDown={(e) => e.stopPropagation()}
				open={exitConfirmation}
			/>
		</>
	)
}

export default DatagridSendDispatch
