import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { toZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserTimeZone } from 'utils/functions/helpers'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { toDayjs } from 'utils/functions/doformsDateUtil'

export function validateDateRange(beginDate, endDate) {
	const beginMoment = moment.utc(beginDate)
	const endMoment = moment.utc(endDate)
	if (endMoment.isBefore(beginMoment)) return 'From date must be a day before to date.'

	if (endMoment.diff(beginMoment, 'days') > 10) return 'Maximum date range is ten days.'
	return ''
}

export default function DoformsMapFilters({
	fromDate,
	toDate,
	disableFilters,
	onFilters,
	setErrorMessage,
}) {
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)
	const displayFromDate = useMemo(() => {
		return toDayjs(fromDate)
	}, [fromDate])

	const displayToDate = useMemo(() => {
		return toDayjs(toDate)
	}, [toDate])

	const handleFromDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('fromDate', newValue)
			return
		}

		const newStringValue = newValue.toISOString()
		const errMessage = validateDateRange(newStringValue, toDate)
		if (setErrorMessage) {
			if (!isEmpty(errMessage)) {
				onFilters?.('fromDate', newStringValue)
				toast.error(errMessage)
				setErrorMessage(errMessage)
				return
			}

			setErrorMessage('')
		}

		const newDate = new Date(newStringValue)
		newDate.setHours(0, 0, 0, 0)
		const localDate = toZonedTime(newDate, getUserTimeZone())

		onFilters?.('fromDate', localDate)
	}

	const handleToDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('toDate', newValue)
			return
		}

		const newStringValue = newValue.toISOString()
		const errMessage = validateDateRange(fromDate, newStringValue)
		if (setErrorMessage) {
			if (!isEmpty(errMessage)) {
				onFilters?.('toDate', newStringValue)
				toast.error(errMessage)
				setErrorMessage(errMessage)
				return
			}

			setErrorMessage('')
		}

		const newDate = new Date(newStringValue)
		newDate.setHours(23, 59, 59, 999)
		const localDate = toZonedTime(newDate, getUserTimeZone())

		onFilters?.('toDate', localDate)
	}

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.fromDate')}
					disabled={disableFilters}
					value={displayFromDate}
					maxDate={dayjs(locale.maxDate)}
					emptyLabel=""
					onAccept={handleFromDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: 'small',
								variant: 'outlined',
								error: false,
							},
							onChange: (newValue) => {
								if (!newValue?.isValid()) return
								handleFromDateChange(newValue)
							},
						},
					}}
				/>
			</LocalizationProvider>

			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.toDate')}
					disabled={disableFilters}
					value={displayToDate}
					maxDate={dayjs(locale.maxDate)}
					emptyLabel=""
					onAccept={handleToDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: 'small',
								variant: 'outlined',
								error: false,
							},
							onChange: (newValue) => {
								if (!newValue?.isValid()) return
								handleToDateChange(newValue)
							},
						},
					}}
				/>
			</LocalizationProvider>
		</>
	)
}
