import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { activeDispatchesApi } from '../../../../../apis/disApi/viewWizard/activeDispatchesApi'

function ActiveDispatchesForm(props) {
    const {
        dashboardKeyList,
        dashboardKey,
        filterData,
        enabledFetchTileList = true,
        onFilterDataChange,
        setColumnList,
        setLoadingViewData,
        doformListWhenCreating,
    } = props

    const environment = useSelector((state) => state.environment)

    const [loading, setLoading] = useState(false)
    const [viewData, setViewData] = useState({})
    const [clientFilter, setClientFilter] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        // Fetch data from API
        // Update state with fetched data
        ; (async () => {
            setLoading(true)
            const { data } = await activeDispatchesApi.getViewData(environment.apiToken)
            setViewData(data)
            setLoading(false)
        })()
    }, [])

    return <div>Active dispatches</div>
}

export default ActiveDispatchesForm
