import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import DriverEventsForm from './DriverEventsForm'

export function DriversEventsSettingDialog(props) {
	const {
		tileElementWidth,
		defaultTileWidth,
		tile,
		open,
		eventData,
        linkedFields,
		isSubmitting,
		showStable,
		onClose,
		onSubmit,
		onResizeTileWidth,
        dashboardKeyList,
	} = props

	const { t } = useTranslation('common')

	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [filterData, setFilterData] = useState({})

	const dialogWidth = tile?.w ?? 6
	const STABLE_WIDTH = 6

	useEffect(() => {
		if (eventData) {
			setFilterData((prev) => ({ ...prev, eventData: eventData }))
		}
	}, [eventData])

    useEffect(() => {
		if (linkedFields) {
			setFilterData((prev) => ({ ...prev, linkedFields: linkedFields }))
		}
	}, [linkedFields])

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	const handleSubmit = () => {
		onSubmit?.({
			eventData: filterData.eventData,
            linkedFields: filterData.linkedFields,
		})
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={showStable ? STABLE_WIDTH : dialogWidth}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '0.9rem !important',
						},
						'& .MuiCheckbox-root': {
							p: 0,
						},
					}}
				>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							label={t('common:chart.labels')}
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>

					<Box sx={{ px: 1, mt: 2 }}>
						<DriverEventsForm
							dashboardKeyList={dashboardKeyList}
							filterData={filterData}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleSubmit}
								disabled={isSubmitting}
								startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default DriversEventsSettingDialog
