import { useEffect, useState, useMemo } from 'react'
import driverMapApi from 'apis/disApi/driverMapApi'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const useCompletedDriveLocations = (props) => {
	const { userId, completedDrives } = props
	const environment = useSelector((state) => state.environment)
	// const [cursors, setCursors] = useState({})

	const [locations, setLocations] = useState([])
	const [loading, setLoading] = useState(false)

	const [stoppedLocation, setStoppedLocations] = useState(null)

	useEffect(() => {
		if (isEmpty(completedDrives)) {
			setStoppedLocations(null)
			return
		}
		const recentLocation = locations.reduce(
			(max, loc) => (loc.timestamp > (max?.timestamp || 0) ? loc : max),
			null
		)

		setStoppedLocations(recentLocation)
	}, [completedDrives, locations])

	useEffect(() => {
		if (!userId || isEmpty(completedDrives)) {
			setLocations([])
			return
		}

		const fetchLocations = async (driveId, cursor = null, accumulatedData = []) => {
			try {
				const response = await driverMapApi.getDriveLocations({
					user_id: userId,
					drive_id: driveId,
					cursor,
					token: environment?.apiToken,
				})

				const newLocations = response?.data?.data || []
				const nextCursor = response?.data?.data?.cursor

				const updatedData = [...accumulatedData, ...newLocations]

				if (nextCursor) {
					// setCursors((prev) => ({ ...prev, [driveId]: nextCursor }))
					await fetchLocations(driveId, nextCursor, updatedData) // Gọi lại nếu có cursor
				} else {
					setLocations((prev) => [...prev, ...updatedData]) // Lưu vào state nếu không còn cursor
				}
			} catch (error) {
				console.error(`Error fetching locations for drive ${driveId}:`, error)
			}
		}

		setLoading(true)
		setLocations([])
		Promise.all(completedDrives?.map((drive) => fetchLocations(drive.id)))
			.then(() => setLoading(false))
			.finally(() => setLoading(false))
	}, [userId, completedDrives, environment?.apiToken])

	return { allCompletedLocation: locations, stoppedLocation, isLoadingCompletedLocations: loading }
}

export const useActiveDriveLocations = (props) => {
	const { userId, activeDrives, repeatReloadMapSeconds } = props
	const environment = useSelector((state) => state.environment)

	const [locationsByDriveId, setLocationsByDriveId] = useState({})
	const [loading, setLoading] = useState(false)
	const [allLocation, setAllLocation] = useState([])
	const [currentDrivesLocation, setCurrentDriveLocations] = useState([])

	useEffect(() => {
		if (isEmpty(locationsByDriveId)) return
		const locationValues = Object.values(locationsByDriveId)
		const locations = locationValues.flat()
		setAllLocation(locations)

		const lastOrderedLocations = locationValues
			.map((locations) => locations.sort((a, b) => a.timestamp - b.timestamp).at(-1)) // Get last item from each sorted array
			.flat()
		setCurrentDriveLocations(lastOrderedLocations || [])
	}, [locationsByDriveId])

	useEffect(() => {
		if (!userId || isEmpty(activeDrives)) {
			setLocationsByDriveId({})
			return
		}

		let isMounted = true

		const fetchLocations = async (driveId, cursor = null, accumulatedData = []) => {
			try {
				const response = await driverMapApi.getDriveLocations({
					user_id: userId,
					drive_id: driveId,
					cursor,
					token: environment?.apiToken,
				})

				const newLocations = response?.data?.data || []
				const nextCursor = response?.data?.data?.cursor

				const updatedData = [...accumulatedData, ...newLocations]

				if (nextCursor) {
					await fetchLocations(driveId, nextCursor, updatedData) // Fetch next page recursively
				} else if (isMounted) {
					setLocationsByDriveId((prev) => ({
						...prev,
						[driveId]: [...(prev[driveId] || []), ...updatedData], // Append new data to existing driveId
					}))
				}
			} catch (error) {
				console.error(`Error fetching locations for drive ${driveId}:`, error)
			}
		}

		const fetchAllDrives = async () => {
			if (!isMounted) return
			setLoading(true)
			setLocationsByDriveId({})
			await Promise.all(activeDrives.map((drive) => fetchLocations(drive.id)))

			if (isMounted) setLoading(false)
		}

		// Initial fetch
		fetchAllDrives()

		// Set interval for auto-refresh
		const intervalId = setInterval(fetchAllDrives, repeatReloadMapSeconds * 1000)

		return () => {
			isMounted = false // Prevents state updates after unmount
			clearInterval(intervalId) // Cleanup interval on unmount
		}
	}, [userId, activeDrives, repeatReloadMapSeconds, environment?.apiToken])

	return {
		allActiveLocation: allLocation,
		currentDrivesLocation: currentDrivesLocation,
		isLoadingActiveLocations: loading,
	}
}

export function useDriveLocation(props) {
	const { userId, drive, shouldRefetchInterval = false, repeatReloadMapSeconds } = props
	const environment = useSelector((state) => state.environment)
	const queryKey = useMemo(() => {
		if (!userId || isEmpty(drive) || !drive.id) return null

		return ['getDriveLocations', userId, drive.id, shouldRefetchInterval]
	}, [userId, drive, shouldRefetchInterval])

	const { data, isFetching } = useQuery({
		queryKey,
		queryFn: () =>
			driverMapApi.getDriveLocations({
				user_id: userId,
				drive_id: drive.id,
				token: environment?.apiToken,
			}),
		enabled: !!queryKey,
		refetchInterval: !shouldRefetchInterval ? false : repeatReloadMapSeconds * 1000,
	})

	return {
		data: data?.data?.data,
		isFetching,
	}
}
