import { useState, useContext, useEffect, useMemo, memo, useReducer, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'

import { InputLabel, FormControl, Select, MenuItem, Button, Stack } from '@mui/material'
import CollapseComponent from './CollapseComponent'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'

import {
	DATA_OBJ_ACTION_TYPES,
	SETTING_TYPES,
	dataObjectReducer,
	shouldPreventRender,
} from '../ViewDialogUtils'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({}))

const GroupTheDataComponent = ({
	rowGroupingModel,
	listActiveFields,
	listActiveFieldsOptions,
	saveSettingDispatch,
	expandedAccordion,
	setExpandedAccordion,
}) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [value, setValue] = useState('')
	const title = useMemo(() => t('common:view.groupTheData'), [t])

	// group collapse
	const [groupTheData, groupTheDataDispatch] = useReducer(dataObjectReducer, [])

	useEffect(() => {
		if (isEmpty(listActiveFieldsOptions) || isEmpty(rowGroupingModel)) return

		groupTheDataDispatch({
			type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
			initData: rowGroupingModel,
		})
	}, [listActiveFieldsOptions, rowGroupingModel])

	useEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.GROUP_THE_DATA,
			value: groupTheData || [],
		})

		if (isEmpty(groupTheData)) {
			setValue('')
			return
		}
		setValue(groupTheData.join(', '))
	}, [groupTheData])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<div>
					{!isEmpty(listActiveFieldsOptions) &&
						groupTheData.map((item, index) => (
							<div key={index}>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									style={{ justifyContent: 'center', marginBottom: '10px', alignItems: 'baseline' }}
								>
									<FormControl variant="standard" fullWidth>
										<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
											Field
										</InputLabel>
										<Select
											defaultValue={item}
											value={item}
											onChange={(e) => {
												groupTheDataDispatch({
													type: DATA_OBJ_ACTION_TYPES.UPDATE,
													target: {
														index: index,
														value: e.target.value,
													},
												})
											}}
											inputProps={{
												name: 'age',
												id: 'uncontrolled-native',
											}}
										>
											{listActiveFieldsOptions?.map((dataOptionItem) => (
												<MenuItem value={dataOptionItem.name}>{dataOptionItem.title}</MenuItem>
											))}
										</Select>
									</FormControl>
									<div>
										<Button
											aria-label="delete"
											size="small"
											variant="contained"
											sx={{
												borderRadius: '50%',
												width: '25px !important',
												minWidth: '25px !important',
												height: '25px !important',
											}}
											onClick={(e) => {
												groupTheDataDispatch({
													type: DATA_OBJ_ACTION_TYPES.DELETE,
													target: {
														index,
													},
												})
											}}
										>
											<Delete fontSize="small" />
										</Button>
									</div>
								</Stack>
							</div>
						))}
					<div>
						<Button
							variant="text"
							className={classes.rightButtonSort}
							onClick={(e) => {
								groupTheDataDispatch({
									type: DATA_OBJ_ACTION_TYPES.ADD,
									target: {
										defaultItem: listActiveFields[0] || '',
									},
								})
							}}
						>
							+ Add group
						</Button>
					</div>
				</div>
			}
		/>
	)
}

export const GroupTheDataCollapse = memo(GroupTheDataComponent, shouldPreventRender)
