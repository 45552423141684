import { useMutation, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import TileWrapper from '../../components/TileWrapper'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import { GeoMapTileContent } from './geotab-map/GeoMapTileContent'
import { DriverMapTileContent } from './driver-map/DriverMapTileContent'
import { MAP_GPS_SOURCE_TYPE } from './helper/constants'
import { isEmpty } from 'lodash'
import { useTileDashboard } from '../../dashboard/Dashboard'

function MapTile({ tile }) {
	const environment = useSelector((state) => state.environment)
	const queryClient = useQueryClient()
	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const {
		locationData = {},
		showFilters = true,
		showHistory = true,
		pointOfInterest = false,
		linkedFields = {},
		repeatReloadMapSeconds,
		gpsSource,
		userEmail,
	} = settings

	const formMapRef = useRef(null)

	const gpsType = useMemo(() => {
		if (isEmpty(gpsSource)) return MAP_GPS_SOURCE_TYPE.GEOTAB_ADDINS

		return gpsSource
	}, [gpsSource])

	const [settingsOpen, setSettingsOpen] = useState(false)
	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const updateTile = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleSubmit = async (data) => {
		try {
			const {
				locationData,
				showFilters,
				showHistory,
				pointOfInterest,
				linkedFields,
				repeatReloadMapSeconds,
				gpsSource,
				userEmail,
			} = data
			const { locationData: locationFromSettings, ...rest } = settings
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...rest,
						showFilters: showFilters,
						showHistory: showHistory,
						pointOfInterest: pointOfInterest,
						repeatReloadMapSeconds,
						locationData: {
							...locationFromSettings,
							...locationData,
						},
						linkedFields,
						gpsSource,
						userEmail,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={formMapRef}
				hidden={false} // {!isLoggedIn}
			>
				{gpsType === MAP_GPS_SOURCE_TYPE.GEOTAB_ADDINS && (
					<GeoMapTileContent
						tile={tile}
						formMapRef={formMapRef}
						updateTile={updateTile}
						settingsOpen={settingsOpen}
						setSettingsOpen={setSettingsOpen}
						handleCloseDialog={handleCloseDialog}
						handleResizeTileWidth={handleResizeTileWidth}
						handleSubmit={handleSubmit}
						showFilters={showFilters}
						repeatReloadMapSeconds={repeatReloadMapSeconds}
						showHistory={showHistory}
						pointOfInterest={pointOfInterest}
						locationData={locationData}
						linkedFields={linkedFields}
						dashboardKeyList={dashboardKeyList}
					/>
				)}
				{gpsType === MAP_GPS_SOURCE_TYPE.DRIVER_TECHNOLOGIES && (
					<DriverMapTileContent
						tile={tile}
						formMapRef={formMapRef}
						updateTile={updateTile}
						settingsOpen={settingsOpen}
						setSettingsOpen={setSettingsOpen}
						handleCloseDialog={handleCloseDialog}
						handleResizeTileWidth={handleResizeTileWidth}
						handleSubmit={handleSubmit}
						showFilters={showFilters}
						repeatReloadMapSeconds={repeatReloadMapSeconds}
						showHistory={showHistory}
						pointOfInterest={pointOfInterest}
						locationData={locationData}
						linkedFields={linkedFields}
						dashboardKeyList={dashboardKeyList}
						gpsSource={gpsSource}
						userEmail={userEmail}
					/>
				)}
			</TileWrapper>
		</>
	)
}

export default MapTile
