import { useEffect, useState, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'

import { Box } from '@mui/material'
import { DataGridPremium, useGridApiRef, getGridDateOperators } from '@mui/x-data-grid-premium'
import { DateTimeInputValue } from 'components/data/dataHelpers'
import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import TileWrapper from '../../components/TileWrapper'
import DriversEventsSettingDialog from './DriverEventsSettingDialog'

import tileApi from 'apis/disApi/tileApi'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { useDriverEmailQuery } from '../map/driver-map/hook/useDriverEmailQuery'
import { useDrives } from '../map/driver-map/hook/useDrives'
import { useDriverEventQuery } from './hook/useDriverEventQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { getAutoUpdateFilters } from '../../helpers'
import { convertArrayToObject } from '../map/helper/functions'

import { validateDateTimeFilter, checkByMasterDateTimeCondition } from 'utils/functions/helpers'
import { parseAs } from 'utils/functions/doformsDateUtil'

const COLUMN_NAME = {
	FROM_DATE: 'fromDate',
	TO_DATE: 'toDate',
	USER_EMAIL: 'userEmail',
}

export default function DriverEventsTile(props) {
	const { tile } = props
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const queryClient = useQueryClient()
	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})
	const tileRef = useRef(null)
	const apiRef = useGridApiRef()

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { eventData, linkedFields } = useMemo(() => settings, [settings])

	const [userEmail, setUserEmail] = useState('')
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')

	const [settingsOpen, setSettingsOpen] = useState(false)

	useEffect(() => {
		if (isEqual(userEmail, eventData?.userEmail)) return
		setUserEmail(eventData?.userEmail)
	}, [eventData?.userEmail])

	useEffect(() => {
		setFromDate(moment.utc(eventData?.fromDate).unix())
	}, [eventData?.fromDate])

	useEffect(() => {
		// If To date is empty, use now
		if (!eventData?.toDate) {
			setToDate(moment.utc().unix())
			return
		}
		setToDate(moment.utc(eventData?.toDate).unix())
	}, [eventData?.toDate])

	const { selectedFields, linkedTitle } = useTileDashboard()

	const { filterConfigs, hasPrimaryKey, conditions } = useMemo(
		() => getAutoUpdateFilters(settings?.linkedFields, selectedFields, dashboardKeyList),
		[settings?.linkedFields, selectedFields, dashboardKeyList]
	)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	const conditionsWithoutId = useMemo(() => {
		const result = {}
		for (const key in conditions) {
			const value = conditions[key]
			const { id, ...rest } = value
			result[key] = rest
		}
		return result
	}, [conditions])

	useEffect(() => {
		if (hasPrimaryKey) {
			const newSettings = convertArrayToObject(filterWithoutId)

			const shouldSetEmail = Object.prototype.hasOwnProperty.call(newSettings, 'userEmail')
			const shouldSetFromDate = Object.prototype.hasOwnProperty.call(newSettings, 'fromDate')
			const shouldSetToDate = Object.prototype.hasOwnProperty.call(newSettings, 'toDate')

			const valueToCheck = {
				userEmail: shouldSetEmail ? newSettings.userEmail : eventData?.userEmail,
				fromDate: shouldSetFromDate ? newSettings.fromDate : eventData?.fromDate,
				toDate: shouldSetToDate ? newSettings.toDate : eventData?.toDate,
			}

			if (!isEmpty(conditionsWithoutId)) {
				Object.values(COLUMN_NAME).forEach((key) => {
					const condition = conditionsWithoutId[key]
					if (isEmpty(condition)) return

					const dateValue = valueToCheck[key]
					const isValid = checkByMasterDateTimeCondition(
						dateValue,
						condition,
						condition?.keyData === 'Master_DateTime'
					)
					if (!isValid) {
						valueToCheck[key] = ''
					}
				})
			}

			if (!isEqual(valueToCheck.userEmail, userEmail)) {
				setUserEmail(valueToCheck.userEmail)
			}

			let startUnix = moment.utc(valueToCheck.fromDate).unix()
			if (!isEqual(startUnix, fromDate)) {
				setFromDate(startUnix)
			}

			let toUnix = moment.utc(valueToCheck.toDate).unix()
			if (!isEqual(toUnix, toDate)) {
				setToDate(toUnix)
			}
		} else {
			setUserEmail(eventData?.userEmail)
			setFromDate(moment.utc(eventData?.fromDate).unix())
			setToDate(moment.utc(eventData?.toDate).unix())
		}
	}, [JSON.stringify(filterWithoutId), JSON.stringify(conditionsWithoutId), hasPrimaryKey])

	const { driverInfoByEmail, isLoadingUser } = useDriverEmailQuery({
		userEmail,
	})

	const userId = useMemo(() => {
		if (isLoadingUser || !userEmail || isEmpty(driverInfoByEmail)) return null

		if (!isEqual(userEmail, driverInfoByEmail.email)) return null

		return driverInfoByEmail.id
	}, [userEmail, isLoadingUser, driverInfoByEmail])

	// Fetch drives
	const { allDrives, isLoadingDrives: isLoadingAllDrives } = useDrives({
		userId: userId,
		fromDate,
		toDate,
		showHistory: true,
	})

	const columns = useMemo(() => {
		const currentUserOffset = environment.userCurrent.time.timezone.offset || 0
		let fullDateTimeFormat = 'MM/DD/YYYY hh:mm:ss A'
		const filterOperas = getGridDateOperators().map((operator) => {
			return {
				...operator,
				getApplyFilterFn: (filterItem, column) => {
					if (!filterItem.field || !filterItem.operator) {
						return null
					}

					return (value, row, column, apiRef) => {
						return validateDateTimeFilter(value, filterItem, column.type)
					}
				},
				InputComponent: operator.InputComponent ? DateTimeInputValue : undefined,
				InputComponentProps: { type: 'dateTime' },
			}
		})
		return [
			{
				field: 'start_timestamp',
				headerName: 'Date',
				type: 'dateTime',
				flex: 1,
				headerAlign: 'left',
				align: 'left',
				filterOperators: filterOperas,
				valueGetter: (value) => {
					if (!value) {
						return ''
					}

					return moment(value).utcOffset(currentUserOffset)
				},
				valueFormatter: (value) => {
					if (!value) {
						return ''
					}
					return !value ? '' : value.format(fullDateTimeFormat)
				},
			},
			{
				field: 'type',
				headerName: 'Type',
				type: 'string',
				flex: 1,
				headerAlign: 'left',
				align: 'left',
			},
		]
	}, [environment])

	const { allEvents, isLoadingAllEvents } = useDriverEventQuery({
		userId: isLoadingUser ? null : driverInfoByEmail?.id,
		drives: isLoadingAllDrives ? [] : allDrives,
	})

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { eventData, linkedFields } = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						eventData,
						linkedFields,
					}),
				},
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<DriversEventsSettingDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={300}
					tile={tile}
					eventData={eventData}
					linkedFields={linkedFields}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					dashboardKeyList={dashboardKeyList}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<DataGridPremium
						sx={{
							'& .MuiDataGrid-footerContainer': {
								minHeight: '40px !important',
							},
						}}
						columns={columns}
						rows={allEvents}
						loading={isLoadingUser || isLoadingAllDrives || isLoadingAllEvents}
						apiRef={apiRef}
						defaultGroupingExpansionDepth={-1}
						rowHeight={25}
						columnHeaderHeight={28}
						getRowId={(row) => row.id}
					/>
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}
