import { InputLabel, FormControl, Select, MenuItem } from '@mui/material'
import { isEmpty, isEqual } from 'lodash'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { MAP_GPS_SOURCE_TYPE } from './helper/constants'
import GeoMapForm from './geotab-map/GeoMapForm'
import { DriverMapForm } from './driver-map/DriverMapForm'

const sourceTypeOption = Object.values(MAP_GPS_SOURCE_TYPE).map((value) => {
	const option = { label: value, value: value }
	if (value === MAP_GPS_SOURCE_TYPE.GEOTAB_ADDINS) {
		option.label = 'Geotab'
	}
	return option
})

const MapForm = ({
	vehicleLoading,
	filterData,
	vehicleList,
	linkedFields,
	dashboardKeyList,
	onFilterDataChange,
}) => {
	const { t } = useTranslation('common')

	const [gpsSourceType, setGPSSourceType] = useState('')

	function handleGPSSourceChange(e) {
		const newGPSSource = e.target.value
		onFilterDataChange('gpsSource', newGPSSource)
	}

	useEffect(() => {
		// support old data
		if (isEmpty(filterData.gpsSource)) {
			onFilterDataChange('gpsSource', MAP_GPS_SOURCE_TYPE.GEOTAB_ADDINS)
			return
		}
	}, [])

	useEffect(() => {
		if (isEmpty(filterData.gpsSource) || isEqual(gpsSourceType, filterData.gpsSource)) return

		setGPSSourceType(filterData.gpsSource)
	}, [filterData.gpsSource])

	return (
		<>
			<FormControl variant="outlined" size="small" fullWidth>
				<InputLabel id="gps-source-label">GPS source</InputLabel>
				<Select
					id="gps-source-select"
					labelId={'gps-source-label'}
					value={gpsSourceType}
					label={'GPS source'}
					onChange={handleGPSSourceChange}
				>
					{sourceTypeOption.map((item) => (
						<MenuItem value={item.value}>{item.label}</MenuItem>
					))}
				</Select>
			</FormControl>
			{filterData.gpsSource === MAP_GPS_SOURCE_TYPE.GEOTAB_ADDINS && (
				<GeoMapForm
					vehicleList={vehicleList}
					vehicleLoading={vehicleLoading}
					filterData={filterData}
					linkedFields={linkedFields}
					dashboardKeyList={dashboardKeyList}
					onFilterDataChange={(id, value) => {
						onFilterDataChange(id, value)
					}}
				/>
			)}
			{filterData.gpsSource === MAP_GPS_SOURCE_TYPE.DRIVER_TECHNOLOGIES && (
				<DriverMapForm
					filterData={filterData}
					linkedFields={linkedFields}
					dashboardKeyList={dashboardKeyList}
					onFilterDataChange={(id, value) => {
						onFilterDataChange(id, value)
					}}
				/>
			)}
		</>
	)
}

export default MapForm
