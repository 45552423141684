import { Fullscreen, Settings } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MARKET_PLACE, RIGHT_SIDE_MENU_LIST } from 'components/core/Layouts/DisLayout'
import { CoreContext } from 'custom-components/context/CoreContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
	menuAppbar: (props) => ({
		background: props.backgroundColor,
		minHeight: '30px',
		border: 'none',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 0,
		marginBottom: 0,
		listStyle: 'none',
	}),
	icon: (props) => ({
		color: props.color,
	}),
	menuNavLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 1rem',
		color: props.color,
		'&:hover': {
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
			cursor: 'pointer',
		},
	}),
	menuNavIconLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 0.5rem',
		color: props.isActive ? props.active.color : props.color,
		backgroundColor: props.isActive ? props.active.backgroundColor : undefined,

		'&:hover, &.isActive': {
			cursor: 'pointer',
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
		},
	}),
}))

const PreviewNav = ({ menuItems, menuSettings }) => {
	const [t] = useTranslation('common')
	const { coreValues } = useContext(CoreContext)

	const { theme } = coreValues
	const menuBarTheme = theme.menuBar
	const classes = useStyles(menuBarTheme)

	return (
		<Box sx={{ '& li': { listStyle: 'none' } }}>
			<Stack
				className={classes.menuAppbar}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box
					component="ul"
					sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 0, m: 0 }}
				>
					{menuItems.map((menuItem, index) => (
						<li key={index} className="nav-item">
							<span className={classes.menuNavLink}>{menuItem.name}</span>

							{menuItem?.items?.length > 0 && (
								<ul
									className="menu-dropdown-list"
									style={{
										backgroundColor: menuBarTheme.backgroundColor,
									}}
								>
									{menuItem?.items?.map((child, childIndex) => {
										return (
											<li key={childIndex} className="nav-item">
												<span className={classes.menuNavLink}>{child.name}</span>
											</li>
										)
									})}
								</ul>
							)}
						</li>
					))}
				</Box>

				<Box
					component="ul"
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						mb: 0,
					}}
				>
					{menuSettings.includes('fullScreen') && (
						<li className="nav-item">
							<div className={classes.menuNavIconLink}>
								<Fullscreen className={classes.icon} />
							</div>
						</li>
					)}
				
					{RIGHT_SIDE_MENU_LIST.map((menu, index) => {
						const Icon = menu.icon
						return (
							<li hidden={!menuSettings.includes(menu.name)} className="nav-item" key={index}>
								<div className={classes.menuNavIconLink}>
									<Icon className={classes.icon} />
								</div>
							</li>
						)
					})}
						{menuSettings.includes(MARKET_PLACE) && (
						<li className="nav-item">
							<div className={classes.menuNavIconLink}>
								<Settings className={classes.icon} />
							</div>
						</li>
					)}
				</Box>
			</Stack>
		</Box>
	)
}

export default PreviewNav
