import { API, RENDER } from '../config'
import _ from 'lodash'

const READ_ONLY_PERMISSION = ['read']
export const ENV_ACTIONS = {
	INITIALIZED_ENVIRONMENT: 'INITIALIZED_ENVIRONMENT',
	CORE_DATA_LOAD: 'CORE_DATA_LOAD',
	LINKS: 'LINKS',
	MAIN_CONTENTS: 'MAIN_CONTENTS',
	GET_USER: 'GET_USER',
	GET_USER_CURRENT: 'GET_USER_CURRENT',
	GET_ACCOUNT: 'GET_ACCOUNT',
	GET_PROJECTS: 'GET_PROJECTS',
	GET_LOOKUPS: 'GET_LOOKUPS',
	GET_FORMS: 'GET_FORMS',
	GET_PROJECT_FORMS: 'GET_PROJECT_FORMS',
	GET_LOOKUPS_FORMS: 'GET_LOOKUPS_FORMS',
	GET_OWNERS: 'GET_OWNERS',
	GET_DEVICES: 'GET_DEVICES',
	GET_TEAMS: 'GET_TEAMS',
	GET_REPORTS: 'GET_REPORTS',
	GET_THEME: 'GET_THEME',
	GET_TRANSLATIONS: 'GET_TRANSLATIONS',
	GET_VIEWS: 'GET_VIEWS',
	GET_WIZARD: 'GET_WIZARD',
	ADD_NEW_VIEW: 'ADD_NEW_VIEW',
	SET_NEW_PROJECTS: 'SET_NEW_PROJECTS',
	HAS_NEW_VIEW: 'HAS_NEW_VIEW',
	UPDATE_DEFAULT_FORM: 'UPDATE_DEFAULT_FORM',
	REMOVE_FROM_VIEW: 'REMOVE_FROM_VIEW',
	REFRESH: 'REFRESH',
	AUTO_REFRESH_UPDATE: 'AUTO_REFRESH_UPDATE',
	FORMS_TAB_LOADED: 'FORMS_TAB_LOADED',
	DISPATCH_FORM: 'DISPATCH_FORM',
	DEVICE_AUDIT_FORM: 'DEVICE_AUDIT_FORM',
	IS_LOGGEDIN: 'IS_LOGGEDIN',
	IS_LOADED: 'IS_LOADED',
	IS_PROJECT_FORMS_LOADED: 'IS_PROJECT_FORMS_LOADED',
	IS_LOADING_PROJECTS: 'IS_LOADING_PROJECTS',
	SIGN_OUT: 'SIGN_OUT',
	CLEAR_DEVICES: 'CLEAR_DEVICES',
	CLEAR_FORMS: 'CLEAR_FORMS',
	CLEAR_LOOKUPS: 'CLEAR_LOOKUPS',
	CLEAR_REPORTS: 'CLEAR_REPORTS',
	CLEAR_VIEWS: 'CLEAR_VIEWS',
	CHART_DS: 'CHART_DS',
	TOGGLE_FULLSCREEN_MODE: 'TOGGLE_FULLSCREEN_MODE',
	TOGGLE_LEFT_PANEL: 'TOGGLE_LEFT_PANEL',
	FORM_COLUMNS_CHANGED: 'FORM_COLUMNS_CHANGED',
	SHOW_CREATE_SUBMISSION: 'SHOW_CREATE_SUBMISSION',
	SHOW_SEND_DISPATCH: 'SHOW_SEND_DISPATCH',
	SHOW_ADD_VIEW: 'SHOW_ADD_VIEW',
	SHOW_EDIT_VIEW: 'SHOW_EDIT_VIEW',
}

const initialState = {
	apiHost: `${API}`,
	renderHost: `${RENDER}`,
	apiToken: '',
	isAuthenticated: false,
	isLoggedIn: false,
	isInitialized: false,
	isLoaded: false,
	isLoading: false,
	isProjectFormsLoaded: false,
	isLoadingProjects: false,
	hasNewView: false,
	links: [],
	settings: {},
	account: {},
	devices: [],
	features: {},
	forms: [],
	lookups: [],
	mainContents: [],
	projectForms: [],
	lookupsForms: [],
	projectLookups: [],
	projects: [],
	projectsLoaded: false,
	dispatchForm: {},
	deviceAuditForm: {},
	teams: [],
	theme: {},
	iconsTheme: [],
	translations: {},
	refresh: false,
	refreshTile: false,
	formsTabLoaded: false,
	user: {},
	userCurrent: {},
	wizardAccess: {},
	owners: [],
	views: [],
	reports: [],
	fullscreenMode: false,
	showLeftPanel: true,
	tab: '',
	formColumnsChanged: [],
	showCreateSubmission: false,
	showSendDispatch: false,
	showSendDispatchRefresh: false,
	showAddView: false,
	showEditView: false,
	isReadOnlySubmission: false,
	isReadOnlyDispatch: false,
}

const environmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case ENV_ACTIONS.INITIALIZED_ENVIRONMENT: {
			return {
				...state,
				isInitialized: true,
			}
		}
		case ENV_ACTIONS.CORE_DATA_LOAD: {
			const data = action.payload
			if (data.environment != null) {
				return { ...data.environment }
			} else {
				const account = data?.account || {}
				const theme = data?.theme || {}
				const userCurrent = data?.userCurrent || {}
				const devices = data?.devices || {}
				const owners = data?.owners || []
				const translations = data?.translations || {}
				const links = data?.links || []
				const settings = data?.settings || {}

				const submissionPermissions = data?.userCurrent?.rights?.submission
				const isReadOnlySubmission = _.isEmpty(submissionPermissions) || _.isEqual(READ_ONLY_PERMISSION, submissionPermissions)

				const dispatchPermissions = data?.userCurrent?.rights?.dispatch
				const isReadOnlyDispatch = _.isEmpty(dispatchPermissions) || _.isEqual(READ_ONLY_PERMISSION, dispatchPermissions)
				return {
					...state,
					account: account,
					theme: theme,
					userCurrent: userCurrent,
					dispatchForm: userCurrent?.projectForm ? userCurrent.projectForm : {},
					devices: devices,
					owners: owners,
					translations: translations,
					links: links,
					settings: settings,
					iconsTheme: [
						{
							color: theme?.icons?.color ? theme?.icons?.color : 'black',
						},
						{
							'&:hover': {
								color: theme?.icons?.active ? theme?.icons?.active?.color : 'black',
								backgroundColor: 'transparent',
							},
						},
					],
					isLoaded: true,
					isReadOnlySubmission: isReadOnlySubmission,
					isReadOnlyDispatch: isReadOnlyDispatch
				}
			}
		}
		case ENV_ACTIONS.LINKS: {
			return {
				...state,
				links: action.payload,
			}
		}
		case ENV_ACTIONS.GET_ACCOUNT: {
			return {
				...state,
				account: action.payload,
			}
		}
		case ENV_ACTIONS.GET_THEME: {
			return {
				...state,
				theme: action.payload,
			}
		}
		case ENV_ACTIONS.GET_TEAMS: {
			return {
				...state,
				teams: action.payload,
			}
		}
		case ENV_ACTIONS.GET_DEVICES: {
			return {
				...state,
				devices: _.union(state.devices, action.payload),
			}
		}
		case ENV_ACTIONS.GET_PROJECTS: {
			return {
				...state,
				// projects: [...state.projects, action.payload]
				projects: action.payload,
			}
		}
		case ENV_ACTIONS.GET_LOOKUPS: {
			return {
				...state,
				lookups: action.payload,
				// lookups: [...state.lookups, action.payload]
				// lookups: state.lookups.concat(action.payload),
			}
		}
		case ENV_ACTIONS.GET_FORMS: {
			return {
				...state,
				forms: _.union(state.forms, action.payload),
			}
		}
		case ENV_ACTIONS.GET_VIEWS: {
			return {
				...state,
				views: _.union(state.views, action.payload),
			}
		}
		case ENV_ACTIONS.ADD_NEW_VIEW: {
			const view = action.payload
			const newOwners = state.owners.reduce((r, { key, name, views }) => {
				if (key === view.ownerKey) {
					r.push({ key, name, views: [...views, view] })
				}
				return r
			}, [])
			return {
				...state,
				owners: newOwners,
				views: _.union(state.views, view),
			}
		}
		case ENV_ACTIONS.GET_PROJECT_FORMS: {
			return {
				...state,
				// projectForms: [...state.projectForms, action.payload]
				projectForms: _.union(state.projectForms, action.payload),
			}
		}
		case ENV_ACTIONS.GET_LOOKUPS_FORMS: {
			return {
				...state,
				lookupsForms: [...state.lookupsForms, action.payload],
			}
		}
		case ENV_ACTIONS.GET_USER: {
			const { user } = action.payload

			return {
				...state,
				user: user,
				apiToken: user.token,
				isAuthenticated: true,
				isLoggedIn: true,
			}
		}
		case ENV_ACTIONS.GET_USER_CURRENT: {
			const userCurrent = action.payload
			return {
				...state,
				userCurrent: userCurrent,
				deviceAuditForm: userCurrent.projectForm ? userCurrent.projectForm : {},
				dispatchForm: userCurrent.projectForm ? userCurrent.projectForm : {},
			}
		}
		case ENV_ACTIONS.UPDATE_DEFAULT_FORM: {
			const userCurrent = action.payload
			return {
				...state,
				userCurrent: userCurrent,
			}
		}
		case ENV_ACTIONS.GET_OWNERS: {
			return {
				...state,
				owners: action.payload,
			}
		}
		case ENV_ACTIONS.GET_REPORTS: {
			return {
				...state,
				reports: action.payload,
			}
		}
		case ENV_ACTIONS.HAS_NEW_VIEW: {
			return {
				...state,
				hasNewView: action.payload,
			}
		}
		case ENV_ACTIONS.REMOVE_FROM_VIEW: {
			const view = action.payload
			const newViews = state.views.filter((item) => item.key !== view.key)
			return {
				...state,
				views: newViews,
			}
		}
		case ENV_ACTIONS.GET_TRANSLATIONS: {
			return {
				...state,
				translations: action.payload,
			}
		}
		case ENV_ACTIONS.DISPATCH_FORM: {
			return {
				...state,
				dispatchForm: action.payload,
			}
		}
		case ENV_ACTIONS.DEVICE_AUDIT_FORM: {
			return {
				...state,
				deviceAuditForm: action.payload,
			}
		}
		case ENV_ACTIONS.MAIN_CONTENTS: {
			if (!state.mainContents.length) return
			const content = {
				tab: action.payload.tab,
				content: action.payload.content,
			}
			const found = state.mainContents.findIndex((item) => item.tab === action.payload.tab)
			if (found > -1) {
				let temp = state.mainContents
				temp[found] = content
				return {
					...state,
					mainContents: temp,
				}
			} else {
				return {
					...state,
					mainContents: [...state.mainContents, content],
				}
			}
		}
		case ENV_ACTIONS.SET_NEW_PROJECTS: {
			const projects = action.payload
			const formProjects = projects.filter((project) => project.name.toLowerCase() !== 'lookups')
			const lookupProjects = projects.filter((project) => project.name.toLowerCase() === 'lookups')
			return {
				...state,
				projects: formProjects,
				lookups: lookupProjects,
			}
		}
		case ENV_ACTIONS.REFRESH: {
			return {
				...state,
				refresh: action.payload,
			}
		}
		case ENV_ACTIONS.AUTO_REFRESH_UPDATE: {
			return {
				...state,
				userCurrent: {
					...state.userCurrent,
					autoRefresh: action.payload,
				},
			}
		}
		case ENV_ACTIONS.FORMS_TAB_LOADED: {
			return {
				...state,
				formsTabLoaded: action.payload,
			}
		}
		case ENV_ACTIONS.IS_LOADED: {
			return {
				...state,
				isLoaded: action.payload,
			}
		}
		case ENV_ACTIONS.IS_PROJECT_FORMS_LOADED: {
			return {
				...state,
				isProjectFormsLoaded: action.payload,
			}
		}
		case ENV_ACTIONS.IS_LOADING_PROJECTS: {
			return {
				...state,
				isLoadingProjects: action.payload,
			}
		}
		case ENV_ACTIONS.CLEAR_DEVICES: {
			return {
				...state,
				teams: [],
				devices: [],
			}
		}
		case ENV_ACTIONS.CLEAR_FORMS: {
			return {
				...state,
				isProjectFormsLoaded: false,
				projects: [],
				forms: [],
			}
		}
		case ENV_ACTIONS.CLEAR_LOOKUPS: {
			return {
				...state,
				lookups: [],
			}
		}
		case ENV_ACTIONS.CLEAR_REPORTS: {
			return {
				...state,
				reports: [],
			}
		}
		case ENV_ACTIONS.CLEAR_VIEWS: {
			return {
				...state,
				owners: [],
				views: [],
			}
		}
		case ENV_ACTIONS.SIGN_OUT: {
			sessionStorage.removeItem('displayDefaultMenu')
			return initialState
		}
		case ENV_ACTIONS.CHART_DS: {
			return {
				...state,
				chartDS: action.payload,
			}
		}
		case ENV_ACTIONS.TOGGLE_FULLSCREEN_MODE: {
			return {
				...state,
				fullscreenMode: action.payload,
				showLeftPanel: !action.payload,
			}
		}
		case ENV_ACTIONS.TOGGLE_LEFT_PANEL: {
			return {
				...state,
				showLeftPanel: action.payload,
			}
		}
		case ENV_ACTIONS.FORM_COLUMNS_CHANGED: {
			return {
				...state,
				formColumnsChanged: action.payload,
			}
		}
		case ENV_ACTIONS.SHOW_CREATE_SUBMISSION: {
			return {
				...state,
				showCreateSubmission: action.payload,
			}
		}
		case ENV_ACTIONS.SHOW_SEND_DISPATCH: {
			return {
				...state,
				showSendDispatch: action.payload,
				showSendDispatchRefresh: !state.showSendDispatchRefresh,
			}
		}
		case ENV_ACTIONS.SHOW_ADD_VIEW: {
			return {
				...state,
				showAddView: action.payload,
			}
		}
		case ENV_ACTIONS.SHOW_EDIT_VIEW: {
			return {
				...state,
				showEditView: action.payload,
			}
		}
		default:
			return state
	}
}

export default environmentReducer
