import axios from 'axios'
import { apiUrl } from '../../apiUrl'

/* Cancel request */
const CancelTokenLoadNextRecordsQuery = axios.CancelToken
let cancelLoadNextRecordsQuery

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

export const feedViewApi = {
	getViewData(token, cancelToken) {
		return axios.get(`${url}/feed/view`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	getColumns(token, cancelToken) {
		return axios.get(`${url}/feed/view/columns`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	getRecords(viewKey, viewSession, filter, token) {
		const config = {
			headers: apiHeader(token),
			cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
				cancelLoadNextRecordsQuery = c
			}),
		}
		let bodyParameters = {
			filter: filter,
			viewKey: viewKey,
		}
		if (viewSession) {
			bodyParameters = { ...bodyParameters, viewSession }
		}

		return axios.post(`${url}/feed/query`, bodyParameters, config)
	},
}
