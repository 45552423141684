import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SideDialog from '../../components/SideDialog'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from '../../hooks/useVehicleLocation'
import GeoMapForm, {
	MAX_REPEAT_RELOAD_MAP_VALUE,
	MIN_REPEAT_RELOAD_MAP_VALUE,
} from './geotab-map/GeoMapForm'
import DoformsMapForm from '../doFormsMap/DoformsMapForm'
import { TYPES_OF_TILE } from '../../../../../constants'

function MapSettingsDialog({
	defaultTileWidth,
	tileElementWidth,
	open,
	tile,
	isSubmitting,
	vehicleList,
	vehicleLoading,
	locationData,
	showFilters,
	repeatReloadMapSeconds,
	showHistory,
	pointOfInterest,
	dashboardKeyList,
	linkedFields,
	onClose,
	onResizeTileWidth,
	onSubmit,
	dashboardKey,
	connectedMobileUnitKey,
}) {
	const { t } = useTranslation('common')
	const [filterData, setFilterData] = useState({})
	const [errorMessage, setErrorMessage] = useState('')

	const hasError = useMemo(() => !isEmpty(errorMessage), [errorMessage])

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	useEffect(() => {
		handleFilterDataChange('repeatReloadMapSeconds', repeatReloadMapSeconds)
	}, [repeatReloadMapSeconds])

	useEffect(() => {
		handleFilterDataChange('showFilters', showFilters)
	}, [showFilters])

	useEffect(() => {
		handleFilterDataChange('showHistory', showHistory)
	}, [showHistory])

	useEffect(() => {
		handleFilterDataChange('pointOfInterest', pointOfInterest)
	}, [pointOfInterest])

	useEffect(() => {
		if (!connectedMobileUnitKey) return

		setFilterData((prev) => ({
			...prev,
			connectedDatagridKey: connectedMobileUnitKey,
		}))
	}, [connectedMobileUnitKey])

	useEffect(() => {
		if (!locationData?.fromDate) return

		setFilterData((prev) => ({
			...prev,
			locationData: {
				...prev.locationData,
				fromDate: locationData?.fromDate,
			},
		}))
	}, [locationData?.fromDate])

	useEffect(() => {
		if (!locationData?.toDate) return

		setFilterData((prev) => ({
			...prev,
			locationData: {
				...prev.locationData,
				toDate: locationData?.toDate,
			},
		}))
	}, [locationData?.toDate])

	useEffect(() => {
		if (isEmpty(linkedFields)) return
		handleFilterDataChange('linkedFields', linkedFields)
	}, [linkedFields])

	const handleOK = () => {
		if (
			filterData.repeatReloadMapSeconds < MIN_REPEAT_RELOAD_MAP_VALUE ||
			filterData.repeatReloadMapSeconds > MAX_REPEAT_RELOAD_MAP_VALUE
		)
			return
		onSubmit?.({
			locationData: filterData.locationData,
			showFilters: filterData.showFilters,
			repeatReloadMapSeconds:
				filterData.repeatReloadMapSeconds || DEFAULT_REPEAT_RELOAD_MAP_SECONDS,
			showHistory: filterData.showHistory,
			pointOfInterest: filterData.pointOfInterest,
			linkedFields: filterData.linkedFields,
			connectedDatagridKey: filterData.connectedDatagridKey,
		})
	}

	return (
		<>
			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={tile?.w ?? 6}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack spacing={2}>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>
					{tile.type === TYPES_OF_TILE.DOFORMS_MAP && (
						<DoformsMapForm
							filterData={filterData}
							linkedFields={linkedFields}
							dashboardKeyList={dashboardKeyList}
							onFilterDataChange={handleFilterDataChange}
							setErrorMessage={setErrorMessage}
							dashboardKey={dashboardKey}
							mobileUnitListWhenCreating={[]}
							enabledFetchTileList={true}
							connectedMobileUnitKey={connectedMobileUnitKey}
						/>
					)}
					{tile.type === TYPES_OF_TILE.GEO_MAP && (
						<GeoMapForm
							vehicleList={vehicleList}
							vehicleLoading={vehicleLoading}
							filterData={filterData}
							linkedFields={linkedFields}
							dashboardKeyList={dashboardKeyList}
							onFilterDataChange={handleFilterDataChange}
						/>
					)}

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleOK}
								disabled={hasError || isSubmitting}
								startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default MapSettingsDialog
