import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { enUS } from 'date-fns/locale'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { TextField, MenuItem, Grid, Tooltip, Button, Popover, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers'
import { DateRangeCalendar } from '@mui/x-date-pickers-pro'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
	datesValues,
	operatorOptions,
	getConditionString,
} from '../../../../../utils/functions/conditionsOptions'
import { computeDateValue, toDayjs } from '../../../../../utils/functions/doformsDateUtil'
import {
	parseAs,
	calculateUserTimezoneOffset,
} from '../../../../../utils/functions/doformsDateUtil'
import { DATE_CONDITION_FILTER_TYPE } from './MasterDatetimeTile'
import { NavigateNext, NavigateBefore } from '@mui/icons-material'
const useStyles = makeStyles(() => ({
	root: {
		minWidth: '300px',
	},

	columnBox: {
		maxWidth: '15em',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderRadius: '0',
			},
		},
	},

	filtersContainer: {
		margin: '16px',
	},

	footer: {
		// marginTop: theme.spacing(1),
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},

	footerLeft: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: '8px',
	},

	footerRight: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	formRoot: {
		padding: '16px',
		justifyContent: 'space-around',
	},

	menuItem: {
		fontSize: '13px',
		paddingTop: 'none',
		paddingBottom: 'none',
	},

	subheader: {
		margin: 'none',
	},

	operatorBox: {
		minWidth: '120px',
		width: '100%',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderRadius: `0`,
			},
		},
	},

	customValueBox: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderRadius: `0`,
			},
		},

		'& .MuiOutlinedInput-input': {
			minHeight: '1.65em',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			fontSize: '0.875rem',
			lineHeight: '1.4375em',
			letterSpacing: '0.00938em',
			padding: '8.5px 14px !important',
		},

		'& .MuiTextField-root': {
			marginLeft: '0 !important',
		},
	},

	presetsValueBox: {
		display: 'inline-grid',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderRadius: `0 4px 4px 0`,
			},
		},
	},

	dateRangePicker: {
		width: '100%',
		display: 'block',
	},
}))

const DisplayDatePickerType = {
	SINGLE: 'single',
	RANGE: 'range',
}

function buildCondition(operator, initValues) {
	const isOperatorType = ['EQ', 'NE', 'BT', 'CT', 'EMPTY', 'NOTEMPTY'].includes(operator)
	if (isOperatorType) {
		switch (operator) {
			case 'EMPTY':
				return { preset: operator, type: 'EQ', values: [] }
			case 'NOTEMPTY':
				return { preset: operator, type: 'NE', values: [] }

			case 'BT':
				return {
					type: 'BT',
					join: 'AND',
					values: [initValues[0] || null, initValues[1] || null],
				}
			default:
				return {
					type: operator,
					values: initValues ? [initValues[0]] : [null],
				}
		}
	} else {
		return { type: 'EQ', preset: operator, values: initValues || [] }
	}
}

const DEFAULT_DATE_OPERATOR = 'TODAY'

export default function MasterDateTimeSelectComponent(props) {
	const { disablePicker, dateConditionFilterDispatch, dateTimeOperators = [] } = props
	const [t] = useTranslation('common')
	const classes = useStyles()
	const operators = operatorOptions(t)
	const presets = datesValues(t)
	const [operatorValue, setOperatorValue] = useState(DEFAULT_DATE_OPERATOR)
	const [displayType, setDisplayType] = useState(DisplayDatePickerType.SINGLE)
	const [values, setValues] = useState([])
	const { environment } = useSelector((state) => state)

	const [displayOperator, setDisplayOperator] = useState(operators)
	const [displayPresets, setDisplayPresets] = useState(presets)

	useEffect(() => {
		if (isEmpty(dateTimeOperators)) return

		const selectedOperators = operators.filter((operator) => {
			const operatorValue = getConditionString(operator)
			return dateTimeOperators.includes(operatorValue)
		})

		const selectedPresets = presets.filter((preset) => {
			const presetValue = getConditionString(preset)
			return dateTimeOperators.includes(presetValue)
		})

		setDisplayOperator(selectedOperators)
		setDisplayPresets(selectedPresets)
	}, [dateTimeOperators])
	const userOffset = useMemo(() => {
		return calculateUserTimezoneOffset(environment)
	}, [environment])

	const handleDateOperatorChange = (event) => {
		const value = event.target.value
		setOperatorValue(value)
	}

	const needPicker = useMemo(
		() => !['ALL', 'EMPTY', 'NOTEMPTY'].includes(operatorValue),
		[operatorValue]
	)

	useEffect(() => {
		const currentCondition = buildCondition(operatorValue, [])

		if (!currentCondition) return

		const { type: operator, preset } = currentCondition
		if (!operator && !preset) return

		if (preset) {
			const computedValues = computeDateValue(preset)
			const computedValuesISO = computedValues.map((item) => {
				if (!item) return ''
				const userMoment = parseAs(item, userOffset)
				return userMoment?.toISOString()
			})
			switch (preset) {
				case 'EMPTY':
				case 'NOTEMPTY':
				case 'ALL': {
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values: [],
						},
					})
					setDisplayType(null)
					setValues(computedValuesISO)
					return
				}
				case 'TODAY':
				case 'YESTERDAY':
				case 'TOMORROW': {
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values: computedValuesISO,
						},
					})
					setDisplayType(DisplayDatePickerType.SINGLE)
					setValues(computedValuesISO)
					return
				}

				default: {
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values: computedValuesISO,
						},
					})
					setDisplayType(DisplayDatePickerType.RANGE)
					setValues(computedValuesISO)
					return
				}
			}
		} else {
			const now = moment()
			const today = now.clone().startOf('day')
			const todayISOString = parseAs(today, userOffset).toISOString()
			switch (operator) {
				case 'EQ':
				case 'NE':
				case 'CT': {
					const values = [todayISOString]
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values,
						},
					})
					setDisplayType(DisplayDatePickerType.SINGLE)
					setValues(values)
					return
				}
				case 'BT': {
					const endToday = now.clone().endOf('day')
					const endTodayMomentISOString = parseAs(endToday, userOffset).add(1, 's').toISOString()
					const values = [todayISOString, endTodayMomentISOString]
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values,
						},
					})
					setDisplayType(DisplayDatePickerType.RANGE)
					setValues(values)
					return
				}

				default: {
					const values = []
					dateConditionFilterDispatch({
						type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
						value: {
							...currentCondition,
							values,
						},
					})
					setDisplayType(null)
					setValues(values)
					return
				}
			}
		}
	}, [operatorValue])

	return (
		<Grid container>
			<Grid item xs={5} md={5} lg={5} style={{ paddingLeft: '6px' }}>
				<TextField
					disabled={disablePicker}
					id="operatorsSelection"
					className={classes.operatorBox}
					size="small"
					select={true}
					label={t('common:filters.operator')}
					color="primary"
					variant="outlined"
					value={operatorValue}
					onChange={(e) => {
						const newOperator = e.target.value
						setOperatorValue(newOperator)
						handleDateOperatorChange(e)
					}}
				>
					{!isEmpty(displayOperator) &&
						displayOperator.map((option) => (
							<MenuItem key={option.name} value={option.type} className={classes.menuItem}>
								{option.name}
							</MenuItem>
						))}
					{!isEmpty(displayOperator) && !isEmpty(displayPresets) && (
						<hr className={classes.subheader} />
					)}
					{!isEmpty(displayPresets) &&
						displayPresets.map((option) => (
							<MenuItem key={option.preset} value={option.preset} className={classes.menuItem}>
								{option.name}
							</MenuItem>
						))}
				</TextField>
			</Grid>
			{needPicker && (
				<Grid item xs={7} md={7} lg={7} style={{ paddingTop: '3px' }}>
					<DateTimePicker
						disablePicker={disablePicker}
						operatorValue={operatorValue}
						dateConditionFilterDispatch={dateConditionFilterDispatch}
						displayType={displayType}
						values={values}
						setValues={setValues}
						userOffset={userOffset}
					/>
				</Grid>
			)}
		</Grid>
	)
}

function DateTimePicker(props) {
	const {
		disablePicker,
		operatorValue,
		dateConditionFilterDispatch,
		displayType,
		values,
		setValues,
		userOffset,
	} = props
	const [t] = useTranslation('common')
	const dateValueRef = useRef(null)
	const classes = useStyles()
	const presets = datesValues(t)
	const [locale, setLocale] = useState(enUS)
	const [anchorElSingle, setAnchorElSingle] = useState(null)
	const [anchorElRange, setAnchorElRange] = useState(null)
	const isOpenSingleCalendar = Boolean(anchorElSingle)
	const isOpenRangeCalendar = Boolean(anchorElRange)
	const isPreset = useMemo(() => {
		return presets?.some((item) => item.preset === operatorValue)
	}, [operatorValue])

	const displayValues = useMemo(() => {
		return values.map((utcString, index) => {
			const momentUTC = index === 0 ? moment.utc(utcString) : moment.utc(utcString).subtract(1, 's')
			return momentUTC.utcOffset(userOffset).format('YYYY-MM-DD')
		})
	}, [values, userOffset])

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	const handleOpenSingle = (event) => {
		setAnchorElSingle(event.currentTarget)
	}

	const handleCloseSingle = () => {
		setAnchorElSingle(null)
	}

	const handleOpenRange = (event) => {
		setAnchorElRange(event.currentTarget)
	}

	const handleCloseRange = (applyData = false) => {
		setAnchorElRange(null)
		if (applyData) {
			dateConditionFilterDispatch({
				type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
				value: values,
			})
		}
	}

	const handleChangeSingleDatePicker = (newValue, isOnInput) => {
		const selectMomentValue = moment(newValue?.toDate())
		const dateString = isOnInput
			? selectMomentValue
			: selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) return
		const startDayMoment = momentValue.clone().startOf('day')
		const userMoment = parseAs(startDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		dateConditionFilterDispatch({
			type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
			value: [startDayUTC],
		})
		setValues([startDayUTC])
	}

	const handleChangeDateRangePicker = (values, callback) => {
		if (!values[0] || !values[1]) return
		const selectMomentFromValue = moment(values[0]?.toDate())
		const selectMomentToValue = moment(values[1]?.toDate())
		const fromDateString = selectMomentFromValue.format('YYYY-MM-DD HH:mm:ss')
		const toDateString = selectMomentToValue.format('YYYY-MM-DD HH:mm:ss')
		let startMomentObj = moment(fromDateString).clone().startOf('day')
		let endMomentObj = moment(toDateString).endOf('day').add(1, 's')

		const fromUserMoment = parseAs(startMomentObj, userOffset)
		const endUserMoment = parseAs(endMomentObj, userOffset)

		if (fromUserMoment.isValid() && endUserMoment.isValid()) {
			const startDayUTC = fromUserMoment.toISOString()
			const endDayUTC = endUserMoment.toISOString()
			callback(startDayUTC, endDayUTC)
		}
	}

	const handleChangePreviousDate = (type, action) => {
		if (type === DisplayDatePickerType.SINGLE) {
			const oldValue = values[0]
			let previousDay = moment.utc(oldValue).clone().subtract(1, 'day').toISOString()
			dateConditionFilterDispatch({
				type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
				value: [previousDay],
			})
			setValues([previousDay])
		} else {
			const oldValue = values[0]
			let previousDay = moment.utc(oldValue).clone().subtract(1, 'day').toISOString()
			dateConditionFilterDispatch({
				type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
				value: [previousDay, values[1]],
			})
			setValues([previousDay, values[1]])
		}
	}

	const handleChangeNextDate = (type, action) => {
		if (type === DisplayDatePickerType.SINGLE) {
			const oldValue = values[0]
			let nextDay = moment.utc(oldValue).clone().add(1, 'day').toISOString()
			dateConditionFilterDispatch({
				type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
				value: [nextDay],
			})
			setValues([nextDay])
		} else {
			const oldValue = values[1]
			let nextDay = moment.utc(oldValue).clone().add(1, 'day').toISOString()
			dateConditionFilterDispatch({
				type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
				value: [values[0], nextDay],
			})
			setValues([values[0], nextDay])
		}
	}

	return (
		<div style={{ display: 'flex' }}>
			{displayType === DisplayDatePickerType.SINGLE && (
				<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
					<IconButton
						style={{
							paddingLeft: '0px',
							paddingRight: '0px',
						}}
						size="small"
						onClick={() => handleChangePreviousDate(DisplayDatePickerType.SINGLE)}
						disabled={isPreset || disablePicker}
					>
						<NavigateBefore />
					</IconButton>
					<Button
						style={{
							paddingLeft: '0px',
							paddingRight: '0px',
						}}
						onClick={handleOpenSingle}
						disabled={isPreset || disablePicker}
					>
						{displayValues[0]}
					</Button>

					<Popover
						id={'date-calendar-single-popover'}
						open={isOpenSingleCalendar}
						anchorEl={anchorElSingle}
						onClose={handleCloseSingle}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<DateCalendar
							value={toDayjs(displayValues[0])}
							onChange={(value) => {
								handleChangeSingleDatePicker(value, false)
								handleCloseSingle()
							}}
						/>
					</Popover>
					<IconButton
						style={{
							paddingLeft: '0px',
							paddingRight: '0px',
						}}
						size="small"
						onClick={() => handleChangeNextDate(DisplayDatePickerType.SINGLE)}
						disabled={isPreset || disablePicker}
					>
						<NavigateNext />
					</IconButton>
				</LocalizationProvider>
			)}
			{displayType === DisplayDatePickerType.RANGE && (
				<div id="dateRangePicker" className={classes.dateRangePicker}>
					<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
						<IconButton
							style={{
								paddingLeft: '0px',
								paddingRight: '0px',
							}}
							size="small"
							onClick={() => handleChangePreviousDate(DisplayDatePickerType.RANGE)}
							disabled={isPreset || disablePicker}
						>
							<NavigateBefore />
						</IconButton>
						<Button
							style={{
								paddingLeft: '0px',
								paddingRight: '0px',
							}}
							onClick={handleOpenRange}
							disabled={isPreset || disablePicker}
						>
							{displayValues[0]} - {displayValues[1]}
						</Button>

						<Popover
							id={'date-calendar-range-popover'}
							open={isOpenRangeCalendar}
							anchorEl={anchorElRange}
							onClose={handleCloseRange}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<DateRangeCalendar
								value={[toDayjs(displayValues[0]), toDayjs(displayValues[1])]}
								onChange={(newValue) => {
									handleChangeDateRangePicker(newValue, (start, to) => {
										// dateConditionFilterDispatch({
										// 	type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
										// 	value: [start, to],
										// })
										setValues([start, to])
									})
								}}
								sx={{ marginLeft: '-3px', marginRight: '-3px' }}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'end',
									gap: '8px',
								}}
							>
								<Button onClick={() => handleCloseRange()} sx={{ fontSize: '13px' }}>
									{t('common:misc.cancel')}
								</Button>
								<Button onClick={() => handleCloseRange(true)} sx={{ fontSize: '13px' }}>
									{t('common:misc.ok')}
								</Button>
							</div>
						</Popover>
						<IconButton
							style={{
								paddingLeft: '0px',
								paddingRight: '0px',
							}}
							size="small"
							onClick={() => handleChangeNextDate(DisplayDatePickerType.RANGE)}
							disabled={isPreset || disablePicker}
						>
							<NavigateNext />
						</IconButton>
					</LocalizationProvider>
				</div>
			)}
		</div>
	)
}
