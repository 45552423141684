import axios from 'axios'
import { apiUrl } from '../../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
    return {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
}

export const messagesViewApi = {
    getViewData(token, cancelToken) {
        return axios.get(`${url}/messages/view`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },

    getColumns(token, cancelToken) {
        return axios.get(`${url}/messages/view/columns`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },

    getAllRecords(viewKey, filter, token, cancelToken) {
        const config = {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        }

        let bodyParameters = {
            filter: filter,
            viewKey: viewKey,
        }

        return axios.post(`${url}/messages/query`, bodyParameters, config)
    },
}
