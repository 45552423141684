import { useEffect, useRef, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isEqual, sortBy } from 'lodash'
import moment from 'moment'

import { Polyline, Marker, InfoWindow } from '@react-google-maps/api'
import LoadingSpinner from '../../../../../../custom-components/LoadingSpinner'
import { useDriveLocation } from './hook'

import { rotateImage, isTimestampInRange, convertMPerSecondToMPH } from '../helper/functions'

import arrowIcon from '../../../../../../static/img/arrow-up.png'
import stopIcon from '../../../../../../static/img/stop.png'

const svgIcons = {
	currentLocation: {
		path: 'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z',
		color: '#575fcf',
		borderColor: '#1e272e',
		scale: 0.06,
		pointX: 180,
		pointY: 600,
	},
	arrow: {
		url: arrowIcon,
	},
	exclamation: {
		path: 'M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z',
		color: '#fff200',
		borderColor: '#ff3838',
		scale: 1.3,
		pointX: 15,
		pointY: 15,
	},
}

export function DrivePolylineComponent(props) {
	const {
		index,
		userId,
		userEmail,
		drive,
		fromDate,
		toDate,
		activeMarker,
		setActiveMarker,
		handleActiveMarker,
		locationByDriveId,
		updateLocationsByDriveId,
		showHistory,
		center,
		setCenter,
		shouldRefetchLocations,
		repeatReloadMapSeconds,
		currentLocationDrive,
	} = props

	const environment = useSelector((state) => state.environment)

	const isFutureToDate = useMemo(() => moment.unix(toDate).isAfter(moment()), [toDate])
	const isLastDrive = useMemo(() => index === 0, [index])
	const isCompletedDrive = useMemo(() => {
		return (
			drive.end_date_timestamp !== '' ||
			drive.end_date_timestamp !== null ||
			drive.end_date_timestamp !== undefined
		)
	}, [drive])

	const showLastActiveLocation = useMemo(() => {
		return isFutureToDate && isLastDrive && !isCompletedDrive
	}, [isFutureToDate, isLastDrive, isCompletedDrive])

	const { data: allLocation, isFetching } = useDriveLocation({
		userId,
		drive,
		shouldRefetchInterval: shouldRefetchLocations && showLastActiveLocation,
		repeatReloadMapSeconds,
	})

	const userOffset = useMemo(() => {
		return environment.userCurrent.time.timezone.offset || 0
	}, [environment])

	const stoppedLocation = useMemo(() => {
		if (isFetching) return null
		if (!showHistory) {
			// prevent unnecessary setCenter
			if (!isEqual(currentLocationDrive?.id, drive.id)) return null
			if (isEmpty(allLocation)) {
				return {
					lat: drive.start_lat,
					long: drive.start_long,
					timestamp: drive.start_date_timestamp,
				}
			} else {
				if (!drive.end_lat || !drive.end_long) return null
				return {
					lat: drive.end_lat,
					long: drive.end_long,
					timestamp: drive.end_date_timestamp,
				}
			}
		} else {
			if (!drive.end_lat || !drive.end_long) return null
			return {
				lat: drive.end_lat,
				long: drive.end_long,
				timestamp: drive.end_date_timestamp,
			}
		}
	}, [showHistory, currentLocationDrive, allLocation, drive, isFetching])

	const sortedLocationByDate = useMemo(() => {
		if (!allLocation || isEmpty(allLocation)) return []

		// Filter, sort, and map in a single chain for clarity and performance
		return sortBy(
			allLocation.filter(({ lat, long }) => lat !== null && long !== null),
			(obj) => moment.utc(obj.timestamp)
		)
	}, [allLocation])

	const displaySortedLocationByDate = useMemo(() => {
		const filteredLocation = sortedLocationByDate.filter((location) => {
			const { timestamp } = location
			return isTimestampInRange(timestamp, fromDate, toDate)
		})

		return filteredLocation.map(({ lat, long }) => ({
			lat: lat,
			lng: long,
		}))
	}, [userId, sortedLocationByDate, fromDate, toDate])

	useEffect(() => {
		if (!displaySortedLocationByDate || !drive?.id) return

		const currentDisplayLocations = locationByDriveId?.[drive.id] || []
		if (isEqual(displaySortedLocationByDate, currentDisplayLocations)) return

		updateLocationsByDriveId(drive.id, displaySortedLocationByDate)
	}, [displaySortedLocationByDate, drive?.id, updateLocationsByDriveId])

	const renderStoppedMarker = useCallback(() => {
		if (!stoppedLocation) return null

		const { long, lat, timestamp } = stoppedLocation
		const formattedStopDate = moment
			.utc(timestamp)
			.utcOffset(userOffset)
			.format('MM/DD/YY [at] hh:mm:ss A')
		const markerId = `driveId-${drive.id}-stop-marker`
		const hasPosition = lat && long
		const position = { lat: lat, lng: long }

		if (!hasPosition) return null

		return (
			<Marker
				key={markerId}
				position={position}
				onClick={() => handleActiveMarker(markerId)}
				icon={{
					url: stopIcon,
					scaledSize: new window.google.maps.Size(20, 20),
					anchor: new window.google.maps.Point(0, 10),
				}}
			>
				{activeMarker === markerId && (
					<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
						<div>
							<p>
								<span style={{ fontWeight: 'bold' }}>Stop time: </span>
								{formattedStopDate}
							</p>
							{/* <p>
                                    <span style={{ fontWeight: 'bold' }}>Stop duration: </span>
                                    {formatDuration(stopDuration)}
                                </p> */}
						</div>
					</InfoWindow>
				)}
			</Marker>
		)
	}, [stoppedLocation, activeMarker, handleActiveMarker, stopIcon])

	useEffect(() => {
		if (showHistory || isEmpty(stoppedLocation)) return

		const centerPoint = { lat: stoppedLocation.lat, lng: stoppedLocation.long }
		if (isEqual(center, centerPoint)) return
		setCenter(centerPoint)
	}, [showHistory, center, stoppedLocation])

	const lastActiveLocation = useMemo(() => {
		if (!showLastActiveLocation || !sortedLocationByDate || isEmpty(sortedLocationByDate))
			return null

		// last active location
		return sortedLocationByDate.at(-1)
	}, [showLastActiveLocation, sortedLocationByDate])

	const renderActiveMarker = useCallback(() => {
		if (!lastActiveLocation) return null

		const { long, altitude, bearing, horizontal_accuracy, lat, speed, timestamp } =
			lastActiveLocation
		const formattedDate = moment
			.utc(timestamp)
			.utcOffset(userOffset)
			.format('MM/DD/YY [at] hh:mm:ss A')

		const hasPosition = lat && long
		const position = { lat: lat, lng: long }

		let icon
		const shouldShowArrow = bearing !== -1
		// If metric is true, use km/h, otherwise use mph
		const isMetric = true

		if (shouldShowArrow) {
			icon = svgIcons.arrow
		} else {
			icon = svgIcons.currentLocation
		}

		const inputImage = new Image()
		inputImage.src = icon?.url

		const markerId = `driveId-${drive.id}-active-marker`

		return (
			<Marker
				key={markerId}
				position={position}
				onClick={() => handleActiveMarker(markerId)}
				zIndex={100}
				icon={
					icon?.url
						? {
								url: inputImage?.src ? rotateImage(inputImage, bearing).src : icon?.url,
								scaledSize: new window.google.maps.Size(28, 28),
								anchor: new window.google.maps.Point(15, 15),
						  }
						: {
								path: icon.path, // Use the rectangle path
								fillColor: icon.color,
								strokeColor: icon.borderColor, // Border color
								scale: icon.scale, // Adjust the scale as needed
								rotation: shouldShowArrow ? bearing : undefined,
								fillOpacity: 1,
								strokeWeight: 1, // Border thickness
								scaledSize: new window.google.maps.Size(30, 30),
								anchor: new window.google.maps.Point(icon.pointX, icon.pointY), // Anchor point at the center of the icon
						  }
				}
			>
				{/* Fix open popover issue when clicking on a marker */}
				{/* @see: https://stackoverflow.com/questions/48719430/not-able-to-render-the-infowindow-in-react-google-maps */}
				{hasPosition && activeMarker === markerId ? (
					<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
						<div>
							<p>{formattedDate}</p>
							{userEmail && (
								<p>
									<span style={{ fontWeight: 'bold' }}>Driver: </span>
									{userEmail}
								</p>
							)}
							{/* <p>
                                                                <span style={{ fontWeight: 'bold' }}>Driving: </span>
                                                                {formatDuration(currentStateDuration)}
                                                            </p> */}
							<p>
								<span style={{ fontWeight: 'bold' }}>Speed: </span>
								{isMetric ? `${speed} m/s` : `${convertMPerSecondToMPH(speed)} mph`}
							</p>
						</div>
					</InfoWindow>
				) : null}
			</Marker>
		)
	}, [lastActiveLocation, activeMarker, handleActiveMarker, userEmail])

	return (
		displaySortedLocationByDate && (
			<div id={`drive-${drive.id}`}>
				{isFetching && <LoadingSpinner />}
				{showHistory && (
					<Polyline
						key={`polyline-${drive.id}`}
						path={displaySortedLocationByDate}
						options={{
							strokeColor: 'rgba(134, 0, 191, 0.9)',
							strokeOpacity: 0.8,
							strokeWeight: 5,
							geodesic: true,
							icons: [
								{
									icon: {
										path: window.google?.maps?.SymbolPath?.FORWARD_CLOSED_ARROW || '',
										strokeWeight: 2,
										fillColor: '#fefefe',
										fillOpacity: 1,
										strokeOpacity: 1,
										strokeColor: '#fefefe',
										scale: 1,
										labelOrigin: new window.google.maps.Point(0, 0),
										rotation: 0,
									},
									offset: '100%',
									repeat: '60px',
								},
							],
						}}
					></Polyline>
				)}

				{renderActiveMarker()}
				{renderStoppedMarker()}
			</div>
		)
	)
}
