import { Box, Stack } from '@mui/material'
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import moment from 'moment/moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { convertKmHToMph, checkByMasterDateTimeCondition } from 'utils/functions/helpers'
import LoadingSpinner from '../../../../../../custom-components/LoadingSpinner'
import arrowIcon from '../../../../../../static/img/arrow-up.png'
import stopIcon from '../../../../../../static/img/stop.png'
import { useTileDashboard } from '../../../dashboard/Dashboard'
import { getAutoUpdateFilters } from '../../../helpers'
import useCurrentLoggedMapUser from '../../../hooks/useCurrentLoggedMapUser'
import useCurrentVehicle from '../../../hooks/useCurrentVehicle'
import useVehicleLocation from '../../../hooks/useVehicleLocation'
import GeoMapFilters from './GeoMapFilters'
import _, { isEmpty, isNumber } from 'lodash'

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_MAPKEY

const svgIcons = {
	currentLocation: {
		path: 'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z',
		color: '#575fcf',
		borderColor: '#1e272e',
		scale: 0.06,
		pointX: 180,
		pointY: 600,
	},
	arrow: {
		url: arrowIcon,
	},
	exclamation: {
		path: 'M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z',
		color: '#fff200',
		borderColor: '#ff3838',
		scale: 1.3,
		pointX: 15,
		pointY: 15,
	},
}

function convertArrayToObject(arr) {
	const output = {}
	for (let item of arr) {
		const { field, value } = item
		output[field] = value
	}
	return output
}

function formatDuration(stopDuration) {
	// Parse the time string into a Moment object
	const duration = moment.duration(stopDuration)

	// Format the time as "X days Y hours Z minutes"
	const formattedDuration = duration.humanize()

	return formattedDuration
}

const defaultMapState = {
	center: { lat: 33.753746, lng: -84.38633 },
	zoom: 11,
}

const COLUMN_NAME = {
	FROM_DATE: 'fromDate',
	TO_DATE: 'toDate',
	NAME: 'vehicleName',
}

function rotateImage(inputImage, rotationDegree) {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	// Determine the size of the canvas to fit the image after rotation
	const width = inputImage.width
	const height = inputImage.height

	canvas.width = width
	canvas.height = height

	// Rotate the image and draw it on the canvas
	ctx.translate(width / 2, height / 2) // Move the focus to the center of the image
	ctx.rotate((rotationDegree * Math.PI) / 180) // Rotate by rotation angle (convert to radians)
	ctx.drawImage(inputImage, -width / 2, -height / 2, width, height) // Draw pictures

	// Convert canvas to rotated image
	const rotatedImage = new Image()
	rotatedImage.src = canvas.toDataURL('image/png')

	return rotatedImage
}

export default function GeoMapInfo({
	titleKey,
	vehicleList,
	userInfo,
	vehicleLoading,
	locationData: _locationData,
	showFilters,
	repeatReloadMapSeconds,
	showHistory,
	pointOfInterest,
	linkedFields,
	dashboardKeyList,
}) {
	const [activeMarker, setActiveMarker] = useState(null)
	const [mapRef, setMapRef] = useState(null)
	const [locationData, setLocationData] = useState({
		vehicleName: '',
		fromDate: null,
		toDate: null,
	})
	const { vehicleName, fromDate, toDate } = locationData

	const [mapState, setMapState] = useState(defaultMapState)
	const [lastLogRecord, setLastLogRecord] = useState(moment.utc())
	const { center, zoom } = mapState

	const isReloadMap = !Boolean(toDate)

	const { selectedFields, isRemovedTile } = useTileDashboard()

	const { filterConfigs, hasPrimaryKey, conditions } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields, dashboardKeyList]
	)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	// Remove id because it is random property and make app re-render many times
	const conditionsWithoutId = useMemo(() => {
		const result = {}
		for (const key in conditions) {
			const value = conditions[key]
			const { id, ...rest } = value
			result[key] = rest
		}
		return result
	}, [conditions])

	const {
		data: currentVehicleLocation,
		isLoading,
		isFetching: currentVehicleLocationFetching,
	} = useCurrentVehicle({
		vehicleList,
		userInfo,
		vehicleName,
		apiCallIntervalInSeconds: repeatReloadMapSeconds,
		lastLogRecord,
		toDate,
	})
	const isDrivingVehicle = useMemo(
		() => currentVehicleLocation?.[0]?.isDriving ?? false,
		[currentVehicleLocation]
	)

	const { data: currentLoggedMapUser, isFetching: currentLoggedMapUserFetching } =
		useCurrentLoggedMapUser({
			userInfo,
		})

	const currentVehicle = useMemo(() => {
		return currentLoggedMapUser.find((x) => x?.id === currentVehicleLocation?.[0]?.driver?.id)
	}, [currentLoggedMapUser, currentVehicleLocation])

	const { allLocation, stopLocation, isFetching } = useVehicleLocation({
		vehicleList,
		userInfo,
		vehicleName,
		fromDate,
		toDate,
		showHistory,
		apiCallIntervalInSeconds: repeatReloadMapSeconds,
		isDrivingVehicle,
	})

	const isFetchingData = useMemo(() => {
		return isFetching || currentVehicleLocationFetching || currentLoggedMapUserFetching
	}, [isFetching, currentVehicleLocationFetching, currentLoggedMapUserFetching])

	const sortedLocationByDate = useMemo(() => {
		if (!allLocation || _.isEmpty(allLocation)) return []

		// Filter, sort, and map in a single chain for clarity and performance
		return _.sortBy(
			allLocation.filter(({ latitude, longitude }) => latitude !== null && longitude !== null),
			(obj) => moment.utc(obj.dateTime)
		)
	}, [allLocation])

	useEffect(() => {
		if (isEmpty(sortedLocationByDate)) {
			setLastLogRecord(moment.utc(null))
			return
		}

		const dateTime = sortedLocationByDate[sortedLocationByDate.length - 1]?.dateTime
		setLastLogRecord(moment.utc(dateTime))
	}, [sortedLocationByDate])

	const displaySortedLocationByDate = useMemo(() => {
		return sortedLocationByDate.map(({ latitude, longitude }) => ({
			lat: latitude,
			lng: longitude,
		}))
	}, [sortedLocationByDate])

	const stopLocationWithoutZero = useMemo(() => {
		return stopLocation?.filter((x) => x.stopDuration !== '00:00:00') ?? []
	}, [stopLocation])

	useEffect(() => {
		if (!_locationData?.vehicleName) return
		setLocationData((prev) => ({
			...prev,
			vehicleName: _locationData?.vehicleName,
		}))
	}, [_locationData?.vehicleName])

	useEffect(() => {
		setLocationData((prev) => ({
			...prev,
			fromDate: _locationData?.fromDate,
		}))
	}, [_locationData?.fromDate])

	useEffect(() => {
		setLocationData((prev) => ({
			...prev,
			toDate: _locationData?.toDate,
		}))
	}, [_locationData?.toDate])

	useEffect(() => {
		if (hasPrimaryKey) {
			const {
				vehicleName: newVehicleName,
				fromDate: newFromDate,
				toDate: newToDate,
			} = convertArrayToObject(filterWithoutId)

			let startDate = newFromDate || locationData?.fromDate || _locationData?.fromDate || null
			let endDate = newToDate || locationData?.toDate || _locationData?.toDate || null
			const valueToCheck = {
				fromDate: startDate,
				toDate: endDate,
				vehicleName: newVehicleName || _locationData?.vehicleName,
			}

			if (!_.isEmpty(conditionsWithoutId)) {
				Object.values(COLUMN_NAME).forEach((key) => {
					const condition = conditionsWithoutId[key]
					if (_.isEmpty(condition)) return

					const dateValue = valueToCheck[key]
					const isValid = checkByMasterDateTimeCondition(
						dateValue,
						condition,
						condition?.keyData === 'Master_DateTime'
					)
					if (!isValid) {
						valueToCheck[key] = ''
					}
				})
			}

			setLocationData({
				vehicleName: valueToCheck.vehicleName,
				fromDate: valueToCheck.fromDate,
				toDate: valueToCheck.toDate,
			})
		} else {
			setLocationData({
				vehicleName: _locationData?.vehicleName,
				fromDate: _locationData?.fromDate,
				toDate: _locationData?.toDate,
			})
		}
	}, [JSON.stringify(filterWithoutId), JSON.stringify(conditionsWithoutId), hasPrimaryKey])

	const fitBounds = useCallback(
		(map) => {
			if (!map) return

			const bounds = new window.google.maps.LatLngBounds()
			if (!allLocation?.length) return
			allLocation.forEach(({ latitude, longitude }) =>
				bounds.extend({ lat: latitude, lng: longitude })
			)
			map.fitBounds(bounds)

			// Override zoom again
			map.setZoom(defaultMapState.zoom)

			const centerFromBounds = bounds.getCenter()

			setMapState({ center: centerFromBounds, zoom: defaultMapState.zoom })
		},
		[allLocation]
	)

	useEffect(() => {
		if (isReloadMap && currentVehicleLocation) {
			const { latitude, longitude } = currentVehicleLocation?.[0] ?? {}

			const zoom = mapRef?.getZoom() || defaultMapState.zoom

			let center = { lat: latitude, lng: longitude }
			if (!isNumber(latitude) || !isNumber(longitude)) {
				center = defaultMapState.center
			}
			setMapState({
				center,
				zoom,
			})
		} else {
			fitBounds(mapRef)
		}
	}, [
		fitBounds,
		showHistory,
		JSON.stringify(allLocation),
		JSON.stringify(currentVehicleLocation),
		isFetching,
	])

	useEffect(() => {
		// Reset zoom to default when change vehicle
		setMapState((prev) => ({
			...prev,
			zoom: defaultMapState.zoom,
		}))
		mapRef?.setZoom(defaultMapState.zoom)
	}, [vehicleName])

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAP_API_KEY,
	})

	if (!window.google || !window.google.maps) {
		console.error('Google Maps API is not loaded.')
		return <div>"Google Maps API is not loaded."</div>
	}

	if (loadError) {
		return <div>Error loading Google Maps API</div>
	}

	if (!isLoaded) {
		return <div>Loading Google Maps...</div>
	}

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return
		}
		setActiveMarker(marker)
	}

	const handleOnLoad = (map) => {
		if (!map) return

		// Store a reference to the google map instance in state
		setMapRef(map)

		// Fit map bounds to contain all markers
		fitBounds(map)
	}

	return (
		<Stack
			sx={{
				height: '100%',
				width: '100%',
				mt: '6px !important',
			}}
		>
			{isFetchingData && <LoadingSpinner />}
			{showFilters && (
				<Stack px={1} direction="row" alignItems="center" spacing={2}>
					<GeoMapFilters
						vehicleList={vehicleList}
						vehicleLoading={vehicleLoading}
						vehicleName={isRemovedTile?.[titleKey] ? null : vehicleName}
						fromDate={fromDate ?? new Date()}
						toDate={toDate}
						disableFilters
					/>
				</Stack>
			)}

			{!isRemovedTile?.[titleKey] && (
				<Box sx={{ flex: 1, mt: 1 }}>
					<GoogleMap
						onLoad={handleOnLoad}
						center={center}
						zoom={zoom}
						onClick={() => setActiveMarker(null)}
						mapContainerStyle={{
							height: '100%',
							width: '100%',
						}}
						options={{
							minZoom: 4,
							gestureHandling: 'greedy',
							styles: [
								{
									featureType: 'poi',
									stylers: [{ visibility: pointOfInterest ? 'on' : 'off' }],
								},
							],
						}}
					>
						{displaySortedLocationByDate?.length > 0 && (
							<Polyline
								path={displaySortedLocationByDate}
								options={{
									strokeColor: 'rgba(134, 0, 191, 0.9)',
									strokeOpacity: 0.8,
									strokeWeight: 5,
									geodesic: true,
									icons: [
										{
											icon: {
												path: window.google?.maps?.SymbolPath?.FORWARD_CLOSED_ARROW || '',
												strokeWeight: 2,
												fillColor: '#fefefe',
												fillOpacity: 1,
												strokeOpacity: 1,
												strokeColor: '#fefefe',
												scale: 1,
												labelOrigin: new window.google.maps.Point(0, 0),
												rotation: 0,
											},
											offset: '100%',
											repeat: '60px',
										},
									],
								}}
							/>
						)}

						{currentVehicleLocation?.length > 0 &&
							currentVehicleLocation.map((value, index) => {
								const {
									latitude,
									longitude,
									isDriving,
									isDeviceCommunicating,
									dateTime,
									currentStateDuration,
									bearing,
									speed,
								} = value
								const formattedDate = moment(dateTime).format('MM/DD/YY [at] hh:mm:ss A')

								const hasPosition = latitude && longitude
								const position = { lat: latitude, lng: longitude }

								let icon
								const shouldShowArrow = isDriving && bearing !== -1
								// If metric is true, use km/h, otherwise use mph
								const isMetric = currentVehicle?.isMetric ?? true

								if (!isDeviceCommunicating) {
									icon = svgIcons.exclamation
								} else if (shouldShowArrow) {
									icon = svgIcons.arrow
								} else {
									icon = svgIcons.currentLocation
								}

								const inputImage = new Image()
								inputImage.src = icon?.url

								return (
									<Marker
										key={index}
										position={position}
										onClick={() => handleActiveMarker(index)}
										zIndex={100}
										icon={
											icon?.url
												? {
														url: inputImage?.src ? rotateImage(inputImage, bearing).src : icon?.url,

														scaledSize: new window.google.maps.Size(28, 28),
														anchor: new window.google.maps.Point(15, 15),
												  }
												: {
														path: icon.path, // Use the rectangle path
														fillColor: icon.color,
														strokeColor: icon.borderColor, // Border color
														scale: icon.scale, // Adjust the scale as needed
														rotation: shouldShowArrow ? bearing : undefined,
														fillOpacity: 1,
														strokeWeight: 1, // Border thickness
														scaledSize: new window.google.maps.Size(30, 30),
														anchor: new window.google.maps.Point(icon.pointX, icon.pointY), // Anchor point at the center of the icon
												  }
										}
									>
										{/* Fix open popover issue when clicking on a marker */}
										{/* @see: https://stackoverflow.com/questions/48719430/not-able-to-render-the-infowindow-in-react-google-maps */}
										{hasPosition && activeMarker === index ? (
											<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
												{isDriving ? (
													<div>
														<p>{formattedDate}</p>
														{vehicleName && (
															<p>
																<span style={{ fontWeight: 'bold' }}>Vehicle: </span>
																{vehicleName}
															</p>
														)}
														<p>
															<span style={{ fontWeight: 'bold' }}>Driving: </span>
															{formatDuration(currentStateDuration)}
														</p>
														<p>
															<span style={{ fontWeight: 'bold' }}>Speed: </span>
															{isMetric ? `${speed} km/h` : `${convertKmHToMph(speed)} mph`}
														</p>
													</div>
												) : (
													<div>
														<p>{formattedDate}</p>
														{vehicleName && (
															<p>
																<span style={{ fontWeight: 'bold' }}>Vehicle: </span>
																{vehicleName}
															</p>
														)}
														<p>
															<span style={{ fontWeight: 'bold' }}>Stopped: </span>
															{formatDuration(currentStateDuration)}
														</p>
													</div>
												)}
											</InfoWindow>
										) : null}
									</Marker>
								)
							})}

						{allLocation?.length > 0 &&
							stopLocationWithoutZero?.length > 0 &&
							stopLocationWithoutZero.map((value, index) => {
								const { id, stopPoint, stopDuration, stop } = value

								const { x: lng, y: lat } = stopPoint
								const formattedStopDate = moment(stop).format('MM/DD/YY [at] hh:mm:ss A')

								const markerId = id ?? index
								const hasPosition = lat && lng
								const position = { lat, lng }

								return (
									<Marker
										key={markerId}
										position={position}
										onClick={() => handleActiveMarker(markerId)}
										icon={{
											url: stopIcon,

											scaledSize: new window.google.maps.Size(20, 20),
											anchor: new window.google.maps.Point(0, 10),
										}}
									>
										{/* Fix open popover issue when clicking on a marker */}
										{/* @see: https://stackoverflow.com/questions/48719430/not-able-to-render-the-infowindow-in-react-google-maps */}
										{hasPosition && activeMarker === markerId ? (
											<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
												<div>
													<p>
														<span style={{ fontWeight: 'bold' }}>Stop time: </span>
														{formattedStopDate}
													</p>
													<p>
														<span style={{ fontWeight: 'bold' }}>Stop duration: </span>
														{formatDuration(stopDuration)}
													</p>
												</div>
											</InfoWindow>
										) : null}
									</Marker>
								)
							})}
					</GoogleMap>
				</Box>
			)}
		</Stack>
	)
}
