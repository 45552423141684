export const HELP_LINK_DATA_CHART =
	'https://support.doforms.com/hc/en-us/articles/4403449163667-Charts'
export const HELP_LINK_CONDITION_FILTERS =
	'https://support.doforms.com/hc/en-us/articles/1500006746162-Queries-filters-sorts-and-columns'
export const HELP_LINK_PROFILE = 'https://support.doforms.com/hc/en-us/articles/11788168076051'
export const HELP_LINK_MAP = 'https://support.doforms.com/hc/en-us/articles/1500005712281-Maps'
export const SEARCH_INPUT_STORAGE = 'search-input-storage'
export const CALL_RECORDS_STORAGE = 'call-records-storage'
export const CHECK_LOAD_COLUMN_STORAGE = 'check-load-column-storage'
export const ACTIVITY_VIEW_TYPE = 'ACTIVITY_CUSTOMER'
export const DEVICE_ACTIVITY_TYPE = 'ACTIVITY_DEVICE'
export const ACTIVITY_DEVICE_LIST = [DEVICE_ACTIVITY_TYPE, ACTIVITY_VIEW_TYPE]
export const ACTIVITY_RECEIVE_TIME_KEY = '@ActivityReceiveTime'
export const ACTIVITY_VIEW_FIREBASE_PATH = 'userSessions/viewActivity/customerKey/'
export const DEVICE_ACTIVITY_FIREBASE_PATH = 'userSessions/viewActivity/mobileKey/'
export const MY_GEO_TAB_LINK = 'https://my.geotab.com'
export const VIEW_TYPE_NOT_SHOW = ['ACTIVITY_FORM']
export const MAP_LOGGED_IN_INFO = 'map-logged-in-info'
export const DRIVER_MAP_LOGGED_IN_INFO = 'driver-logged-in-info'
export const TabList = {
	MobileUnits: 'Mobile units',
	Forms: 'Forms',
	Activity: 'Activity',
	Lookups: 'Lookups',
	Views: 'Views',
	Reports: 'Reports',
}

export const TYPES_OF_TILE = {
	CHART: 'Chart',
	DATA_GRID: 'datagrid',
	GEO_MAP: 'GeoMap',
	PAGE: 'Page',
	FORM_LIST: 'FormList',
	IMAGE_VIEWER: 'ImageViewer',
	MOBILE_UNITS: 'MobileUnits',
	FEED: 'Feed',
	DOFORMS_MAP: 'DoformsMap',
	BROWSER: 'Browser',
	TEXT_SEARCH: 'TextSearch',
	DO_FORMS_FORM: 'DoFormsForm',
	BLANK: 'Blank',
	MASTER_DATETIME: 'MasterDateTime',
	SCHEDULER: 'Scheduler',
	DRIVER_EVENTS: 'DriverEvents',
}

export const VIEW = {
	VIEW_TYPE_EVERYONE: 'everyone',
	VIEW_TYPE_VIEWTAB: 'viewtab',
	VIEW_TYPE_MYVIEW: 'myview',
	VIEW_SAVE_TYPE_ADD_UPDATE: 'saveTypeAddUpdate',
	VIEW_SAVE_TYPE_ADD: 'add',
	VIEW_SAVE_TYPE_UPDATE: 'update',
	VIEW_SAVE_THE_VIEW: 'saveTheView',
	VIEW_TAB_DEVICES: 'devices',
	VIEW_TAB_FORMS: 'forms',
	VIEW_TAB_LOOKUPS: 'lookups',
	VIEW_TAB_VIEWS: 'views',
	TAB_TILE_BUILDER: 'tileBuilder',
}
