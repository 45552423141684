import { useEffect, useState, useMemo } from 'react'
import { useQuery, useQueries } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import driverMapApi from 'apis/disApi/driverMapApi'

const driverMapEmailKey = {
	prefixKey: ['getDrives'],
	drives: (userId, from, to, showHistory) => [
		...driverMapEmailKey.prefixKey,
		userId,
		from,
		to,
		showHistory,
	],
}
export const useDrives = ({ userId, fromDate, toDate, showHistory }) => {
	const environment = useSelector((state) => state.environment)
	const queryKey = useMemo(() => {
		if (!userId) return null
		return driverMapEmailKey.drives(userId, fromDate, toDate, showHistory)
	}, [userId, fromDate, toDate, showHistory])

	// const [completedDrives, setCompletedDrives] = useState([])
	// const [activeDrives, setActiveDrives] = useState([])
	// const [allDrives, setAllDrives] = useState([])

	const { data, isFetching } = useQuery({
		queryKey: queryKey,
		queryFn: () =>
			driverMapApi.getDrivesByUserId({
				user_id: userId,
				end_after: fromDate,
				start_before: toDate,
				showHistory,
				userOffset: environment?.userCurrent?.time?.timezone?.offset || 0,
				token: environment?.apiToken,
			}),
		enabled: !!queryKey && !!environment?.apiToken, // Only fetch if enabled
	})

	return {
		allDrives: data?.data?.data || [],
		isLoadingDrives: !userId ? false : isFetching,
	}
}
