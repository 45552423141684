import { FilterCenterFocus, VisibilityRounded } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import WrongLocationIcon from '@mui/icons-material/WrongLocation'
import { Alert, LoadingButton } from '@mui/lab'
import { Box, IconButton, Slide, Stack, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'
import { isEmpty } from 'lodash'
import { Resizable } from 're-resizable'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDate } from '../../../utils/functions/doformsDateUtil'
import { getDeviceName, getFormName } from '../../../utils/functions/helpers'
import { formatElapsed, convertFeetToMeters } from '../../../utils/functions/mapUtils'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { loadForm } from '../dataServices'
import { createMarkerIcon, formatAccuracy } from './mapHelpers'
import { getRecordName } from './mapService'

const useStyles = makeStyles(() => ({
	mapCanvas: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		height: '100%',
		'& .gm-style-iw.gm-style-iw-c': {
			maxHeight: '320px !important',
		},
		'& .gm-style-iw-d': {
			maxHeight: '320px !important',
		},
	},
	alertMessage: {
		width: '300px',
		position: 'absolute',
		top: '20%',
		left: 0,
		right: 0,
		margin: '0 auto',
		zIndex: 99,
	},
	markerPin: {
		color: 'red',
	},
	infoWindow: {
		'& .gm-style-iw-a': {
			left: '-83px',
		},
	},
	formMapCanvasFull: {
		height: 'calc(100% - 65px) !important',
		maxHeight: 'inherit !important',
	},
}))

const DoformsMapCanvas = (props) => {
	const [t] = useTranslation('common')
	const {
		environment,
		mapRecords,
		mapLoading,
		selectedActions,
		selectedPlace,
		formMapRef,
		onHandledActions,
		onHandledSelected,
		onHandledNewMapItemFetched,
		deviceSelected,
	} = props

	const classes = useStyles()
	const mapCanvasRef = useRef()
	const [mapRef, setMapRef] = useState(null)
	const [markerMap, setMarkerMap] = useState({})
	const defaultState = {
		center: { lat: 33.753746, lng: -84.38633 },
		zoom: 5,
	}
	const [mapState, setMapState] = useState(defaultState)
	const { center, zoom } = mapState

	const [infoOpen, setInfoOpen] = useState(false)
	const [infoRefresh, setInfoRefresh] = useState(false)

	const [alertOpen, setAlertOpen] = useState(false)

	const dispatch = useDispatch()

	let mapPointY = 0

	useEffect(() => {
		if (!mapRecords.length) {
			setAlertOpen(true)
			setMapState(defaultState)
		} else {
			setAlertOpen(false)
			fitBounds(mapRef)
		}
	}, [mapRecords])

	useEffect(() => {
		if (isEmpty(selectedPlace)) return
		if (!selectedPlace.latLng) {
			setAlertOpen(true)
			return
		}
		if (selectedPlace.showInfoWindow) {
			if (infoOpen) {
				setInfoOpen(false)
				if (selectedActions.animate) return
				setInfoRefresh(!infoRefresh)
			} else {
				if (selectedActions.animate) return
				setInfoOpen(true)
			}
			return
		} else {
			setInfoOpen(false)
			if (mapRef && selectedPlace) {
				mapRef.setCenter(selectedPlace.latLng)
			}
		}
		if (alertOpen) {
			setAlertOpen(false)
		}
		// if (infoOpen || !selectedPlace.showInfoWindow) {
		//     setInfoOpen(false);

		//     //we only want to show infoWindow when user click to view the marker. Omit open window when go through animation
		//     if(selectedActions.animate) return;

		//     setInfoRefresh(!infoRefresh);
		// }else{

		//     //we only want to show infoWindow when user click to view the marker. Omit open window when go through animation
		//     if(selectedActions.animate) return;

		//     setInfoOpen(true);
		// }
	}, [selectedPlace])

	useEffect(() => {
		setInfoOpen(true)
	}, [infoRefresh])

	useEffect(() => {
		if (!selectedActions.recenter) return
		fitBounds(mapRef)
		onHandledActions('recenter') //this is to cause icon from being in active state
	}, [selectedActions.recenter])

	const showNoRecordsAlert = useCallback(
		() =>
			!mapLoading &&
			alertOpen && (
				<Slide direction="up" in={true} container={mapCanvasRef.current}>
					<Alert
						severity={'warning'}
						icon={<WrongLocationIcon fontSize="inherit" />}
						onClose={() => setAlertOpen(false)}
						variant="filled"
						className={classes.alertMessage}
					>
						{t('common:map.noMappingRecordToDisplay')}
					</Alert>
				</Slide>
			),
		[mapLoading, alertOpen]
	)

	const fitBounds = (map) => {
		if (!map) return
		const bounds = new window.google.maps.LatLngBounds()
		if (!mapRecords.length) return
		mapRecords.map((place) => {
			// if(place.latLng) {
			//     bounds.extend(place.latLng);
			// }
			if (place.pins) {
				place.pins.map((pin) => {
					if (pin.position) {
						bounds.extend({ lat: pin.position.latitude, lng: pin.position.longitude })
					}
				})

				// bounds.extend(place.latLng);
			}
			return place.key
		})
		map.fitBounds(bounds)
		setMapState({ ...mapState, center: map.getCenter(), zoom: map.getZoom() })
	}

	const loadHandler = (map) => {
		// Store a reference to the google map instance in state
		setMapRef(map)
		// Fit map bounds to contain all markers
		fitBounds(map)
	}

	// We have to create a mapping of our places to actual Marker objects
	const markerLoadHandler = (marker, place) => {
		return setMarkerMap((prevState) => {
			return { ...prevState, [place.key]: marker }
		})
	}

	const onSelect = (place) => {
		if (selectedPlace) {
			handleInfoWindowClosed()
			if (selectedPlace.key === place.key) {
				return
			}
		}

		onHandledSelected(place, true)
	}

	const handleGetFormName = (record) => {
		if (!record.formKey) {
			record.form = ''
			onHandledNewMapItemFetched(record)
			return
		}

		let formName = getFormName(record.formKey, environment.forms)
		if (formName && !isEmpty(formName)) {
			record.form = formName
			onHandledNewMapItemFetched(record)
			return
		}
		let value = record
		value.formLoading = true
		onHandledNewMapItemFetched(value)
		loadForm(record.formKey, environment.apiToken)
			.then((response) => {
				value.form = response.data.name
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: [response.data],
				})
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				value.formLoading = false
				onHandledNewMapItemFetched(value)
			})
	}

	const handleGetRecordName = (record) => {
		let value = record
		value.recordLoading = true
		onHandledNewMapItemFetched(value)
		getRecordName(record.recordKey, environment.apiToken)
			.then((res) => {
				value.recordName = res.data.name
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				value.recordLoading = false
				onHandledNewMapItemFetched(value)
			})
	}

	const handleGetAddress = (record) => {
		if (isEmpty(record.position)) return
		const location = new window.google.maps.LatLng(
			record.position.latitude,
			record.position.longitude
		)
		const geocoder = new window.google.maps.Geocoder()
		let value = record
		value.addressLoading = true
		onHandledNewMapItemFetched(value)

		geocoder.geocode({ location: location }, (res) => {
			if (!res.length) {
				value.address = '*No address found'
				value.addressLoading = false
			} else {
				value.address = res[0].formatted_address
				value.addressLoading = false
			}
			onHandledNewMapItemFetched(value)
		})
	}

	const handleInfoWindowClosed = () => {
		setInfoOpen(false)
		onHandledSelected(null, false)
	}

	const handleResizeMapStart = (e, dir) => {
		mapPointY = formMapRef.current.scrollTop
	}

	const handleResizingMap = (event, direction, refToElement, delta) => {
		formMapRef.current.scrollTop = mapPointY
	}

	const zoomToCenter = (e) => {
		if (mapRef) {
			mapRef.setCenter(selectedPlace.latLng)
			mapRef.setZoom(17)
		}
	}

	const clickHandler = (e, target) => {
		switch (target) {
			case 'history':
				onHandledActions('history')
				break
			case 'view':
				onHandledActions('view')
				break
			default:
				break
		}
	}

	const showInfoWindow = () => {
		if (mapRef) {
			mapRef.setCenter(selectedPlace.latLng)
		}
		let showInfoWindowDataSet = []
		let showHistoryBtn = false
		let showViewBtn = false
		let showTeamDevice4Report = false
		if (deviceSelected && !deviceSelected.key) {
			showTeamDevice4Report = true
		}
		//['device', 'time', 'status', 'description', 'accuracy', 'form', 'record', 'address']: tab === 'devices'?['time', 'status', 'accuracy', 'address']:
		switch (selectedPlace.type) {
			case 'dispatch':
				showInfoWindowDataSet = ['time', 'status', 'accuracy', 'form', 'record', 'address']
				showHistoryBtn = false
				showViewBtn = false
				break
			case 'event':
				showInfoWindowDataSet = ['time', 'status', 'accuracy', 'form', 'record', 'address']
				showHistoryBtn = true
				showViewBtn = true
				break
			case 'track':
				showInfoWindowDataSet = ['time', 'status', 'accuracy', 'address']
				break
			case 'location':
				showInfoWindowDataSet = [
					'time',
					'status',
					'description',
					'accuracy',
					'form',
					'record',
					'address',
				]
				break
			case 'geofence':
				showInfoWindowDataSet = [
					'time',
					'status',
					'description',
					'duration',
					'accuracy',
					'record',
					'address',
				]
				break
			case 'timesheet':
				showInfoWindowDataSet = ['time', 'status', 'description', 'accuracy', 'address']
				break
			case 'trip_report':
				if (showTeamDevice4Report) {
					showInfoWindowDataSet = ['device', 'status', 'beginDate', 'address', 'accuracy']
				} else {
					showInfoWindowDataSet = ['status', 'beginDate', 'address', 'accuracy']
				}
				break
			case 'stop_report':
				if (showTeamDevice4Report) {
					showInfoWindowDataSet = [
						'device',
						'status',
						'beginDate',
						'duration',
						'accuracy',
						'address',
					]
				} else {
					showInfoWindowDataSet = ['status', 'endDate', 'duration', 'accuracy', 'address']
				}
				break
			case 'geofence_report':
				showInfoWindowDataSet = ['status', 'beginDate', 'duration', 'accuracy', 'address']
				break
			case 'timesheet_report':
				if (showTeamDevice4Report) {
					showInfoWindowDataSet = [
						'device',
						'status',
						'beginDate',
						'duration',
						'accuracy',
						'address',
					]
				} else {
					showInfoWindowDataSet = ['status', 'beginDate', 'duration', 'accuracy', 'address']
				}
				break
			case 'track_pin':
				showInfoWindowDataSet = ['status', 'time', 'address', 'accuracy']
				break
			case 'device':
				showInfoWindowDataSet = ['device', 'time', 'status', 'accuracy', 'address']
				break
			default:
				break
		}
		return (
			<>
				<InfoWindow
					anchor={markerMap[selectedPlace.key]}
					/*position={selectedPlace.latLng}*/
					onCloseClick={handleInfoWindowClosed}
					/*options={{pixelOffset: new window.google.maps.Size(25, 0)}}*/
					sx={{ flexGrow: 1, overflow: 'hidden' }}
				>
					<Box sx={{ width: '30em' }}>
						{showInfoWindowDataSet.map((item, index) => {
							return (
								<Stack key={index} flexDirection="row" alignItems="center">
									<Typography sx={{ minWidth: 150 }} fontWeight="bold">
										{getRecordInfoWindowLabel(item)}
									</Typography>
									<Typography sx={{ flex: 1 }}>
										{getRecordInfoWindowValue(item, selectedPlace, environment)}
									</Typography>
								</Stack>
							)
						})}
						<Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
							{showHistoryBtn && (
								<IconButton
									aria-label="audit"
									size="small"
									color="inherit"
									edge="end"
									title={`History`}
									onClick={(e) => clickHandler(e, 'history')}
								>
									<AccessTimeIcon fontSize="inherit" className={classes.icon} />
								</IconButton>
							)}
							{showViewBtn && (
								<IconButton
									aria-label="audit"
									size="small"
									color="inherit"
									edge="end"
									title={`View`}
									onClick={(e) => clickHandler(e, 'view')}
								>
									<VisibilityRounded fontSize="inherit" className={classes.icon} />
								</IconButton>
							)}
							<Tooltip
								title={`${t('tooltip.zoomInToThisLocation')}`}
								arrow
								placement="bottom-start"
								disableInteractive
							>
								<IconButton
									id="zoomIn"
									color="inherit"
									sx={{ m: 0.5 }}
									size="small"
									onClick={(e) => zoomToCenter(e)}
									aria-label="Center"
								>
									<FilterCenterFocus
										style={{
											color: selectedActions.recenter
												? environment.theme.icons.active.color
												: environment.theme.icons.color,
										}}
										fontSize="small"
									/>
								</IconButton>
							</Tooltip>
						</Stack>
					</Box>
				</InfoWindow>
			</>
		)
	}

	const getRecordInfoWindowLabel = (item) => {
		if (!item) return ''

		switch (item) {
			case 'device':
				return t('common:misc.device')
			case 'beginDate':
			case 'endDate':
			case 'time':
				return t('common:misc.time')
			case 'status':
				return t('common:misc.status')
			case 'description':
				return t('common:misc.description')
			case 'accuracy':
				return t('common:misc.accuracy')
			case 'form':
				return t('common:misc.form')
			case 'record':
				return t('common:misc.recordName')
			case 'address':
				return t('common:misc.address')
			case 'duration':
				return t('common:misc.duration')
			default:
				return ''
		}
	}

	const formatTimeHMA = (dateValue) => {
		return formatDate(
			dateValue,
			environment.userCurrent.time.timezone.isDst
				? environment.userCurrent.time.timezone.offset - 60
				: environment.userCurrent.time.timezone.offset,
			'h:mm a'
		)
	}

	const getRecordInfoWindowValue = (item, record, environment) => {
		if (!item) return
		if (isEmpty(record)) return

		switch (item) {
			case 'device':
				return record.type !== 'device'
					? record.device
					: getDeviceName(record.key, environment.devices)
			case 'time':
				if (record.type === 'device') {
					return formatDate(record.time, null, 'h:mm a') || ''
				} else if (record.type === 'track_pin') {
					return formatTimeHMA(record.date) || ''
				} else {
					return record.time || ''
				}
			case 'beginDate':
				return formatTimeHMA(record.beginDate) || ''
			case 'endDate':
				return formatTimeHMA(record.endDate) || ''
			case 'status':
				if (record.type === 'track_pin') {
					return record.title || ''
				} else {
					return record.status || ''
				}
			case 'description':
				return record.description || ''
			case 'accuracy':
				const isMetricUser = environment?.userCurrent?.units === 'metric'
				return isMetricUser
					? convertFeetToMeters(formatAccuracy(record.position.accuracy || 0))
					: formatAccuracy(record.position.accuracy || 0)
			case 'form':
				/*let formName = getFormName(record.formKey, environment.forms);
                if(!formName && record.formKey){

                    loadForm(record.formKey, environment.apiToken).then(response => {
                        formName = response.data.name;
                        dispatch({
                            type: ENV_ACTIONS.GET_FORMS,
                            payload: [response.data]
                        })
                    }).catch((err) => {
                    })
                }
                if(record.type === 'event'){
                    return record.formKey ? formName : '';
                }else{
                    return record.formKey ? getProjectName(record.projectKey, environment.projects) + " / " + getFormName(record.formKey, environment.forms) : '';
                }*/
				return record.form ? (
					record.form
				) : (
					<LoadingButton
						size="small"
						variant="outlined"
						color="inherit"
						loading={record.formLoading}
						sx={{ textTransform: 'none', lineHeight: '1.3em' }}
						onClick={() => handleGetFormName(record)}
					>
						{t('map.button.getFormName')}
					</LoadingButton>
				)
			case 'record':
				return record.recordName ? (
					record.recordName
				) : (
					<LoadingButton
						size="small"
						variant="outlined"
						color="inherit"
						loading={record.recordLoading}
						sx={{ textTransform: 'none', lineHeight: '1.3em' }}
						onClick={() => handleGetRecordName(record)}
					>
						{t('map.button.get')}
					</LoadingButton>
				)
			case 'address':
				return record.address ? (
					record.address
				) : (
					<LoadingButton
						size="small"
						variant="outlined"
						color="inherit"
						loading={record.addressLoading}
						sx={{ textTransform: 'none', lineHeight: '1.3em' }}
						onClick={() => handleGetAddress(record)}
					>
						{t('map.button.get')}
					</LoadingButton>
				)
			case 'duration':
				return formatElapsed(record.duration) || ''
			default:
				return ''
		}
	}

	const renderMap = () => {
		return (
			<GoogleMap
				onLoad={loadHandler}
				center={center}
				zoom={zoom}
				mapContainerStyle={{
					height: '100%',
					width: '100%',
				}}
				options={{
					gestureHandling: 'greedy',
					styles: [
						{
							featureType: 'poi',
							stylers: [{ visibility: selectedActions.poi ? 'on' : 'off' }],
						},
					],
				}}
			>
				{mapRecords
					? mapRecords.map(
							(place, index) => {
								if (place.type !== 'trip_report') {
									return (
										<Marker
											key={place.key}
											name={`Marker ${index}`}
											position={place.latLng}
											markerMap={markerMap}
											onLoad={(marker) => markerLoadHandler(marker, place)}
											onClick={() => onSelect(place)}
											icon={createMarkerIcon(
												place,
												selectedPlace ? selectedPlace.key === place.key : false
											)}
										/>
									)
								} else {
									let mks = []
									place.pins.map((pin, pinIndex) => {
										pin.color = place.color
										pin.latLng = { lat: pin.position.latitude, lng: pin.position.longitude }
										mks.push(
											<Marker
												key={pin.key}
												name={`Marker ${pinIndex}`}
												position={{ lat: pin.position.latitude, lng: pin.position.longitude }}
												markerMap={markerMap}
												onLoad={(marker) => markerLoadHandler(marker, pin)}
												onClick={() => onSelect(pin)}
												icon={createMarkerIcon(
													pin,
													selectedPlace ? selectedPlace.key === place.key : false
												)}
											/>
										)
									})
									return mks
								}
							}
							// {place.type === "trip_report"?
							// place.pins.map((pin, pinIndex) => <Marker key={pin.key}
							//                                           name={`Marker ${pinIndex}`}
							//                                           position={{lat: pin.position.latitude, lng: pin.position.longitude}}
							//                                           markerMap={markerMap}
							//                                           onLoad={marker => markerLoadHandler(marker, pin)}
							//                                           onClick={() => onSelect(pin)}
							//                                           icon={createMarkerIcon(pin, selectedPlace ? selectedPlace.key === place.key : false)}
							//                                     />
							// ): <Marker
							//     key={place.key}
							//     name={`Marker ${index}`}
							//     position={place.latLng}
							//     markerMap={markerMap}
							//     onLoad={marker => markerLoadHandler(marker, place)}
							//     onClick={() => onSelect(place)}
							//     icon={createMarkerIcon(place, selectedPlace ? selectedPlace.key === place.key : false)}
							// />}
					  )
					: null}
				{infoOpen && selectedPlace && showInfoWindow()}
			</GoogleMap>
		)
	}

	return (
		<Resizable
			className={selectedActions.report ? '' : classes.formMapCanvasFull}
			defaultSize={{ height: '30vh', minHeight: 400 }}
			minHeight={300}
			maxHeight={'60vh'}
			handleStyles={{ bottom: { cursor: 'ns-resize' } }}
			enable={{
				top: false,
				right: false,
				bottom: true,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			onResizeStart={handleResizeMapStart}
			onResize={handleResizingMap}
		>
			<div ref={mapCanvasRef} className={classes.mapCanvas}>
				{showNoRecordsAlert()}
				{renderMap()}
			</div>
		</Resizable>
	)
}

export default DoformsMapCanvas
