import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import driverMapApi from 'apis/disApi/driverMapApi'

const driverMapEmailKey = {
	prefixKey: ['getDriverInfo'],
	users: ['driverEmail', 'users'],
}

export function useDriverEmailQuery(props) {
	const { userEmail } = props
	const environment = useSelector((state) => state.environment)
	const [driverInfoByEmail, setDriverInfoByEmail] = useState(null)

	const queryKey = useMemo(() => {
		return ['getDriveUserByEmail', userEmail]
	}, [userEmail])

	const {
		data,
		isLoading: isLoadingAllUsers,
		isFetching: isFetchingAllUsers,
	} = useQuery({
		queryKey: queryKey,
		queryFn: () => driverMapApi.getUserByEmail(userEmail, environment?.apiToken),
		enabled: !!userEmail && !isEmpty(environment?.apiToken),
	})

	useEffect(() => {
		if (isEmpty(userEmail)) {
			setDriverInfoByEmail(null)
			return
		}

		if (isLoadingAllUsers || isFetchingAllUsers) return

		const userByEmail = data?.data?.data
		if (isEmpty(userByEmail)) {
			setDriverInfoByEmail(null)
			return
		}

		setDriverInfoByEmail(userByEmail)
	}, [isLoadingAllUsers, isFetchingAllUsers, data, userEmail])

	return {
		driverInfoByEmail,
		isLoadingUser: !userEmail ? false : isLoadingAllUsers || isFetchingAllUsers,
	}
}
