import { Fullscreen, MoreVert } from '@mui/icons-material'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { checkAdminRole } from 'utils/functions/helpers'
import ExpandedDialog from '../../dis-v2/_components/ExpandedDialog'
import { isEmpty } from 'lodash'

const TileWrapper = forwardRef(
	({ children, title, hidden, onSettingClick, isExpandDialogBtn, tooltipValue }, ref) => {
		const { t } = useTranslation('common')
		const [expandedDialogOpen, setExpendedDialogOpen] = useState(false)
		const { environment } = useSelector((state) => state)

		const isAdmin = checkAdminRole(environment?.userCurrent)

		const handleOpenExpandedDialog = () => {
			setExpendedDialogOpen(true)
		}

		const handleCloseExpandedDialog = () => {
			setExpendedDialogOpen(false)
		}

		return (
			<Box
				ref={ref}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					boxShadow: 1,
					backgroundColor: blueGrey[50],
					position: 'relative',
					overflowX: 'hidden',
				}}
			>
				<Stack px={2} direction="row" alignItems="center" justifyContent="space-between">
					{isEmpty(tooltipValue) ? (
						<Typography
							fontWeight="bold"
						>
							{title}
						</Typography>
					) : (
						<Tooltip title={tooltipValue} placement='top'>
							<Typography
								fontWeight="bold"
							>
								{title}
							</Typography>
						</Tooltip>
					)}
					{isAdmin && !hidden && (
						<Stack direction="row" alignItems="center" spacing={2}>
							{isExpandDialogBtn && (
								<Tooltip title={t('common:tooltip.expand')}>
									<IconButton aria-label="expand" onClick={handleOpenExpandedDialog}>
										<Fullscreen />
									</IconButton>
								</Tooltip>
							)}
							<IconButton aria-label="delete" size="small" onClick={onSettingClick}>
								<MoreVert fontSize="small" />
							</IconButton>
						</Stack>
					)}
				</Stack>

				<Box sx={{ flex: 1 }}>{children}</Box>

				{/* Expanded dialog with this screen */}
				{expandedDialogOpen && (
					<ExpandedDialog
						title={title}
						open={expandedDialogOpen}
						onClose={handleCloseExpandedDialog}
						tooltipValue={tooltipValue}
					>
						{children}
					</ExpandedDialog>
				)}
			</Box>
		)
	}
)

export default TileWrapper
