import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep } from 'lodash'
import { enUS } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { TextField, Box, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { toDayjs } from 'utils/functions/doformsDateUtil'
import { getDriverMapFieldList } from '../map/helper/functions'
import DashboardList from '../DashboardKeyList'
import { parseAs } from 'utils/functions/doformsDateUtil'

export default function DriverEventsForm(props) {
	const { filterData, dashboardKeyList, onFilterDataChange } = props
	const { t } = useTranslation('common')

	const fieldList = useMemo(() => getDriverMapFieldList(t), [t])
	return (
		<Stack spacing={2}>
			<TextField
				size="small"
				id="userEmail"
				label={'User email'}
				value={filterData.eventData?.userEmail}
				onChange={(e) => {
					const value = e.target.value
					onFilterDataChange('eventData', { ...filterData.eventData, userEmail: value })
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>

			<DateTimeRangePicker
				fromDate={filterData.eventData?.fromDate}
				toDate={filterData.eventData?.toDate}
				onFilters={(name, value) =>
					onFilterDataChange('eventData', { ...filterData.eventData, [name]: value })
				}
			/>

			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={fieldList}
					onFieldsChange={(id, value) => {
						if (isEmpty(value)) {
							const currentLinkedFields = cloneDeep(filterData?.linkedFields)
							if (currentLinkedFields[id]) {
								delete currentLinkedFields[id]
							}
							onFilterDataChange('linkedFields', currentLinkedFields)
							return
						}
						onFilterDataChange('linkedFields', { ...filterData.linkedFields, [id]: value })
					}}
					linkedFields={filterData.linkedFields || {}}
					getLabelOption={(item) => item.label}
				/>
			</Box>
		</Stack>
	)
}

function DateTimeRangePicker(props) {
	const { fromDate, toDate, onFilters, disableFilters } = props
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)
	const { environment } = useSelector((state) => state)
	const userOffset = useMemo(() => {
		return environment?.userCurrent?.time?.timezone?.offset || 0
	}, [environment])

	const displayFromDate = useMemo(() => {
		if (!fromDate) return null
		const currentUTCMoment = moment
			.utc(fromDate)
			.utcOffset(userOffset)
			.format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, fromDate])

	const displayToDate = useMemo(() => {
		if (!toDate) return null
		const currentUTCMoment = moment.utc(toDate).utcOffset(userOffset).format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, toDate])

	const handleFromDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())
		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		const startDayMoment = momentValue.clone().startOf('day')
		const userMoment = parseAs(startDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		onFilters?.('fromDate', startDayUTC)
	}

	const handleToDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('toDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		const endDayMoment = momentValue.clone().endOf('day')
		const userMoment = parseAs(endDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		onFilters?.('toDate', startDayUTC)
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} locale={enUS}>
			<DatePicker
				id="map-date"
				label={t('common:input.fromDate')}
				disabled={disableFilters}
				value={displayFromDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleFromDateChange}
				onAccept={handleFromDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleFromDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>

			<DatePicker
				id="map-date"
				label={t('common:input.toDate')}
				disabled={disableFilters}
				value={displayToDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleToDateChange}
				onAccept={handleToDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',

							// Turn off the error when the user clears the date
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleToDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>
		</LocalizationProvider>
	)
}
