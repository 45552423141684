import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep } from 'lodash'
import { enUS } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { FormControlLabel, TextField, Box, Checkbox } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DashboardList from '../../DashboardKeyList'

import { parseAs, toDayjs } from 'utils/functions/doformsDateUtil'
import { getMapLoggedInInfoFromStorageByGPSSource } from '../helper/functions'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from '../../../hooks/useVehicleLocation'
import GeoMapFilters from '../geotab-map/GeoMapFilters'

export const MIN_REPEAT_RELOAD_MAP_VALUE = 5
export const MAX_REPEAT_RELOAD_MAP_VALUE = 60

export const DriverMapForm = (props) => {
	const { filterData, linkedFields, dashboardKeyList, onFilterDataChange } = props
	const { t } = useTranslation('common')

	const FIELD_LIST = [
		{
			name: 'userEmail',
			label: 'User email',
		},
		{
			name: 'fromDate',
			label: t('common:input.fromDate'),
			type: 'DATE',
		},
		{
			name: 'toDate',
			label: t('common:input.toDate'),
			type: 'DATE',
		},
	]

	const [repeatReloadMapError, setRepeatReloadMapError] = useState('')

	// get user info from storage
	// const userInfo = getMapLoggedInInfoFromStorageBGPSSource(filterData.gpsSource)

	// const isLoggedIn = !isEmpty(userInfo)

	// if (!isLoggedIn) {
	// 	return <DriverMapLoginForm />
	// }

	return (
		<>
			<TextField
				size="small"
				id="userEmail"
				label={'User email'}
				value={filterData.userEmail}
				onChange={(e) => {
					const value = e.target.value
					onFilterDataChange('userEmail', value)
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showHistory')}
				control={
					<Checkbox
						checked={filterData?.showHistory ?? false}
						onChange={() => onFilterDataChange('showHistory', !filterData.showHistory)}
						name={t('common:input.showHistory')}
					/>
				}
			/>
			{filterData?.showHistory && (
				<DateTimeRangePicker
					fromDate={filterData.locationData?.fromDate}
					toDate={filterData.locationData?.toDate}
					onFilters={(name, value) =>
						onFilterDataChange('locationData', { ...filterData.locationData, [name]: value })
					}
				/>
			)}

			<TextField
				size="small"
				id="repeat-reload-map-value"
				label={t('common:dis.autoRefreshInterval')}
				type="number"
				value={filterData.repeatReloadMapSeconds || DEFAULT_REPEAT_RELOAD_MAP_SECONDS}
				onChange={(e) => {
					const value = e.target.value
					onFilterDataChange('repeatReloadMapSeconds', value)
					if (value < MIN_REPEAT_RELOAD_MAP_VALUE || value > MAX_REPEAT_RELOAD_MAP_VALUE) {
						setRepeatReloadMapError('Repeat reload map value must be between 5 and 60')
					} else {
						setRepeatReloadMapError('')
					}
				}}
				error={Boolean(repeatReloadMapError)}
				helperText={repeatReloadMapError || ''}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
					min: MIN_REPEAT_RELOAD_MAP_VALUE,
					max: MAX_REPEAT_RELOAD_MAP_VALUE,
				}}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showFilters')}
				control={
					<Checkbox
						checked={filterData?.showFilters ?? false}
						onChange={() => onFilterDataChange('showFilters', !filterData.showFilters)}
						name={t('common:input.showFilters')}
					/>
				}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showPointsOfInterest')}
				control={
					<Checkbox
						checked={filterData?.pointOfInterest ?? false}
						onChange={() => onFilterDataChange('pointOfInterest', !filterData.pointOfInterest)}
						name={t('common:input.showPointsOfInterest')}
					/>
				}
			/>
			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={FIELD_LIST}
					onFieldsChange={(id, value) => {
						if (isEmpty(value)) {
							const currentLinkedFields = cloneDeep(filterData?.linkedFields)
							if (currentLinkedFields[id]) {
								delete currentLinkedFields[id]
							}
							onFilterDataChange('linkedFields', currentLinkedFields)
							return
						}
						onFilterDataChange('linkedFields', { ...filterData.linkedFields, [id]: value })
					}}
					linkedFields={filterData.linkedFields || {}}
					getLabelOption={(item) => item.label}
				/>
			</Box>
		</>
	)
}

function DateTimeRangePicker(props) {
	const { fromDate, toDate, onFilters, disableFilters } = props
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)
	const { environment } = useSelector((state) => state)
	const userOffset = useMemo(() => {
		return environment?.userCurrent?.time?.timezone?.offset || 0
	}, [environment])

	const displayFromDate = useMemo(() => {
		if (!fromDate) return null
		const currentUTCMoment = moment
			.utc(fromDate)
			.utcOffset(userOffset)
			.format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, fromDate])

	const displayToDate = useMemo(() => {
		if (!toDate) return null
		const currentUTCMoment = moment.utc(toDate).utcOffset(userOffset).format('MM/DD/YYYY HH:mm:ss')
		return toDayjs(currentUTCMoment)
	}, [userOffset, toDate])

	const handleFromDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())
		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		const startDayMoment = momentValue.clone().startOf('day')
		const userMoment = parseAs(startDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		onFilters?.('fromDate', startDayUTC)
	}

	const handleToDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('toDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		const selectMomentValue = moment(newValue?.toDate())
		const dateString = selectMomentValue.format('YYYY-MM-DD HH:mm:ss')
		const momentValue = moment(dateString)
		if (!momentValue.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		const endDayMoment = momentValue.clone().endOf('day')
		const userMoment = parseAs(endDayMoment, userOffset)
		// to UTC time
		const startDayUTC = userMoment.toISOString()
		onFilters?.('toDate', startDayUTC)
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} locale={enUS}>
			<DatePicker
				id="map-date"
				label={t('common:input.fromDate')}
				disabled={disableFilters}
				value={displayFromDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleFromDateChange}
				onAccept={handleFromDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleFromDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>

			<DatePicker
				id="map-date"
				label={t('common:input.toDate')}
				disabled={disableFilters}
				value={displayToDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleToDateChange}
				onAccept={handleToDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',

							// Turn off the error when the user clears the date
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleToDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>
		</LocalizationProvider>
	)
}
