import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Box } from '@mui/material'
import GeoMapInfo from './GeoMapInfo'
import GeoMapLoginForm from './GeoMapLoginForm'
import MapSettingsDialog from '../MapSettingsDialog'

import mapApi from 'apis/disApi/mapApi'

import { MAP_LOGGED_IN_INFO } from '../../../../../../constants'
import useForceRerender from 'utils/hooks/useForceRerender'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import {
	getMapLoggedInInfoFromStorage,
	isJson,
	logErrorMessage,
} from '../../../../../../utils/functions/helpers'

export function GeoMapTileContent(props) {
	const environment = useSelector((state) => state.environment)
	const { id: dashboardKey } = useParams()
	const { isMapLogout } = useSearchParams()

	const {
		tile,
		formMapRef,
		updateTile,
		settingsOpen,
		setSettingsOpen,
		handleCloseDialog,
		handleResizeTileWidth,
		handleSubmit,
		showFilters,
		repeatReloadMapSeconds,
		showHistory,
		pointOfInterest,
		locationData,
		linkedFields,
		dashboardKeyList,
	} = props
	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !isEmpty(userInfo) && isMapLogout !== 'true'

	const [, triggerReRender] = useForceRerender()

	const setSessionIdToLS = async () => {
		try {
			const response = await mapApi.resetSession(environment.apiToken)
			const userValues = response?.data

			if (!userValues) return
			localStorage.setItem(MAP_LOGGED_IN_INFO, JSON.stringify(userValues))

			// Trigger re-render to update map
			triggerReRender()
		} catch (error) {
			// Clear session id if error 403
			if (error?.response?.status === 403) {
				localStorage.removeItem(MAP_LOGGED_IN_INFO)
			}
			setSettingsOpen(false)
			logErrorMessage(error)
		}
	}

	const {
		data: vehicleList,
		isLoading: vehicleLoading,
		isFetching: vehicleFetching,
		refetch,
	} = useQuery({
		queryKey: ['vehicle-list', tile.key, userInfo],
		queryFn: async () => {
			const response = await mapApi.getAllDevices(userInfo)
			// Trim name to remove white spaces because API returns some names with white spaces
			return response?.data?.map((item) => ({ ...item, name: item.name.trim() })) ?? []
		},
		onError: async (error) => {
			// Reset sessionId if sessionId expired
			if (error?.response?.status === 401) {
				await setSessionIdToLS()
				refetch()
			}
		},
		retry: false,
		enabled: Boolean(isLoggedIn),
	})

	const isVehicleLoading = vehicleLoading || vehicleFetching

	useEffect(() => {
		if (isLoggedIn) return
		;(async () => {
			await setSessionIdToLS()
		})()
	}, [isLoggedIn])

	return (
		<>
			{isLoggedIn ? (
				<GeoMapInfo
					titleKey={tile?.key}
					vehicleList={vehicleList}
					vehicleLoading={isVehicleLoading}
					userInfo={userInfo}
					showFilters={showFilters}
					repeatReloadMapSeconds={repeatReloadMapSeconds}
					showHistory={showHistory}
					pointOfInterest={pointOfInterest}
					locationData={locationData}
					linkedFields={linkedFields}
					dashboardKeyList={dashboardKeyList}
				/>
			) : (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<GeoMapLoginForm />
				</Box>
			)}

			<MapSettingsDialog
				defaultTileWidth={300}
				tileElementWidth={formMapRef?.current?.clientWidth}
				open={settingsOpen}
				tile={tile}
				isSubmitting={updateTile?.isLoading}
				vehicleList={vehicleList}
				vehicleLoading={vehicleLoading}
				locationData={locationData}
				showFilters={showFilters}
				repeatReloadMapSeconds={repeatReloadMapSeconds}
				showHistory={showHistory}
				pointOfInterest={pointOfInterest}
				dashboardKeyList={dashboardKeyList}
				linkedFields={linkedFields}
				onClose={handleCloseDialog}
				onResizeTileWidth={handleResizeTileWidth}
				onSubmit={handleSubmit}
			/>
		</>
	)
}
