import { Close as CloseIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { cloneDeep, isEmpty, sortBy } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import colorScheme from 'utils/functions/colorsOptions'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import useForceRerender from 'utils/hooks/useForceRerender'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import DoformsRecordsActions from '../datagrid/DoformsRecordsActions'
import DoformsMapCanvas from './DoformsMapCanvas'
import DoformsMapFilters from './DoformsMapFilters'
import DoformsMapGrid from './DoformsMapGrid'
import { generateMapItemProps } from './mapHelpers'
import {
	getDeviceEvents,
	getDeviceGeofences,
	getDeviceGeofencesReport,
	getDeviceMapLocations,
	getDeviceStopReport,
	getDeviceTimeSheets,
	getDeviceTimeSheetsReport,
	getDeviceTracks,
	getDeviceTripReport,
	getDispatchEvents,
	getFormMapEvents,
	getFormMapLocations,
	getRecordName,
	getTeamDeviceStopReport,
	getTeamDeviceTimeSheetsReport,
	getTeamDeviceTripReport,
	getTeamDevices,
} from './mapService'

const useStyles = makeStyles(() => ({
	headingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingLeft: '10px',
	},
	headingContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	headingLabel: {
		display: 'block',
		paddingLeft: '10px',
		minHeight: '32px',
	},
	headingAction: {
		display: 'flex',
		alignItems: 'center',
	},
	dataGrid: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
		'& .MuiDataGrid-footerContainer': {
			width: '100%',
			display: 'inline-flex',
			justifyContent: 'end',
			'& .MuiTablePagination-selectLabel': {
				marginTop: '1em',
			},
			'& .MuiTablePagination-displayedRows': {
				marginTop: '1em',
			},
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			overflow: 'hidden',
			lineHeight: '20px',
			whiteSpace: 'normal',
			fontSize: '14px',
		},
	},
	formMap: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		paddingTop: '10px',
		overflow: 'auto',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	noMinHeight: {
		minHeight: '0px !important',
	},
}))

const DoformsMap = (props) => {
	const [t] = useTranslation('common')

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const {
		action,
		environment,
		viewData,
		title,
		formSelected,
		deviceSelected,
		tab,
		module,
		onHandleShowLoading,
		onHandleOpenFullScreenFormsTab,
		onHandleCloseMap,
	} = props
	const [showMap, setShowMap] = useState(false)
	const [loading, setLoading] = useState(false)
	const [recordName, setRecordName] = useState('')

	const sendDispatch = useDispatch()
	const formMapRef = useRef(null)
	const [reRenderNumber, forceRender] = useForceRerender()
	const isFirstRender = useFirstRender()

	const offset = environment.userCurrent.time.timezone.isDst
		? environment.userCurrent.time.timezone.offset - 60
		: environment.userCurrent.time.timezone.offset

	const defaultActions = {
		poi: false,
		report: true,
		recenter: false,
		refresh: false,
		help: false,
		animate: false,
		device_formSelectedChangeRefresh: false,
	}

	const defaultDevicesDetailsGroup = [
		{ name: 'devices', label: t('common:filters.devices'), checked: true },
	]

	const defaultDevicesDetails = [
		{ name: 'tracking', label: t('common:filters.tracking'), checked: true },
		{ name: 'geofence', label: t('common:filters.geofence'), checked: false },
		{ name: 'dispatch', label: t('common:filters.dispatch'), checked: false },
		{ name: 'timesheet', label: t('common:misc.time'), checked: false },
		{ name: 'form', label: t('common:filters.form'), checked: false },
	]

	const defaultDevicesReports = [
		{ name: 'trip', label: t('common:filters.trip'), checked: false },
		{ name: 'stop', label: t('common:filters.stop'), checked: false },
		{ name: 'geofence', label: t('common:filters.geofence'), checked: false },
		{ name: 'timesheet', label: t('common:misc.time'), checked: false },
	]

	const defaultDevicesReportsGroup = [
		{ name: 'trip', label: t('common:filters.trip'), checked: false },
		{ name: 'stop', label: t('common:filters.stop'), checked: false },
		{ name: 'timesheet', label: t('common:misc.time'), checked: false },
	]

	const getDefaultDevicesDetails = (deviceSelected) => {
		if (deviceSelected && !deviceSelected.key) {
			return defaultDevicesDetailsGroup
		} else {
			return defaultDevicesDetails
		}
	}

	const getDefaultDevicesReports = (deviceSelected) => {
		if (deviceSelected && !deviceSelected.key) {
			return defaultDevicesReportsGroup
		} else {
			return defaultDevicesReports
		}
	}

	// const defaultFormsDetails = {
	//     dispatch: true,
	//     form: false,
	// }

	const defaultFormsDetails = [
		{ name: 'dispatch', label: t('common:filters.dispatch'), checked: true },
		{ name: 'form', label: t('common:filters.form'), checked: false },
	]

	const infoWindowDataSet =
		tab === 'forms'
			? ['device', 'time', 'status', 'description', 'accuracy', 'form', 'record', 'address']
			: tab === 'devices'
			? ['time', 'status', 'accuracy', 'address']
			: []

	const [dateValue, setDateValue] = useState(moment())
	const [selectedActions, setSelectedActions] = useState(defaultActions)
	const [selectedPlace, setSelectedPlace] = useState(null)
	const [selectedOption, setSelectedOption] = useState('detail')
	const [detailState, setDetailState] = useState(
		tab === 'forms' ? defaultFormsDetails : getDefaultDevicesDetails(deviceSelected)
	)
	const [reportState, setReportState] = useState(
		tab === 'devices' ? getDefaultDevicesReports(deviceSelected) : []
	)
	const [mapItemsLoaded, setMapItemsLoaded] = useState([])

	const [mapRecords, setMapRecords] = useState([])

	const { poi, report, recenter, refresh, help, animate, device_formSelectedChangeRefresh } =
		selectedActions
	const { dispatch, form } = detailState

	const [infoWindowAction, setInfoWindowAction] = useState(null)
	const [actionRecord, setActionRecord] = useState(null)
	const [open, setOpen] = useState(false)
	const [state, setState] = useState(false)

	const [recordLoaded, setRecordLoaded] = useState(false)

	var isRestoreVariable = false
	useEffect(() => {
		if (action === null) return
		if (action !== 'map') {
			setShowMap(false)
			return
		}
		var newFullScreenDataStr = localStorage.getItem('newFullScreenData_map')
		if (newFullScreenDataStr !== null) {
			var newFullScreenData = JSON.parse(newFullScreenDataStr)
			if (newFullScreenData != null && newFullScreenData.mapData != null) {
				var mapData = newFullScreenData.mapData

				setShowMap(mapData.showMap)
				setDateValue(mapData.dateValue)
				setSelectedActions(mapData.selectedActions)
				setSelectedPlace(mapData.selectedPlace)
				setSelectedOption(mapData.selectedOption)
				setDetailState(mapData.detailState)
				setReportState(mapData.reportState)
				setMapItemsLoaded(mapData.mapItemsLoaded)
				setMapRecords(mapData.mapRecords)
				setInfoWindowAction(mapData.infoWindowAction)
				setActionRecord(mapData.actionRecord)
				setOpen(mapData.open)
				setState(mapData.state)

				/*
                setLoading(mapData.loading);
                */

				setRecordLoaded(true)
				isRestoreVariable = true
			}

			localStorage.removeItem('newFullScreenData_map')
		}
	}, [])

	useEffect(() => {
		if (!module.openNewScreenMap) return
		onHandleOpenFullScreenTab()
		sendDispatch({
			type: DEVICES_ACTIONS.DEVICES_OPEN_NEW_SCREEN_MAP,
			payload: false,
		})
	}, [module.openNewScreenMap])

	const handleModalClose = () => {
		setLoading(false)
	}

	const showLoading = () => (
		<Modal
			open={loading}
			onClose={handleModalClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box>
				<LoadingSpinner />
			</Box>
		</Modal>
	)

	const handleDate = (newValue) => {
		forceRender()
		setMapItemsLoaded([])
		setDateValue(newValue)
	}

	const handledActions = (target) => {
		forceRender()
		switch (target) {
			case 'poi':
				setSelectedActions({ ...selectedActions, poi: !poi })
				return
			case 'report':
				setSelectedActions({ ...selectedActions, report: !report })
				return
			case 'recenter':
				setSelectedActions({ ...selectedActions, recenter: !recenter })
				return
			case 'refresh':
				setSelectedActions({ ...selectedActions, refresh: !refresh })
				return
			case 'help':
				setSelectedActions({ ...selectedActions, help: !help })
				return
			case 'animate':
				setSelectedActions({ ...selectedActions, animate: !animate })
				return
			case 'view':
				setInfoWindowAction('view')
				setOpen(true)
				return
			case 'history':
				setInfoWindowAction('history')
				setOpen(true)
				return
			case 'device_formSelectedChangeRefresh':
				setSelectedActions({
					...selectedActions,
					device_formSelectedChangeRefresh: !device_formSelectedChangeRefresh,
				})
				return
			default:
				return
		}
	}

	const handledSelected = (target, isShowInfoWindow) => {
		if (!target) {
			setSelectedPlace(null)
			return
		}
		if (!isEmpty(selectedPlace) && target.key === selectedPlace.key) {
			if (isShowInfoWindow && selectedPlace.showInfoWindow) {
				setSelectedPlace(null)
				return
			} else if (!isShowInfoWindow) {
				setSelectedPlace(null)
				return
			}
		}

		let newSelectedPlace = cloneDeep(target)
		newSelectedPlace.showInfoWindow = isShowInfoWindow

		setSelectedPlace(newSelectedPlace)
	}

	const handledNewMapItemFetched = (target) => {
		if (!target) return
		if (!mapRecords.length) return
		let newMapRecord = mapRecords.map((rec) => {
			if (rec.key === target.key) {
				return target
			} else {
				return rec
			}
		})
		setMapRecords(newMapRecord)
	}

	const handleDetails = (event) => {
		forceRender()

		if (selectedOption === 'report') {
			/*mapItemsLoaded.forEach((stateName) => {
                if (stateName.includes('_report')) {
                    setMapItemsLoaded(mapItemsLoaded.filter(item => item !== stateName));
                    performUnCheckedRecords(stateName);
                }
            });*/
			setDetailState(
				detailState.map((item) => ({
					...item,
					loaded: false,
				}))
			)
			setSelectedOption('detail')
			return
		}
		if (event && !event.target.checked) {
			performUnCheckedRecords(event.target.name + '_detail')
		}
		setDetailState(
			detailState.map((item) => ({
				...item,
				checked: event && item.name === event.target.name ? event.target.checked : item.checked,
				loaded:
					event && item.name === event.target.name && event.target.checked ? false : item.loaded,
			}))
		)
		setSelectedOption('detail')
	}

	const handleReports = (event) => {
		forceRender()

		if (selectedOption === 'detail') {
			/*mapItemsLoaded.forEach((stateName) => {
                if (stateName.includes('_detail')) {
                    setMapItemsLoaded(mapItemsLoaded.filter(item => item !== stateName));
                    performUnCheckedRecords(stateName);
                }
            });*/
			setReportState(
				reportState.map((item) => ({
					...item,
					loaded: false,
				}))
			)
			setSelectedOption('report')
			return
		}
		if (event && !event.target.checked) {
			performUnCheckedRecords(event.target.name + '_report')
		}
		setReportState(
			reportState.map((item) => ({
				...item,
				checked: event && item.name === event.target.name ? event.target.checked : item.checked,
				loaded:
					event && item.name === event.target.name && event.target.checked ? false : item.loaded,
			}))
		)
		setSelectedOption('report')
	}

	const setMapRocordsColor = (mapItems) => {
		let colorInd = 0
		let colors = colorScheme()
		for (let i = 0; i < mapItems.length; i++) {
			let item = mapItems[i]
			if (item.type === 'trip_report') {
				item.color = colors[colorInd]
				colorInd++
				if (colorInd >= colors.length) {
					colorInd = 0
				}
			}
		}
		return mapItems
	}

	const computeMapRecords = (dataSet, type) => {
		if (dataSet && dataSet.length > 0) {
			dataSet = dataSet.map((item) => ({
				...item,
				dataType: type,
			}))
		}
		if (mapRecords.length) {
			if (!dataSet.length) {
				setMapRecords(mapRecords.filter((rec) => rec.dataType !== type))
			} else {
				let modifiedDataSet = computeAdditionalMapRecords(dataSet)
				let newMapItem = computeDifference(modifiedDataSet, mapRecords)
				if (!newMapItem.length) return
				newMapItem = setMapRocordsColor(newMapItem)
				setMapRecords(mapRecords.filter((rec) => rec.dataType !== type).concat(newMapItem))
			}
		} else {
			let newDataSet = computeAdditionalMapRecords(dataSet)
			newDataSet = setMapRocordsColor(newDataSet)
			setMapRecords(newDataSet)
		}

		if (type && type.endsWith('_report')) {
			setReportState(
				reportState.map((item) => ({
					...item,
					loaded: item.name + '_report' === type ? true : item.loaded,
				}))
			)
		} else if (type) {
			switch (type) {
				case 'devices':
				case 'tracking':
				case 'geofence':
				case 'dispatch':
				case 'timesheet':
				case 'form':
					setDetailState(
						detailState.map((item) => ({
							...item,
							loaded: item.name === type ? true : item.loaded,
						}))
					)
					break
				default:
					break
			}
		}
	}

	const computeDispatchEventRecords = () => {
		if (dispatch) {
			if (tab === 'devices') {
				fetchDeviceEvents()
			} else {
				if (module.dispatchRecord4Map != null) {
					fetchDispatchRecordEvents()
				} else {
					fetchDispatchEvents()
				}
			}
		} else {
			if (selectedActions.refresh) return
			removeTypeFromRecords('event')
		}
	}

	const computeFormLocationRecords = () => {
		if (form) {
			if (tab === 'devices') {
				fetchDeviceLocations()
			} else {
				if (module.dispatchRecord4Map != null) {
				} else {
					fetchSubmissionLocations()
				}
			}
		} else {
			if (selectedActions.refresh) return
			removeTypeFromRecords('location')
		}
	}

	//Details section

	const fetchDispatchEvents = () => {
		setLoading(true)
		getFormMapEvents(
			formSelected.projectKey,
			formSelected.key,
			dateValue,
			offset,
			environment.apiToken
		)
			.then((res) => {
				computeMapRecords(res.data, 'event_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "dispatch" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'dispatch_detail'])
				setLoading(false)
			})
	}

	const fetchDispatchRecordEvents = () => {
		setLoading(true)
		getDispatchEvents(module.dispatchRecord4Map.dispatchKey, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'event_detail')
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				setLoading(false)
			})
			.finally(() => {
				setMapItemsLoaded([...mapItemsLoaded, 'dispatch_detail'])
			})
	}

	const fetchSubmissionLocations = () => {
		setLoading(true)
		getFormMapLocations(
			formSelected.projectKey,
			formSelected.key,
			dateValue,
			offset,
			environment.apiToken
		)
			.then((res) => {
				computeMapRecords(res.data, 'location_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'form_detail'])
				setLoading(false)
			})
	}

	const fetchDeviceLocations = () => {
		setLoading(true)
		getDeviceMapLocations(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'location_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'form_detail'])
				setLoading(false)
			})
	}

	const fetchDeviceTracks = () => {
		setLoading(true)
		getDeviceTracks(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'track_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'tracking_detail'])
				setLoading(false)
			})
	}

	const fetchTeamDevices = () => {
		setLoading(true)
		getTeamDevices(deviceSelected.teamKey, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'devices_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'devices_detail'])
				setLoading(false)
			})
	}

	const fetchDeviceEvents = () => {
		setLoading(true)
		getDeviceEvents(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'event_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'dispatch_detail'])
				setLoading(false)
			})
	}

	const fetchDeviceGeofences = () => {
		setLoading(true)
		getDeviceGeofences(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'geofence_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'geofence_detail'])
				setLoading(false)
			})
	}

	const fetchDeviceTimeSheets = () => {
		setLoading(true)
		getDeviceTimeSheets(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'timesheet_detail')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'timesheet_detail'])
				setLoading(false)
			})
	}

	//Reports section

	const fetchDeviceTripReport = () => {
		setLoading(true)
		getDeviceTripReport(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'trip_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'trip_report'])
				setLoading(false)
			})
	}

	const fetchTeamDeviceTripReport = () => {
		setLoading(true)
		getTeamDeviceTripReport(deviceSelected.teamKey, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'trip_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'trip_report'])
				setLoading(false)
			})
	}

	const fetchDeviceStopReport = () => {
		setLoading(true)
		getDeviceStopReport(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'stop_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'stop_report'])
				setLoading(false)
			})
	}

	const fetchTeamDeviceStopReport = () => {
		setLoading(true)
		getTeamDeviceStopReport(deviceSelected.teamKey, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'stop_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'stop_report'])
				setLoading(false)
			})
	}

	const fetchDeviceGeofencesReport = () => {
		setLoading(true)
		getDeviceGeofencesReport(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'geofence_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'geofence_report'])
				setLoading(false)
			})
	}

	const fetchDeviceTimeSheetsReport = () => {
		setLoading(true)
		getDeviceTimeSheetsReport(deviceSelected.key, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'timesheet_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'timesheet_report'])
				setLoading(false)
			})
	}

	const fetchTeamDeviceTimeSheetsReport = () => {
		setLoading(true)
		getTeamDeviceTimeSheetsReport(deviceSelected.teamKey, dateValue, offset, environment.apiToken)
			.then((res) => {
				computeMapRecords(res.data, 'timesheet_report')
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				// setDetailState(detailState.map((item) => ({...item, loaded: item.name === "form" ? true: item.loaded})));
				setMapItemsLoaded([...mapItemsLoaded, 'timesheet_report'])
				setLoading(false)
			})
	}

	const removeTypeFromRecords = (type) => {
		if (!mapRecords) return
		if (!mapRecords.length) return
		let result = mapRecords.filter((rec) => rec.type !== type)
		setMapRecords(result)
	}

	const performCheckedRecords = (stateName) => {
		switch (stateName) {
			case 'dispatch_detail':
				if (tab === 'devices') {
					fetchDeviceEvents()
				} else {
					if (module.dispatchRecord4Map != null) {
						fetchDispatchRecordEvents()
					} else {
						fetchDispatchEvents()
					}
				}
				break
			case 'form_detail':
				if (tab === 'devices') {
					fetchDeviceLocations()
				} else {
					if (module.dispatchRecord4Map != null) {
					} else {
						fetchSubmissionLocations()
					}
				}
				break
			case 'devices_detail':
				fetchTeamDevices()
				break
			case 'tracking_detail':
				fetchDeviceTracks()
				break
			case 'trip_report':
				if (deviceSelected && !deviceSelected.key) {
					fetchTeamDeviceTripReport()
				} else {
					fetchDeviceTripReport()
				}
				break
			case 'stop_report':
				if (deviceSelected && !deviceSelected.key) {
					fetchTeamDeviceStopReport()
				} else {
					fetchDeviceStopReport()
				}
				break
			case 'geofence_detail':
				fetchDeviceGeofences()
				break
			case 'geofence_report':
				fetchDeviceGeofencesReport()
				break
			case 'timesheet_detail':
				fetchDeviceTimeSheets()
				break
			case 'timesheet_report':
				if (deviceSelected && !deviceSelected.key) {
					fetchTeamDeviceTimeSheetsReport()
				} else {
					fetchDeviceTimeSheetsReport()
				}
				break
			default:
				break
		}
	}

	const performUnCheckedRecords = (stateName) => {
		switch (stateName) {
			case 'dispatch_detail':
				removeTypeFromRecords('event')
				break
			case 'form_detail':
				removeTypeFromRecords('location')
				break
			case 'devices_detail':
				removeTypeFromRecords('device')
				break
			case 'tracking_detail':
				removeTypeFromRecords('track')
				break
			case 'trip_report':
				removeTypeFromRecords('trip_report')
				break
			case 'stop_report':
				removeTypeFromRecords('stop_report')
				break
			case 'geofence_detail':
				removeTypeFromRecords('geofence')
				break
			case 'geofence_report':
				removeTypeFromRecords('geofence_report')
				break
			case 'timesheet_detail':
				removeTypeFromRecords('timesheet')
				break
			case 'timesheet_report':
				removeTypeFromRecords('timesheet_report')
				break
			default:
				removeTypeFromRecords(stateName)
				break
		}
	}

	const itemChecked = (name, itemsState) => {
		if (!itemsState) {
			return false
		}
		for (let i = 0; i < itemsState.length; i++) {
			let item = itemsState[i]
			if (item.name == name && item.checked) {
				return true
			}
		}
		return false
	}

	useEffect(() => {
		if (isRestoreVariable) return
		let detail = tab === 'forms' ? defaultFormsDetails : getDefaultDevicesDetails(deviceSelected)
		if (detailState != null) {
			detail = detail.map((item) => {
				let itemFilter = detailState.filter(
					(itemFilter) => itemFilter.name == item.name && itemFilter.checked
				)
				if (itemFilter.length > 0) {
					item.checked = true
				}
				return item
			})
		}
		setDetailState(detail)

		let report = tab === 'devices' ? getDefaultDevicesReports(deviceSelected) : []
		if (reportState != null) {
			report = report.map((item) => {
				let itemFilter = reportState.filter(
					(itemFilter) => itemFilter.name == item.name && itemFilter.checked
				)
				if (itemFilter.length > 0) {
					item.checked = true
				}
				return item
			})
		}
		setReportState(report)
	}, [t('common:languages.dateFnsLocale')])

	useEffect(() => {
		if (isRestoreVariable) return
		setMapItemsLoaded([])
		setMapRecords([])
		setDetailState(tab === 'forms' ? defaultFormsDetails : getDefaultDevicesDetails(deviceSelected))
		setReportState(tab === 'devices' ? getDefaultDevicesReports(deviceSelected) : [])
		handledActions('device_formSelectedChangeRefresh')
	}, [deviceSelected, formSelected])

	useEffect(() => {
		if (isRestoreVariable) return
		if (tab === 'forms' ? isEmpty(formSelected) : isEmpty(deviceSelected)) return
		if (selectedOption === 'report') return
		setMapItemsLoaded(
			mapItemsLoaded.filter(
				(item) =>
					item.endsWith('_detail') &&
					itemChecked(item.replace(new RegExp('_detail$'), ''), detailState)
			)
		)
		setMapRecords(mapRecords.filter((rec) => rec.dataType.endsWith('_detail')))
		setSelectedActions({
			...selectedActions,
			device_formSelectedChangeRefresh: !device_formSelectedChangeRefresh,
		})
	}, [detailState])

	useEffect(() => {
		if (isRestoreVariable) return
		if (tab !== 'devices') return
		if (isEmpty(deviceSelected)) return
		if (selectedOption === 'detail') return
		setMapItemsLoaded(
			mapItemsLoaded.filter(
				(item) =>
					item.endsWith('_report') &&
					itemChecked(item.replace(new RegExp('_report$'), ''), detailState)
			)
		)
		setMapRecords(mapRecords.filter((rec) => rec.dataType.endsWith('_report')))
		setSelectedActions({
			...selectedActions,
			device_formSelectedChangeRefresh: !device_formSelectedChangeRefresh,
		})
	}, [reportState])

	useEffect(() => {
		if (isRestoreVariable) return
		if (selectedOption === 'report') {
			setReportState(
				reportState.map((item) => ({
					...item,
					loaded: false,
				}))
			)
		} else if (selectedOption === 'detail') {
			setDetailState(
				detailState.map((item) => ({
					...item,
					loaded: false,
				}))
			)
		}
	}, [dateValue])

	function fetchDetail(refresh = false) {
		if (isRestoreVariable) return
		if (tab === 'forms' ? isEmpty(formSelected) : isEmpty(deviceSelected)) return
		if (selectedOption === 'report') return
		if (!recordLoaded) {
			setSelectedPlace(null)
			for (let i = 0; i < detailState.length; i++) {
				let state = detailState[i]
				if (state.loaded) {
					continue
				}
				// tmp = state;
				if (state.checked && (refresh || !mapItemsLoaded.includes(`${state.name}_detail`))) {
					performCheckedRecords(`${state.name}_detail`)
					// break;
				} else if (!state.checked && mapItemsLoaded.includes(`${state.name}_detail`)) {
					continue
				}
			}
		} else {
			setRecordLoaded(false)
		}
	}

	useEffect(() => {
		fetchDetail()
	}, [])

	useEffect(() => {
		if (isFirstRender) return
		fetchDetail()
	}, [reRenderNumber])

	useEffect(() => {
		if (isRestoreVariable) return
		if (tab !== 'devices') return
		if (isEmpty(deviceSelected)) return
		if (selectedOption === 'detail') return
		if (!recordLoaded) {
			setSelectedPlace(null)
			// let tmp = null;
			for (let i = 0; i < reportState.length; i++) {
				let state = reportState[i]
				if (state.loaded) {
					continue
				}
				// tmp = state;
				if (state.checked && !mapItemsLoaded.includes(`${state.name}_report`)) {
					performCheckedRecords(`${state.name}_report`)
					break
				} else if (!state.checked && mapItemsLoaded.includes(`${state.name}_report`)) {
					/*setMapItemsLoaded(mapItemsLoaded.filter(item => item !== `${state.name}_report`));
                    performUnCheckedRecords(`${state.name}_report`);*/
					continue
				}
			}
			/*reportState.forEach((state) => {
                if(state.loaded){
                    return true;
                }
                tmp = state;
                if (state.checked && !mapItemsLoaded.includes(`${state.name}_report`)) {
                    performCheckedRecords(`${state.name}_report`);
                } else if (!state.checked && mapItemsLoaded.includes(`${state.name}_report`)) {
                    setMapItemsLoaded(mapItemsLoaded.filter(item => item !== `${state.name}_report`));
                    performUnCheckedRecords(`${state.name}_report`);
                }
                return false;
            })*/
			/*if(tmp != null){
                setReportState(reportState.map((item) => ({
                    ...item,
                    loaded: item === tmp ? true : item.loaded
                })));
            }*/
		} else {
			setRecordLoaded(false)
		}
	}, [selectedActions.device_formSelectedChangeRefresh])

	useEffect(() => {
		if (isRestoreVariable) return
		if (!selectedActions.refresh) return
		try {
			computeDispatchEventRecords()
			computeFormLocationRecords()
			fetchDetail(selectedActions.refresh)
		} catch (e) {
			console.error(e)
		} finally {
			setSelectedActions({ ...selectedActions, refresh: false })
		}
	}, [selectedActions.refresh])

	useEffect(() => {
		if (isRestoreVariable) return
		if (selectedPlace) {
			setSelectedPlace(null)
		}
		if (!selectedActions.animate) return
		if (mapRecords) {
			setSelectedActions({ ...selectedActions, animate: true })
			let sortedMapItems = sortBy(mapRecords, ['time'], ['asc'])
			let index = 0
			let interval = setInterval(() => {
				let mapItem = sortedMapItems[index++]
				setSelectedPlace(mapItem)
				if (index === sortedMapItems.length) {
					setSelectedActions({ ...selectedActions, animate: false })
					clearInterval(interval)
				}
			}, 1000)
		}
	}, [selectedActions.animate])

	useEffect(() => {
		if (isRestoreVariable) return
		if (action === null) return
		if (action !== 'map') {
			setShowMap(false)
			return
		}
		setShowMap(true)
		if (module.dispatchRecord4Map != null) {
			let detail = tab === 'forms' ? defaultFormsDetails : getDefaultDevicesDetails(deviceSelected)
			detail = detail.map((item) => {
				item.checked = true
				return item
			})
			setDetailState(detail)
			setLoading(true)
			getRecordName(module.dispatchRecord4Map.key, environment.apiToken)
				.then((res) => {
					setRecordName(res.data.name)
					setLoading(false)
					fetchDispatchRecordEvents()
				})
				.catch((err) => {
					console.log(err)
					setLoading(false)
				})
				.finally(() => {})
		} else if (module.mapData != null) {
			if (module.mapData.detailState != null) {
				setDetailState(module.mapData.detailState)
			}
			if (module.mapData.reportState != null) {
				setReportState(module.mapData.reportState)
			}
			onHandleShowLoading(false)
		}
	}, [action])

	const computeDifference = (dataSet, mapRecord) => {
		// see if our new dataSet already exists in mapRecord.
		//     if any object in dataSet that didn't yet exist in mapRecord, return those objects
		let newItem = []
		dataSet.forEach((item) => {
			let found = mapRecord.findIndex((rec) => rec.key === item.key)
			if (found < 0) {
				newItem.push(item)
			}
		})
		return newItem
	}

	const computeAdditionalMapRecords = (dataSet) => {
		if (!dataSet.length) return []
		let newDataSet = dataSet.map((item) => {
			return generateMapItemProps(item, environment, deviceSelected)
		})

		return newDataSet
	}

	const getTitle = () => {
		if (module.dispatchRecord4Map != null) {
			return recordName
		} else {
			return title
		}
	}

	const renderTitle = () => {
		if (isEmpty(formSelected)) return null
		const headingTitle = getTitle()
		return (
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.headingContainer}>
					<div className={classes.headingContent}>
						<Typography
							variant="h6"
							className={[classes.headingLabel, isEmpty(headingTitle) ? classes.noMinHeight : null]}
						>
							{headingTitle || ''}
							{/*{getSelectedProject(environment, formSelected).name + ` / ${formSelected.displayName}`}*/}
						</Typography>
					</div>
					<div className={classes.headingAction}>
						{(module.showMapCloseIcon || tab === 'views') && (
							<Tooltip
								title={`${t('tooltip.close')}`}
								arrow
								placement="bottom-start"
								disableInteractive
							>
								<span>
									<IconButton
										aria-label="Close map"
										size="small"
										color="primary"
										edge="end"
										onClick={() => handleCloseMap()}
									>
										<CloseIcon fontSize="inherit" className={classes.icon} />
									</IconButton>
								</span>
							</Tooltip>
						)}
					</div>
				</Grid>
			</Grid>
		)
	}

	const renderMap = () => {
		return (
			<>
				{renderTitle()}
				<Box ref={formMapRef} className={classes.formMap}>
					{showLoading()}
					<DoformsMapFilters
						environment={environment}
						dateValue={dateValue}
						selectedActions={selectedActions}
						detailState={detailState}
						reportState={reportState}
						onHandledDate={handleDate}
						onHandledActions={handledActions}
						onHandledDetails={handleDetails}
						onHandledReports={handleReports}
						selectedOption={selectedOption}
						tab={tab}
						module={module}
						onHandleOpenFullScreenTab={onHandleOpenFullScreenTab}
					/>
					<DoformsMapCanvas
						environment={environment}
						infoWindowDataSet={infoWindowDataSet}
						mapLoading={loading}
						mapRecords={mapRecords}
						selectedActions={selectedActions}
						selectedPlace={selectedPlace}
						onHandledActions={handledActions}
						onHandledSelected={handledSelected}
						onHandledNewMapItemFetched={handledNewMapItemFetched}
						deviceSelected={deviceSelected}
						formMapRef={formMapRef}
					/>
					{selectedActions.report && (
						<DoformsMapGrid
							environment={environment}
							selectedPlace={selectedPlace}
							mapRecords={mapRecords}
							onHandledSelected={handledSelected}
							onHandledNewMapItemFetched={handledNewMapItemFetched}
							selectedOption={selectedOption}
							detailState={detailState}
							reportState={reportState}
							deviceSelected={deviceSelected}
							module={module}
							title={title}
						/>
					)}
				</Box>
			</>
		)
	}

	const handleClose = () => {
		setInfoWindowAction(null)
		setActionRecord(null)
		setOpen(false)
	}

	const onHandleOpenFullScreenTab = () => {
		var newFullScreenDataMap = {
			showMap: showMap,
			loading: loading,
			dateValue: dateValue,
			selectedActions: selectedActions,
			selectedPlace: selectedPlace,
			selectedOption: selectedOption,
			detailState: detailState,
			reportState: reportState,
			mapItemsLoaded: mapItemsLoaded,
			mapRecords: mapRecords,
			infoWindowAction: infoWindowAction,
			actionRecord: actionRecord,
			open: open,
			state: state,
		}

		localStorage.setItem('newFullScreenData_map', JSON.stringify({ mapData: newFullScreenDataMap }))
		onHandleOpenFullScreenFormsTab()
	}

	const handleCloseMap = () => {
		switch (tab) {
			case 'devices':
			case 'forms':
			case 'views':
				onHandleCloseMap()
				break
			default:
				break
		}
	}

	return (
		<>
			{showMap && renderMap()}
			{infoWindowAction && (
				<DoformsRecordsActions
					open={open}
					action={infoWindowAction}
					environment={environment}
					actionRecord={selectedPlace}
					formSelected={formSelected}
					name={viewData.name}
					tab={tab}
					onClose={handleClose}
					setLoading={setLoading}
				/>
			)}
		</>
	)
}

export default DoformsMap
