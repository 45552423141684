import axios from 'axios'
import { apiUrl } from '../../apiUrl'

/* Cancel request */
const CancelTokenLoadNextRecordsQuery = axios.CancelToken
let cancelLoadNextRecordsQuery

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

export const geofenceApi = {
	getViewData(token, cancelToken) {
		return axios.get(`${url}/geofences/view`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	getColumns(token, cancelToken) {
		return axios.get(`${url}/geofences/view/columns`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getRecords(viewKey, viewSession, filter, token) {
		const config = {
			headers: apiHeader(token),
			cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
				cancelLoadNextRecordsQuery = c
			}),
		}
		let bodyParameters = {
			filter: filter,
			viewKey: viewKey,
		}
		if (viewSession) {
			bodyParameters = { ...bodyParameters, viewSession }
		}

		return axios.post(`${url}/geofences/query`, bodyParameters, config)
	},

	// Geofence Page
	getSharedGeofences(token, cancelToken) {
		return axios.get(`${url}/geofences?type=account`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getMobileUnitGeofences(token, cancelToken) {
		return axios.get(`${url}/geofences?type=device`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	createGeofence(token, geofence) {
		return axios.post(`${url}/geofences`, geofence, {
			headers: apiHeader(token),
		})
	},
	updateGeofence(key, geofence, token) {
		return axios.put(`${url}/geofences/${key}`, geofence, {
			headers: apiHeader(token),
		})
	},
	deleteGeofence(key, token) {
		return axios.delete(`${url}/geofences/${key}`, {
			headers: apiHeader(token),
		})
	},

	// geofence event
	getGeofenceEventView(token, cancelToken) {
		return axios.get(`${url}/geofences/events/view`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getGeofenceEventColumns(token, cancelToken) {
		return axios.get(`${url}/geofences/events/view/columns`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getGeofenceEventRecords(viewKey, viewSession, filter, token) {
		const config = {
			headers: apiHeader(token),
			cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
				cancelLoadNextRecordsQuery = c
			}),
		}
		let bodyParameters = {
			filter: filter,
			viewKey: viewKey,
		}
		if (viewSession) {
			bodyParameters = { ...bodyParameters, viewSession }
		}

		return axios.post(`${url}/geofences/events/query`, bodyParameters, config)
	},

	// geofence reportreport
	getGeofenceReportView(token, cancelToken) {
		return axios.get(`${url}/geofences/reports/view`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getGeofenceReportColumns(token, cancelToken) {
		return axios.get(`${url}/geofences/reports/view/columns`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},
	getGeofenceReportRecords(viewKey, viewSession, filter, token) {
		const config = {
			headers: apiHeader(token),
			cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
				cancelLoadNextRecordsQuery = c
			}),
		}
		let bodyParameters = {
			filter: filter,
			viewKey: viewKey,
		}
		if (viewSession) {
			bodyParameters = { ...bodyParameters, viewSession }
		}

		return axios.post(`${url}/geofences/reports/query`, bodyParameters, config)
	},
}
