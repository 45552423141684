import _ from 'lodash'

const defaultQuery = {
	columns: [],
	filter: {
		conditions: [
			{ preset: 'TODAY', target: '@StartTime', type: 'EQ' },
			{ preset: 'ALL', target: '@UserStatus', type: 'EQ' },
		],
	},
}

export const LOOKUPS_ACTIONS = {
	ACTION: 'LOOKUPS_ACTION',
	FORM_SELECTED: 'LOOKUPS_FORM_SELECTED',
	FORM_COLUMNS: 'LOOKUPS_FORM_COLUMNS',
	FORM_FILTER_CONDITIONS: 'LOOKUPS_FORM_FILTER_CONDITIONS',
	FORM_DELETE_SELECTION: 'LOOKUPS_FORM_DELETE_SELECTION',
	DATA_GRID: 'LOOKUPS_DATA_GRID',
	LOOKUPS_PROJECT: 'LOOKUPS_PROJECT',
	LOOKUPS_REFRESH: 'LOOKUPS_REFRESH',
	VIEW_DATA: 'LOOKUPS_VIEW_DATA',
	VIEW_UPDATE: 'LOOKUPS_VIEW_UPDATE',
	HANDLE_VIEW_UPDATE: 'HANDLE_VIEW_UPDATE',
	VIEW_CREATE: 'LOOKUPS_VIEW_CREATE',
	RESET: 'LOOKUPS_RESET',
	LOOKUPS_RESTORE_PRODUCER: 'LOOKUPS_RESTORE_PRODUCER',
	TAB_CHANGE: 'LOOKUPS_TAB_CHANGE',
	NAME: 'Lookups',
	ROWS_PER_PAGE: 'LOOKUPS_ROWS_PER_PAGE',
	CLIENT_FILTERS: 'LOOKUPS_CLIENT_FILTERS',
	SERVER_CLIENT_FILTERS: 'LOOKUPS_SERVER_CLIENT_FILTERS'
}

const initialState = {
	action: null,
	formColumns: [],
	formColumnsChanged: false,
	formSelected: {},
	formDeleteSelection: [],
	formFilterConditions: {},
	lookupsProject: {},
	lookupsRefresh: false,
	viewUpdate: {},
	viewCreate: {},
	viewData: {},
	dataGrid: {
		title: null,
		query: defaultQuery,
		queryView: {},
		records: [],
		viewSession: false,
	},
	rowsPerPage: 100,
	clientFilters: {},
	serverClientFilters: {},
}

const lookupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOOKUPS_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.DATA_GRID: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload },
			}
		}
		case LOOKUPS_ACTIONS.FORM_SELECTED: {
			return {
				...state,
				formSelected: action.payload,
				dataGrid: _.cloneDeep(initialState.dataGrid),
			}
		}
		case LOOKUPS_ACTIONS.FORM_FILTER_CONDITIONS: {
			return {
				...state,
				formFilterConditions: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.FORM_COLUMNS: {
			return {
				...state,
				formColumns: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.FORM_DELETE_SELECTION: {
			return {
				...state,
				formDeleteSelection: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.LOOKUPS_PROJECT: {
			return {
				...state,
				lookupsProject: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.VIEW_DATA: {
			return {
				...state,
				viewData: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.VIEW_UPDATE: {
			return {
				...state,
				viewUpdate: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.VIEW_CREATE: {
			return {
				...state,
				viewCreate: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.LOOKUPS_REFRESH: {
			return {
				...state,
				lookupsRefresh: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.RESET:
			return initialState
		case LOOKUPS_ACTIONS.LOOKUPS_RESTORE_PRODUCER: {
			return {
				...state,
				action: action.payload.action,
				formColumns: action.payload.formColumns || [],
				formSelected: action.payload.formSelected || {},
				formDeleteSelection: action.payload.formDeleteSelection || [],
				formFilterConditions: action.payload.formFilterConditions || {},
				lookupsProject: action.payload.lookupsProject || {},
				lookupsRefresh: action.payload.lookupsRefresh,
				viewUpdate: action.payload.viewUpdate || {},
				viewCreate: action.payload.viewCreate || {},
				viewData: action.payload.viewData || {},
				dataGrid: action.payload.dataGrid || initialState.dataGrid,
				rowsPerPage: action?.payload?.rowsPerPage || 100,
			}
		}
		case LOOKUPS_ACTIONS.TAB_CHANGE: {
			if (LOOKUPS_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					formSelected: {},
				}
			}
		}
		case LOOKUPS_ACTIONS.ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.CLIENT_FILTERS: {
			return {
				...state,
				clientFilters: action.payload,
			}
		}
		case LOOKUPS_ACTIONS.HANDLE_VIEW_UPDATE: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload?.dataGrid },
				serverClientFilters: { ...action.payload?.serverClientFilters },
				viewUpdate: {},
			}
		}
		case LOOKUPS_ACTIONS.SERVER_CLIENT_FILTERS: {
			return {
				...state,
				serverClientFilters: action.payload,
			}
		}
		default:
			return state
	}
}

export default lookupsReducer
