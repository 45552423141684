import { useState, useContext, useEffect, useMemo, memo, useLayoutEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'

import { isEqual, isEmpty, cloneDeep } from 'lodash'

import { InputLabel, FormControl, Select, MenuItem, Button, Stack } from '@mui/material'
import CollapseComponent from './CollapseComponent'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import {
	DATA_OBJ_ACTION_TYPES,
	SETTING_TYPES,
	dataObjectReducer,
	shouldPreventRender,
} from '../ViewDialogUtils'

const MAIN_CALCULATIONS = {
	SIZE: 'size',
	MIN: 'min',
	MAX: 'max',
}

const NUMBER_CALCULATIONS = {
	SUM: 'sum',
	AVG: 'avg',
}

const AVAILABLE_FIELD_TYPES = ['DATE', 'DATETIME', 'TIME', 'NUMBER', 'FLOAT', 'INTEGER']

const useStyles = makeStyles(() => ({}))

function CalculationSelectComponent(props) {
	const { item, index, calculationsDispatch } = props

	const calculationOptionsByType = useMemo(() => {
		switch (item.type) {
			case 'DATE':
			case 'DATETIME':
			case 'TIME':
				return Object.values(MAIN_CALCULATIONS)
			case 'NUMBER':
			case 'FLOAT':
			case 'INTEGER':
				return [...Object.values(MAIN_CALCULATIONS), ...Object.values(NUMBER_CALCULATIONS)]
			default:
				return [...Object.values(NUMBER_CALCULATIONS)]
		}
	}, [item])
	return (
		<FormControl variant="standard" style={{ width: '50%' }}>
			<InputLabel variant="standard" htmlFor="uncontrolled-native">
				Order
			</InputLabel>
			<Select
				defaultValue={item?.calType || 'Sum'}
				value={item?.calType}
				onChange={(e) => {
					const newItem = cloneDeep(item)
					newItem['calType'] = e.target.value
					calculationsDispatch({
						type: DATA_OBJ_ACTION_TYPES.UPDATE,
						target: {
							index,
							value: newItem,
						},
					})
				}}
				inputProps={{
					name: 'age',
					id: 'uncontrolled-native',
				}}
			>
				{calculationOptionsByType.map((cal) => (
					<MenuItem value={cal}>{cal}</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const AddCalculationsComponent = ({
	allColumns,
	aggregationModel,
	saveSettingDispatch,
	listActiveFieldsOptions,
	expandedAccordion,
	setExpandedAccordion,
}) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [value, setValue] = useState('')
	const title = useMemo(() => t('common:view.addCalculations'), [t])

	// caculation
	const [calculations, calculationsDispatch] = useReducer(dataObjectReducer, [])

	const availableFieldOptions = useMemo(() => {
		return listActiveFieldsOptions.filter((field) => AVAILABLE_FIELD_TYPES.includes(field.type))
	}, [listActiveFieldsOptions])

	useEffect(() => {
		if (isEmpty(allColumns) || isEmpty(availableFieldOptions) || isEmpty(aggregationModel)) return

		const calculations = Object.keys(aggregationModel).map((field) => ({
			field,
			type: allColumns?.find((column) => column.name === field)?.type,
			calType: aggregationModel[field],
		}))

		calculationsDispatch({
			type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
			initData: calculations,
		})
	}, [allColumns, availableFieldOptions, aggregationModel])

	useEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.CALCULATIONS,
			value: calculations || [],
		})

		if (isEmpty(calculations)) {
			setValue('')
			return
		}
		const tmp = calculations.map((e) => e.field + ' ' + e.calType)
		setValue(tmp.join(', '))
	}, [calculations])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<div>
					{!isEmpty(availableFieldOptions) &&
						calculations.map((item, index) => (
							<Stack
								key={`calculation-${index}`}
								direction="row"
								alignItems="center"
								spacing={1}
								style={{ justifyContent: 'center', marginBottom: '10px', alignItems: 'baseline' }}
							>
								<FormControl variant="standard" fullWidth>
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Field
									</InputLabel>
									<Select
										defaultValue={item.field}
										value={item.field}
										onChange={(e) => {
											const newItem = cloneDeep(item)
											const value = e.target.value
											newItem['field'] = value

											const options = availableFieldOptions.find((item) => item.name === value)
											newItem['type'] = options.type
											calculationsDispatch({
												type: DATA_OBJ_ACTION_TYPES.UPDATE,
												target: {
													index,
													value: newItem,
												},
											})
										}}
										inputProps={{
											name: 'age',
											id: 'uncontrolled-native',
										}}
									>
										{availableFieldOptions.map((tmpItem, index) => (
											<MenuItem key={`${index}_${tmpItem.name}`} value={tmpItem.name}>
												{tmpItem.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<CalculationSelectComponent
									item={item}
									index={index}
									calculationsDispatch={calculationsDispatch}
								/>
								<div>
									<Button
										aria-label="delete"
										size="small"
										variant="contained"
										sx={{
											borderRadius: '50%',
											width: '25px !important',
											minWidth: '25px !important',
											height: '25px !important',
										}}
										onClick={(e) => {
											calculationsDispatch({
												type: DATA_OBJ_ACTION_TYPES.DELETE,
												target: {
													index,
												},
											})
										}}
									>
										<Delete fontSize="small" />
									</Button>
								</div>
							</Stack>
						))}
					<div>
						<Button
							variant="text"
							className={classes.rightButtonSort}
							disabled={isEmpty(availableFieldOptions)}
							onClick={(e) => {
								const defaultField = isEmpty(availableFieldOptions) ? '' : availableFieldOptions[0]
								const newItem = {
									field: defaultField ? defaultField.name : '',
									type: defaultField ? defaultField.type : '',
									calType: MAIN_CALCULATIONS.SIZE,
								}
								calculationsDispatch({
									type: DATA_OBJ_ACTION_TYPES.ADD,
									target: {
										defaultItem: newItem,
									},
								})
							}}
						>
							+ Add calculation
						</Button>
					</div>
				</div>
			}
		/>
	)
}

export const AddCalculationsCollapse = memo(AddCalculationsComponent, shouldPreventRender)
