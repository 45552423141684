import { useMemo, useCallback, useEffect } from 'react'
import { useQueries, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import driverMapApi from 'apis/disApi/driverMapApi'

import { DRIVER_EVENT_TYPE, HIDDEN_DRIVER_EVENT_TYPES } from '../../map/helper/constants'

export function useDriverEventQuery({ userId, drives, fromDate, toDate }) {
	const queryClient = useQueryClient()
	const environment = useSelector((state) => state.environment)

	const queries = useMemo(() => {
		return drives.map((drive) => {
			return {
				queryKey: ['driverEvents', userId, drive.id],
				queryFn: () => {
					return driverMapApi.getDriveEvents({
						user_id: userId,
						drive_id: drive.id,
						token: environment?.apiToken,
					})
				},
				enabled: !!userId && !!drive.id && !!environment?.apiToken,
			}
		})
	}, [drives, queryClient, userId])

	const queriesResult = useQueries({ queries })

	const data = queriesResult
		.map((result) => result.data?.data?.data)
		.flat()
		.filter(Boolean)
	const isLoading = queriesResult.some((result) => result.isLoading)
	const isError = queriesResult.some((result) => result.isError)
	const error = queriesResult.find((result) => result.isError)?.error || null

	const formattedEvents = useMemo(() => {
		return data
			.map((event) => {
				return {
					...event,
					type: event?.type ? DRIVER_EVENT_TYPE[event.type] : '',
				}
			})
			.filter((event) => !HIDDEN_DRIVER_EVENT_TYPES.has(event?.type))
	}, [data])

	return { allEvents: formattedEvents, isLoadingAllEvents: isLoading, isError, error }
}
