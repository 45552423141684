import { useCallback, useEffect, useMemo, useState } from 'react'
import { isEmpty, sortBy, isNumber, isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'

import { Box, Stack } from '@mui/material'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { DrivePolylineComponent } from './DrivePolyineComponent'
import LoadingSpinner from '../../../../../../custom-components/LoadingSpinner'

import { defaultMapState } from '../helper/constants'

import { useDriverEmailQuery, useDrives } from './hook/index'
import DriverMapFilters from './DriverMapFilters'

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_MAPKEY

export function DriverMapInfo(props) {
	const {
		titleKey,
		fromDate,
		toDate,
		userEmail,
		showFilters,
		repeatReloadMapSeconds,
		showHistory,
		pointOfInterest,
		isRemovedTile,
	} = props
	const environment = useSelector((state) => state.environment)
	const [activeMarker, setActiveMarker] = useState(null)
	const [mapRef, setMapRef] = useState(null)

	const [center, setCenter] = useState(defaultMapState.center)
	const [zoom, setZoom] = useState(defaultMapState.zoom)

	const { driverInfoByEmail, isLoadingUser } = useDriverEmailQuery({
		userEmail,
	})

	const userId = useMemo(() => {
		if (isLoadingUser || !userEmail || isEmpty(driverInfoByEmail)) return null

		if (!isEqual(userEmail, driverInfoByEmail.email)) return null

		return driverInfoByEmail.id
	}, [userEmail, isLoadingUser, driverInfoByEmail])

	// Fetch drives
	const { allDrives, isLoadingDrives: isLoadingAllDrives } = useDrives({
		userId: userId,
		fromDate,
		toDate,
		showHistory,
	})

	const currentLocationDrive = useMemo(() => {
		if (showHistory || isEmpty(allDrives)) return null

		return allDrives[0]
	}, [showHistory, allDrives])

	const [locationByDriveId, setLocationByDriveIds] = useState({})

	const allLocations = useMemo(() => {
		if (isEmpty(locationByDriveId)) return []

		return sortBy(Object.values(locationByDriveId).flat() || [], (obj) => moment.utc(obj.timestamp))
	}, [JSON.stringify(locationByDriveId)]) // Store all locations

	const updateLocationsByDriveId = (id, locations) => {
		setLocationByDriveIds((prev) => {
			return { ...prev, [id]: locations }
		})
	}

	const shouldRefetchLocations = useMemo(() => {
		if (!toDate) return false

		const startBeforeDate = moment.unix(toDate)
		const oneHourAgo = moment().subtract(1, 'hour')
		const now = moment()

		return startBeforeDate.isBetween(oneHourAgo, now, null, '[]')
	}, [toDate])

	useEffect(() => {
		if (isLoadingUser || isEmpty(driverInfoByEmail)) {
			setLocationByDriveIds({})
			if (mapRef) {
				fitBounds(mapRef)
			}
		}
	}, [mapRef, isLoadingUser, driverInfoByEmail])

	const fitBounds = (map, allLocations) => {
		if (!map) return
		if (isEmpty(allLocations)) {
			setCenter(defaultMapState.center)
			setZoom(defaultMapState.zoom)

			// Force map to reapply the zoom
			map.setZoom(defaultMapState.zoom)
			return
		}

		const bounds = new window.google.maps.LatLngBounds()
		allLocations.forEach(({ lat, lng }) => bounds.extend({ lat: lat, lng: lng }))
		map.fitBounds(bounds)

		const centerFromBounds = bounds.getCenter()

		setCenter(centerFromBounds)
	}

	useEffect(() => {
		if (!showHistory || !mapRef) return
		fitBounds(mapRef, allLocations)
	}, [showHistory, allLocations, mapRef])

	useEffect(() => {
		if (!mapRef) return
		fitBounds(mapRef)
	}, [userEmail])

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAP_API_KEY,
	})

	const handleOnLoad = (map) => {
		if (!map) return

		// Store a reference to the google map instance in state
		setMapRef(map)

		// Fit map bounds to contain all markers
		fitBounds(map)
	}

	if (!window.google || !window.google.maps) {
		console.error('Google Maps API is not loaded.')
		return <div>"Google Maps API is not loaded."</div>
	}

	if (loadError) {
		return <div>Error loading Google Maps API</div>
	}

	if (!isLoaded) {
		return <div>Loading Google Maps...</div>
	}

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return
		}
		setActiveMarker(marker)
	}

	return (
		<Stack
			sx={{
				height: '100%',
				width: '100%',
				mt: '6px !important',
			}}
		>
			{showFilters && (
				<Stack px={1} direction="row" alignItems="center" spacing={2}>
					<DriverMapFilters
						userEmail={userEmail}
						fromDate={moment.unix(fromDate).toISOString()}
						toDate={moment.unix(toDate).toISOString()}
						disableFilters
					/>
				</Stack>
			)}

			{!isRemovedTile?.[titleKey] && (
				<Box sx={{ flex: 1, mt: 1 }}>
					<GoogleMap
						onLoad={handleOnLoad}
						center={center}
						zoom={zoom}
						onClick={() => setActiveMarker(null)}
						mapContainerStyle={{
							height: '100%',
							width: '100%',
						}}
						options={{
							minZoom: 4,
							gestureHandling: 'greedy',
							styles: [
								{
									featureType: 'poi',
									stylers: [{ visibility: pointOfInterest ? 'on' : 'off' }],
								},
							],
						}}
					>
						{isLoadingUser || isLoadingAllDrives ? (
							<LoadingSpinner />
						) : (
							userId &&
							driverInfoByEmail &&
							allDrives?.map((drive, index) => {
								return (
									<DrivePolylineComponent
										index={index}
										drive={drive}
										userId={userId}
										userEmail={userEmail}
										fromDate={fromDate}
										toDate={toDate}
										activeMarker={activeMarker}
										setActiveMarker={setActiveMarker}
										handleActiveMarker={handleActiveMarker}
										locationByDriveId={locationByDriveId}
										updateLocationsByDriveId={updateLocationsByDriveId}
										showHistory={showHistory}
										center={center}
										setCenter={setCenter}
										shouldRefetchLocations={shouldRefetchLocations}
										repeatReloadMapSeconds={repeatReloadMapSeconds}
										currentLocationDrive={currentLocationDrive}
									/>
								)
							})
						)}
					</GoogleMap>
				</Box>
			)}
		</Stack>
	)
}
