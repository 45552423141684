import { isEmpty, cloneDeep } from 'lodash'
import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { enUS } from 'date-fns/locale'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
	Box,
	Checkbox,
	FormControlLabel,
	TextField,
	FormControl,
	Skeleton,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import DashboardList from '../../DashboardKeyList'
import GeoMapFilters from './GeoMapFilters'
import GeoMapLoginForm from './GeoMapLoginForm'

import { getMapLoggedInInfoFromStorage } from 'utils/functions/helpers'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from '../../../hooks/useVehicleLocation'
import { toDayjs } from 'utils/functions/doformsDateUtil'

export const MIN_REPEAT_RELOAD_MAP_VALUE = 5
export const MAX_REPEAT_RELOAD_MAP_VALUE = 60

const GeoMapForm = ({
	vehicleLoading,
	filterData,
	vehicleList,
	linkedFields,
	dashboardKeyList,
	onFilterDataChange,
}) => {
	const { t } = useTranslation('common')
	const [showLoginForm, setShowLoginForm] = useState()

	const FIELD_LIST = [
		{
			name: 'vehicleName',
			label: t('common:input.vehicleName'),
		},
		{
			name: 'fromDate',
			label: t('common:input.fromDate'),
			type: 'DATE',
		},
		{
			name: 'toDate',
			label: t('common:input.toDate'),
			type: 'DATE',
		},
	]

	const [repeatReloadMapError, setRepeatReloadMapError] = useState('')

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !isEmpty(userInfo)

	useEffect(() => {
		setShowLoginForm(!isLoggedIn)
	}, [isLoggedIn])

	return showLoginForm ? (
		<GeoMapLoginForm setShowLoginForm={setShowLoginForm} />
	) : (
		<>
			{(isLoggedIn ? !vehicleLoading : true) ? (
				<FormControl sx={{ minWidth: 120 }} size="small">
					<InputLabel id="vehicle-select-small-label">{t('common:input.vehicleName')}</InputLabel>
					<Select
						labelId="vehicle-select-small-label"
						id="vehicle-select-small"
						value={filterData.locationData?.vehicleName || ''}
						label={t('common:input.vehicleName')}
						onChange={(e) => {
							onFilterDataChange('vehicleName', e.target.value)
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{(isLoggedIn ? vehicleList : [])?.map((vehicle) => (
							<MenuItem value={vehicle.name} key={vehicle.name}>
								{vehicle.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<Skeleton variant="rectangular" height={45} sx={{ minWidth: 120 }} />
			)}
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showHistory')}
				control={
					<Checkbox
						checked={filterData?.showHistory ?? false}
						onChange={() => onFilterDataChange('showHistory', !filterData.showHistory)}
						name={t('common:input.showHistory')}
					/>
				}
			/>
			{filterData?.showHistory && (
				<DateRangePicker
					fromDate={filterData.locationData?.fromDate}
					toDate={filterData.locationData?.toDate}
					onFilters={(name, value) =>
						onFilterDataChange('locationData', { ...filterData.locationData, [name]: value })
					}
				/>
			)}
			<TextField
				size="small"
				id="repeat-reload-map-value"
				label={t('common:dis.autoRefreshInterval')}
				type="number"
				value={filterData.repeatReloadMapSeconds || DEFAULT_REPEAT_RELOAD_MAP_SECONDS}
				onChange={(e) => {
					const value = e.target.value
					onFilterDataChange('repeatReloadMapSeconds', value)
					if (value < MIN_REPEAT_RELOAD_MAP_VALUE || value > MAX_REPEAT_RELOAD_MAP_VALUE) {
						setRepeatReloadMapError('Repeat reload map value must be between 5 and 60')
					} else {
						setRepeatReloadMapError('')
					}
				}}
				error={Boolean(repeatReloadMapError)}
				helperText={repeatReloadMapError || ''}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
					min: MIN_REPEAT_RELOAD_MAP_VALUE,
					max: MAX_REPEAT_RELOAD_MAP_VALUE,
				}}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showFilters')}
				control={
					<Checkbox
						checked={filterData?.showFilters ?? false}
						onChange={() => onFilterDataChange('showFilters', !filterData.showFilters)}
						name={t('common:input.showFilters')}
					/>
				}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showPointsOfInterest')}
				control={
					<Checkbox
						checked={filterData?.pointOfInterest ?? false}
						onChange={() => onFilterDataChange('pointOfInterest', !filterData.pointOfInterest)}
						name={t('common:input.showPointsOfInterest')}
					/>
				}
			/>
			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={FIELD_LIST}
					onFieldsChange={(id, value) => {
						if (isEmpty(value)) {
							const currentLinkedFields = cloneDeep(filterData?.linkedFields)
							if (currentLinkedFields[id]) {
								delete currentLinkedFields[id]
							}
							onFilterDataChange('linkedFields', currentLinkedFields)
							return
						}
						onFilterDataChange('linkedFields', { ...filterData.linkedFields, [id]: value })
					}}
					linkedFields={filterData.linkedFields || {}}
					getLabelOption={(item) => item.label}
				/>
			</Box>
		</>
	)
}

function DateRangePicker(props) {
	const { fromDate, toDate, onFilters, disableFilters } = props
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)
	const displayFromDate = useMemo(() => {
		return toDayjs(fromDate)
	}, [fromDate])

	const displayToDate = useMemo(() => {
		return toDayjs(toDate)
	}, [toDate])
	const handleFromDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('fromDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		onFilters?.('fromDate', newValue.toDate())
	}

	const handleToDateChange = (newValue) => {
		if (!newValue?.isValid()) {
			onFilters?.('toDate', null)
			return
		}

		// const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		onFilters?.('toDate', newValue.toDate())
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} locale={enUS}>
			<DatePicker
				id="map-date"
				label={t('common:input.fromDate')}
				disabled={disableFilters}
				value={displayFromDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleFromDateChange}
				onAccept={handleFromDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleFromDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>

			<DatePicker
				id="map-date"
				label={t('common:input.toDate')}
				disabled={disableFilters}
				value={displayToDate}
				maxDate={toDayjs(locale.maxDate)}
				onChange={handleToDateChange}
				onAccept={handleToDateChange}
				slotProps={{
					textField: {
						InputProps: {
							size: 'small',
							variant: 'outlined',

							// Turn off the error when the user clears the date
							error: false,
						},
						onChange: (e) => {
							if (!e?.isValid()) return
							handleToDateChange(e)
						},
					},
					actionBar: {
						actions: ['clear'],
					},
				}}
			/>
		</LocalizationProvider>
	)
}

export default GeoMapForm
